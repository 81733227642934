import { BarChart, CurrencyRupee, Dashboard, DirectionsBoat, ManageAccounts, ModeOfTravel, SettingsRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes-constants';
import AdminFooter from '../footers/AdminFooter';
import AdminHeader from '../headers/AdminHeader';
import Sidebar from '../sidebars/Sidebar';
import SidebarItem from '../sidebars/SidebarItem';
import DivHeadDashboard from '../../pages/division-head/dashboard/Dashboard';
import { Container } from '@mui/material';

interface AdminLayoutProps {
    children: React.ReactNode
    activeLink: string;
}

export enum DivHeadPageType {
    DASHBOARD = 'Dashboard',
    VESSEL = 'Vessels',
    TRIP_SETTINGS = 'Trip Settings',
    TRIP = 'Trips',
    USERS = 'Users',
    REVENUE_DISTRIBUTION = 'Revenue Distribution',
    CAPACITY_UTILISATION = 'Capacity Utilisation'
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ ...props }) => {
    const navigate = useNavigate();
    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    const divHeadLinks = [
        { name: 'Dashboard', href: ROUTES.DIVISION_HEAD.DASHBOARD, icon: <Dashboard /> },
    ]

    const managementLinks = [
        { name: 'Vessels', href: ROUTES.DIVISION_HEAD.VESSEL, icon: <DirectionsBoat /> },
        { name: 'Trip Settings', href: ROUTES.DIVISION_HEAD.TRIP_SETTINGS, icon: <SettingsRounded /> },
        { name: 'Trips', href: ROUTES.DIVISION_HEAD.TRIP, icon: <ModeOfTravel /> },
        { name: 'Users', href: ROUTES.DIVISION_HEAD.USERS, icon: <ManageAccounts /> },
    ]

    const misLinks = [
        { name: 'Revenue Distribution', href: ROUTES.DIVISION_HEAD.REVENUE_DISTRIBUTION, icon: <CurrencyRupee /> },
        { name: 'Capacity Utilisation', href: ROUTES.DIVISION_HEAD.CAPACITY_UTILISATION, icon: <BarChart /> }
    ]

    return (
        <div className='flex'>
            <div className='fixed'>
                <Sidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded}>
                    {
                        divHeadLinks.map((link, index) => (
                            <button key={index} onClick={() => navigate(link.href)} className='block text-left'>
                                <SidebarItem icon={link.icon} text={link.name} active={props.activeLink === link.name} theme='primary' />
                            </button>
                        ))
                    }
                    <hr />
                    {
                        managementLinks.map((link, index) => (
                            <button key={index} onClick={() => navigate(link.href)} className='block text-left'>
                                <SidebarItem icon={link.icon} text={link.name} active={props.activeLink === link.name} theme='primary' />
                            </button>
                        ))
                    }
                    <hr />
                    {
                        misLinks.map((link, index) => (
                            <button key={index} onClick={() => navigate(link.href)} className='block text-left'>
                                <SidebarItem icon={link.icon} text={link.name} active={props.activeLink === link.name} theme='secondary' />
                            </button>
                        ))
                    }
                </Sidebar>
            </div>

            <div className={`w-full ${sidebarExpanded ? 'ml-[18.5rem]' : 'ml-[4.75rem]'}`}>
                <AdminHeader />
                <div className='min-h-screen py-4 bg-neutral-50'>
                    <Container>
                        {props.children}
                    </Container>
                </div>
                <AdminFooter />
            </div>
        </div>
    )
}

export default AdminLayout;