import React from 'react'
import { useApiService } from '../../../hooks/useApiService';
import { ISearchParams } from '../../unlogged/landing-page/landing-interfaces';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import API_ENDPOINT from '../../../constants/api-constants';
import { Route } from '../../manage/route-management/route-interfaces';
import { ISearchResult } from '../../unlogged/search-results/search-interfaces';
import { IBookingTicketDetails } from '../booking-history/ticket-details/booking-ticket-details-interfaces';
import { BookingTicketTypes, GoodsView, PassengerView, VehicleView } from '../payment/order-details-interfaces';
import { Passenger } from '../ticket-booking/ticket-booking-interfaces';
import { dateUtil } from '../../../utility/dateUtil';

export const useTicketRebookService = (t: (key: string) => string) => {

    const { fetchPublicRequest } = useApiService();

    const getRouteFromOriginAndDestination = (routes: Route[], origin: string, destination: string) => {
        const match = routes.find((route) => route.ghatFrom.id == origin && route.ghatTo.id == destination);
        if (match) {
            return match.id;
        }
        return null;
    }

    const searchTrips = async (
        searchParams: ISearchParams,
        routes: Route[],
        bookingTicketDetails: IBookingTicketDetails
    ) => {
        const { origin, destination, tripDate } = searchParams;

        const missingFields = [];
        if (!origin.originId) missingFields.push('origin');
        if (!destination.destinationId) missingFields.push('destination');

        if (missingFields.length > 0) {
            toast.error(`Please select ${missingFields.join(' and ')}.`);
            return;
        }

        const routeId = getRouteFromOriginAndDestination(routes, origin.originId, destination.destinationId);

        if (!routeId || !tripDate.trim()) {
            toast.error("No trips found for the selected route and date.");
            return;
        }

        try {
            const response: AxiosResponse = await fetchPublicRequest(API_ENDPOINT.PUBLIC.GET_TRIPS, {
                routeId,
                tripDate
            });
            const tripList: ISearchResult[] = response.data.object;
            if (tripList.length > 0) {
                localStorage.setItem('searchResults', JSON.stringify(tripList));
                localStorage.setItem('bookingTicketDetails', JSON.stringify(bookingTicketDetails));
                return true;
            } else {
                toast.error("No trips found for the selected route and date.");
            }
        } catch (error) {
            toast.error("Failed to fetch trips. Please try again.");
        }
    }

    const transformPassengers = (
        passengers: PassengerView[]
    ) => {
        return passengers.map(passenger => {
            const uniqueId = dateUtil.getTimestamp().toString();

            const transformedPassenger: Passenger = {
                id: uniqueId,
                passengerName: passenger.passengerName,
                mobileNo: passenger.mobileNo,
                email: passenger.email,
                pwd: passenger.pwd,
                gender: passenger.gender,
                age: parseInt(passenger.age, 10),
                uniqueId: uniqueId,
                infants: passenger.infants.map(infant => ({
                    infantName: infant.infantName,
                    infantGender: infant.infantGender,
                    infantAgeCode: infant.infantAge.code,
                }))
            };

            return transformedPassenger;
        });
    }

    const transformVehicles = (
        vehicles: VehicleView[],
        passengers: Passenger[]
    ) => {
        return vehicles.map(vehicle => {
            const passenger = passengers.find(p => p.passengerName = vehicle.ownerName);
            const uniqueId = dateUtil.getTimestamp().toString();

            return {
                id: uniqueId,
                model: vehicle.model,
                regNo: vehicle.regNo,
                item: {
                    id: vehicle.item.id,
                    itemType: vehicle.item.itemType
                },
                vehicleType: vehicle.item.itemCategory,
                passengerUniqueId: passenger?.uniqueId || '',
                ownerName: vehicle.ownerName
            };
        });
    }

    const transformGoods = (goods: GoodsView[]) => {
        const uniqueId = dateUtil.getTimestamp().toString();

        return goods.map(good => ({
            id: uniqueId,
            description: good.description,
            unit: good.unit,
            weight: good.weight,
            height: good.height,
            width: good.width,
            length: good.length
        }));
    }

    const getTableData = (type: BookingTicketTypes) => {
        const headersMap = {
            passengers: [
                { name: 'passengerName', label: t('name') },
                { name: 'gender', label: t('gender') },
                { name: 'age', label: t('age') },
                { name: 'pwd', label: t('differently_abled') },
            ],
            vehicles: [
                { name: 'item.itemType', label: t('vehicle_type') },
                { name: 'regNo', label: t('registration_no') },
                { name: 'model', label: t('vehicle_model') },
                { name: 'ownerName', label: t('owner') },
            ],
            goods: [
                { name: 'description', label: t('description') },
                { name: 'weight', label: t('weight') },
                { name: 'volume', label: t('volume') },
            ]
        };

        const titlesMap = {
            passengers: t('passengers'),
            vehicles: t('vehicles'),
            goods: t('goods')
        };

        return {
            title: titlesMap[type],
            headers: headersMap[type]
        }
    }


    return {
        searchTrips,
        transformPassengers,
        transformVehicles,
        transformGoods,
        getTableData
    }
}
