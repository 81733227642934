import React, { useEffect, useState } from 'react'
import GhatIcLayout from '../../../components/layouts/GhatIcLayout'
import useAuth from '../../../hooks/useAuth';
import Card from '../../../components/cards/Card';
import { dateUtil } from '../../../utility/dateUtil';
import { AccessTimeOutlined, ArrowForwardOutlined, DirectionsBoatFilledOutlined } from '@mui/icons-material';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes-constants';

interface Trip {
    id: string;
    origin: any;
    destination: any;
    tripDate: string;
    tripTime: string;
    tripDeckItems: any[];
}

interface BoardingStatus {
    label: string;
    capacity: number;
    ticketsIssued: number;
    boarded: number;
}

const BoardingStatusTable: React.FC<BoardingStatus> = ({ ...props }) => {
    return (
        <table className='w-full text-sm'>
            <tr className={`border border-slate-100 bg-slate-200 text-center font-medium`}>
                <td colSpan={2} className='px-3 py-1'>{props.label}</td>
            </tr>
            <tr className={`border border-slate-100 bg-slate-50`}>
                <td className={`px-3 py-1`}>Capacity</td>
                <td className='text-center'>{props.capacity}</td>
            </tr>
            <tr className='border border-slate-100 bg-slate-100'>
                <td className={`px-3 py-1`}>Tickets Issued</td>
                <td className='text-center'>{props.ticketsIssued}</td>
            </tr>
            <tr className='border border-slate-100 bg-slate-50'>
                <td className={`px-3 py-1`}>Boarded</td>
                <td className='text-center'>{props.boarded}</td>
            </tr>
        </table>
    )
}

const TripCard: React.FC<Trip> = ({ ...props }) => {
    const navigate = useNavigate();
    const handleMoreDetailsClick = (tripId: string) => {
        return () => {
            navigate(ROUTES.GHAT_IC.TRIP_MANAGEMENT, { state: { tripId: tripId } });
        }
    }

    return (
        <Card>
            <div>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <h4 className="font-semibold"><DirectionsBoatFilledOutlined className='mb-1 mr-2' /> {`Jalpori II (AS02AJ8898)`}</h4>
                    <div className='flex justify-end'>
                        <div className='flex bg-sky-700 text-white py-1.5 px-3 rounded-full'>
                            <AccessTimeOutlined className='mr-1.5' /> {`${dateUtil.formatTime(`09:30:00`)} (${`00:15:00`})`}
                        </div>
                    </div>
                </div>
                <p className="font-medium">{`Gateway of Guwahati Fancy Bazaar (ফেন্সি বজাৰ)`} <ArrowForwardOutlined /> {`North Guwahati (উত্তৰ গুৱাহাটী)`}</p>

                <hr className='my-4' />

                <div className='grid grid-cols-3 gap-x-4'>
                    <BoardingStatusTable
                        label='Passengers'
                        capacity={100}
                        ticketsIssued={100}
                        boarded={80}
                    />
                    <BoardingStatusTable
                        label='Vehicles'
                        capacity={100}
                        ticketsIssued={100}
                        boarded={80}
                    />
                    <BoardingStatusTable
                        label='Goods'
                        capacity={100}
                        ticketsIssued={100}
                        boarded={80}
                    />
                </div>

                <hr className='my-4' />
                <div className='flex justify-between'>
                    <div className='font-medium'>
                        <div>Departure: {`12:00 PM`}</div>
                        <div>Duration: {`00:12:00`}</div>
                    </div>
                    <PrimaryButton
                        buttonText='More Details'
                        onClick={handleMoreDetailsClick(props.id)}
                    />
                </div>
            </div>
        </Card>
    )
}

const GhatIcDashboard: React.FC = () => {
    const { auth } = useAuth();
    const [ghatId, setGhatId] = useState<string>('');
    const [ghatName, setGhatName] = useState<string>('');

    useEffect(() => {
        if (auth.roles && auth.roles.jurisdiction === 'Ghat') {
            setGhatId(auth.roles.jurisdictionId);
            setGhatName(auth.roles.jurisdictionName);
        }
    }, []);

    useEffect(() => {
        if (ghatId !== '') {
            // TODO: Fetch Trips by Ghat ID
        }
    }, [ghatId]);

    return (
        <GhatIcLayout>
            <h3>{`Ghat IC Dashboard`}</h3>
            <h4 className='text-sky-600'>{ghatName}</h4>
            <div className=''>
                <h6>{`Today's Trips`}</h6>
            </div>
            <div className='grid grid-cols-1 gap-4'>
                <TripCard
                    id='1'
                    origin={{ originName: 'Gateway of Guwahati Fancy Bazaar (ফেন্সি বজাৰ)' }}
                    destination={{ destinationName: 'North Guwahati (উত্তৰ গুৱাহাটী)' }}
                    tripDate='2021-08-01'
                    tripTime='12:00 PM'
                    tripDeckItems={[]}
                />
            </div>
        </GhatIcLayout>
    )
}

export default GhatIcDashboard