import React from 'react';
import { Chart } from 'react-google-charts';
import Card from '../../../../components/cards/Card';
import { CurrencyRupee, Download } from '@mui/icons-material';
import { IPaymentModeWiseRevenue } from '../div-head-dashboard-interfaces';
import { IVesselWiseRevenue } from '../../mis/mis-interfaces';
import { ROUTES } from '../../../../constants/routes-constants';
import { useNavigate } from 'react-router-dom';
import { useDownloadService } from './services/useDownloadService';

interface IRevenueDistributionCardProps {
    vesselWiseRevenue: IVesselWiseRevenue[];
    paymentModeWiseRevenue: IPaymentModeWiseRevenue;
    fromDate: string;
    toDate: string;
    serviceType: string;
    owner: string;
    vesselType: string;
}

const RevenueDistributionCard: React.FC<IRevenueDistributionCardProps> = ({
    vesselWiseRevenue,
    paymentModeWiseRevenue,
    fromDate,
    toDate,
    serviceType,
    owner,
    vesselType
}) => {

    const navigate = useNavigate();

    const revenueTable = React.useRef<HTMLTableElement>(null);
    const modeWiseRevenueChart = React.useRef<HTMLDivElement>(null);

    const { downloadRevenueInformation } = useDownloadService();

    const vesselRevenueData = vesselWiseRevenue.map((vessel, index) => {
        if (index < 5) {
            return { id: index + 1, name: vessel.vesselName, trips: vessel.noOfTrips, revenue: vessel.totalRevenue }
        }
    });

    const revenueData = [
        ['Mode', 'Revenue'],
        ['POS Cash', paymentModeWiseRevenue.cash],
        ['POS Cashless', paymentModeWiseRevenue.pos],
        ['Online', paymentModeWiseRevenue.online],
    ];

    const revenueOptions = {
        pieHole: 0.4,
        is3D: false,
        slices: {
            0: { color: "#ca080b" }, // Cash
            1: { color: "#040797" }, // POS
            2: { color: "#488f31" }, // Online
        },
        chartArea: { width: "90%", height: "80%" },
        legend: { position: 'bottom' }
    };

    const handleDownloadClick = async () => {
        modeWiseRevenueChart.current && revenueTable.current &&
            downloadRevenueInformation(
                revenueTable.current,
                modeWiseRevenueChart.current,
                revenueData,
                fromDate,
                toDate,
                serviceType,
                owner,
                vesselType
            );
    }

    return (
        <Card addClass='mb-4'>
            <div className="flex justify-between items-center border-b pb-2">
                <h6 className="text-lg font-medium flex items-center">
                    <CurrencyRupee fontSize='small' className='mr-2' /> Revenue Information
                </h6>
                <button
                    className="text-gray-600 hover:text-gray-800"
                    onClick={handleDownloadClick}
                >
                    <Download />
                </button>
            </div>

            <div className="grid grid-cols-12 gap-4 mt-4">
                {/* Table Section */}
                <div className="col-span-8 border-r pr-4">
                    <div className="flex justify-between items-center mb-2">
                        <strong>Top 5 Revenue Generating Vessels</strong>
                        <button onClick={() => navigate(ROUTES.DIVISION_HEAD.REVENUE_DISTRIBUTION)} className="text-blue-600 text-sm">
                            View Complete List
                        </button>
                    </div>
                    <table className="table-auto w-full border text-sm" ref={revenueTable}>
                        <thead>
                            <tr className="bg-blue-100 text-center">
                                <th className="p-2">#</th>
                                <th className="p-2">Ferry Name</th>
                                <th className="p-2">Total Trips</th>
                                <th className="p-2">Total Revenue</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {
                                vesselRevenueData.length > 0 &&
                                vesselRevenueData.map((vessel) => vessel && (
                                    <tr key={vessel.id} className="border-b">
                                        <td className="p-2">{vessel.id}</td>
                                        <td className="p-2 font-bold">{vessel.name}</td>
                                        <td className="p-2">{vessel.trips}</td>
                                        <td className="p-2 text-red-600">₹ {vessel.revenue.toLocaleString()}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>

                {/* Chart Section */}
                <div className="col-span-4 flex flex-col items-center">
                    <strong className="mb-2">Mode Wise Revenue Distribution</strong>
                    <div ref={modeWiseRevenueChart}>
                        <Chart
                            chartType="PieChart"
                            width="100%"
                            height="220px"
                            data={revenueData}
                            options={revenueOptions}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default RevenueDistributionCard;