import React from 'react'
import { ICapacityUtilisationReport } from './mis-interfaces';

export const useCapacityUtilisationService = () => {

    const getFilteredCapacityUtilisationData = (
        serviceType: string,
        owner: string,
        vesselType: string,
        capacityUtilisationList: ICapacityUtilisationReport[],
        setCapacityUtilisation: React.Dispatch<React.SetStateAction<ICapacityUtilisationReport[]>>
    ) => {
        // Create a map to group data by composite key (vesselName + source + destination)
        const groupedData = new Map<string, ICapacityUtilisationReport>();

        for (const entry of capacityUtilisationList) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Create a composite key for grouping
                const compositeKey = `${entry.vesselName}|${entry.source}|${entry.destination}`;

                // If the group doesn't exist, initialize it
                if (!groupedData.has(compositeKey)) {
                    groupedData.set(compositeKey, {
                        ...entry, // Copy all fields from the entry
                        goodsCapacity: entry.goodsCapacity,
                        twoWheelerCapacity: entry.twoWheelerCapacity,
                        fourWheelerCapacity: entry.fourWheelerCapacity,
                        passengerUtilization: entry.passengerUtilization,
                        passengerCapacity: entry.passengerCapacity,
                        biCycleUtilization: entry.biCycleUtilization,
                        biCycleCapacity: entry.biCycleCapacity,
                        twoWheelerUtilization: entry.twoWheelerUtilization,
                        threeWheelerUtilization: entry.threeWheelerUtilization,
                        fourWheelerUtilization: entry.fourWheelerUtilization,
                        hmvUtilization: entry.hmvUtilization,
                        hmvCapacity: entry.hmvCapacity,
                        goodsUtilization: entry.goodsUtilization,
                        threeWheelerCapacity: entry.threeWheelerCapacity,
                    });
                } else {
                    // If the group exists, aggregate the values
                    const existingEntry = groupedData.get(compositeKey)!;
                    existingEntry.goodsCapacity += entry.goodsCapacity;
                    existingEntry.twoWheelerCapacity += entry.twoWheelerCapacity;
                    existingEntry.fourWheelerCapacity += entry.fourWheelerCapacity;
                    existingEntry.passengerUtilization += entry.passengerUtilization;
                    existingEntry.passengerCapacity += entry.passengerCapacity;
                    existingEntry.biCycleUtilization += entry.biCycleUtilization;
                    existingEntry.biCycleCapacity += entry.biCycleCapacity;
                    existingEntry.twoWheelerUtilization += entry.twoWheelerUtilization;
                    existingEntry.threeWheelerUtilization += entry.threeWheelerUtilization;
                    existingEntry.fourWheelerUtilization += entry.fourWheelerUtilization;
                    existingEntry.hmvUtilization += entry.hmvUtilization;
                    existingEntry.hmvCapacity += entry.hmvCapacity;
                    existingEntry.goodsUtilization += entry.goodsUtilization;
                    existingEntry.threeWheelerCapacity += entry.threeWheelerCapacity;
                }
            }
        }

        // Convert the map back to an array
        setCapacityUtilisation(Array.from(groupedData.values()));
    }

    return {
        getFilteredCapacityUtilisationData
    }
}
