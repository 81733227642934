import { AccountCircle, AddRounded, BrowserUpdated } from '@mui/icons-material';
import React, { useEffect } from 'react';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Body from '../../../components/layouts/Body';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import { useFormErrorContext } from '../../../hooks/useFormErrorContext';
import { useValidationService } from '../../../hooks/useValidationService';
import { IUser, IUserFetch, UserFormInitialState } from './user-interfaces';
import UserForm from './UserForm';
import UserTable from './UserTable';
import { useUserManagementService } from './useUserManagementSevice';
import FormControl from '../../../components/forms/FormControl';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import SecondaryButton2 from '../../../components/buttons/SecondaryButton2';
import toast from 'react-hot-toast';

const UserManagement: React.FC = () => {
    const { fetchDivisionOptions, fetchGhatOptionsByDivision, fetchRoleOptions } = useCommonService();
    const { fetchUsers, saveUser } = useUserManagementService();
    const { setFormErrors } = useFormErrorContext();
    const { validateFields } = useValidationService();

    const [userList, setUserList] = React.useState<IUserFetch[]>([]);
    const [userForm, setUserForm] = React.useState<IUser>(UserFormInitialState);

    const [userDetailsModalOpen, setUserDetailsModalOpen] = React.useState(false);
    const handleUserDetailsModalOpen = () => setUserDetailsModalOpen(true);
    const handleUserDetailsModalClose = () => setUserDetailsModalOpen(false);

    const [division, setDivision] = React.useState('');
    const [ghat, setGhat] = React.useState('');

    const [roleOptions, setRoleOptions] = React.useState<SelectOption[]>([]);
    const [divisionOptions, setDivisionOptions] = React.useState<SelectOption[]>([]);
    const [ghatOptions, setGhatOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        // fetchUsers(setUserList);
        fetchRoleOptions(setRoleOptions);
        fetchDivisionOptions(setDivisionOptions, setDivision);
    }, []);

    useEffect(() => {
        division !== '' &&
            fetchGhatOptionsByDivision(division, setGhatOptions, setGhat);
    }, [division]);

    const handleEditClick = async (id: number) => {
        const user = userList.find(user => user.id === id);
        user && setUserForm({
            id: user.id,
            fullName: user.fullName,
            username: user.username,
            enabled: user.enabled,
            mobileNo: user.mobileNo,
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
            mpin: user.mpin,
            role: user.role.role,
            jurisdiction: user.jurisdiction,
            jurisdictionId: user.jurisdictionId
        });
        handleUserDetailsModalOpen();
    }

    const handleAddUserClick = () => {
        handleUserDetailsModalOpen();
        setUserForm(UserFormInitialState);
    }

    const handleSaveUserClick = () => {
        const errors = validateFields(null,
            { fullName: userForm.fullName },
            { username: userForm.username },
            { mobileNo: userForm.mobileNo },
            { email: userForm.email },
            { password: userForm.password },
            { confirmPassword: userForm.confirmPassword },
            { mpin: userForm.mpin },
            { role: userForm.role },
            { jurisdiction: userForm.jurisdiction }
        );
        if (errors) {
            setFormErrors(errors);
            return;
        }
        saveUser(userForm)
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDivision(e.target.value);
    }

    const handleFetchUserList = async () => {
        if (!division || division === '') {
            toast.error('Please select a division');
            return;
        }
        fetchUsers(setUserList, division);
    }

    return (
        <Body>

            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><AccountCircle /> User Management</h3>
            </div>

            <hr />

            <div className='grid grid-cols-1 lg:grid-cols-2 items-end mt-2'>
                <div className='flex w-max'>
                    <h4>User List</h4>
                </div>
                <div className='flex w-max ml-auto items-center gap-x-2'>
                    <FormControl
                        placeholder='Select Division'
                        type={'select'}
                        id={'divisionOption'}
                        onChange={handleSelectChange}
                        options={divisionOptions}
                        value={division}
                    />
                    <SecondaryButton2
                        onClick={handleFetchUserList}
                        buttonText='Fetch Users'
                        buttonIcon={BrowserUpdated}
                    />
                    <PrimaryButton
                        onClick={handleAddUserClick}
                        buttonText='Create User'
                        buttonIcon={AddRounded}
                    />
                </div>
            </div>

            <UserTable
                rows={userList}
                handleEditClick={handleEditClick}
            />

            <ModalWrapper
                open={userDetailsModalOpen}
                handleClose={handleUserDetailsModalClose}
                title={'User Details'}
            >
                <UserForm
                    userForm={userForm}
                    setUserForm={setUserForm}
                    division={division}
                    ghat={ghat}
                    setDivision={setDivision}
                    setGhat={setGhat}
                    roleOptions={roleOptions}
                    divisionOptions={divisionOptions}
                    ghatOptions={ghatOptions}
                />
                <PrimaryButton
                    fullWidth={true}
                    buttonText={'Save User'}
                    onClick={handleSaveUserClick}
                />
            </ModalWrapper>
        </Body>
    )
}

export default UserManagement