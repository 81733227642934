import { KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded, Logout } from '@mui/icons-material';
import { UserCircle } from 'lucide-react';
import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LogoutAction from '../../auth/LogoutAction';
import { ROUTES } from '../../constants/routes-constants';
import useAuth from '../../hooks/useAuth';

interface SidebarProps {
    children: React.ReactNode
    sidebarExpanded: boolean;
    setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SidebarContextProps {
    expanded: boolean;
}

export const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

const Sidebar: React.FC<SidebarProps> = ({ ...props }) => {

    const { auth } = useAuth();

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <aside className="h-screen w-max">
            <nav className="h-full flex flex-col bg-neutral-100 border-r shadow-sm">
                <div className="p-4 pb-2 flex justify-between items-center">
                    <button onClick={() => navigate(ROUTES.DIVISION_HEAD.DASHBOARD)} className={`-m-1.5 p-1.5 flex items-center gap-4 overflow-hidden transition-all ${props.sidebarExpanded ? "w-30" : "w-0"}`}>
                        <span className="sr-only">{props.sidebarExpanded ? t('portal_name_short') : ''}</span>
                        <img className="h-8 w-auto" src={require("../../resources/images/aiwtd_logo.png")} alt="" />
                        <span className="font-semibold text-wrap">{props.sidebarExpanded ? t('portal_name') : ''}</span>
                    </button>
                    <button
                        onClick={() => props.setSidebarExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                    >
                        {props.sidebarExpanded ? <KeyboardDoubleArrowLeftRounded /> : <KeyboardDoubleArrowRightRounded />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded: props.sidebarExpanded }}>
                    <ul className="flex-1 px-3 pt-4">{props.children}</ul>
                </SidebarContext.Provider>

                
                <div className="border-t flex p-3">
                    <div className='w-12 h-12 bg-indigo-200 rounded-md flex justify-center items-center'>
                        <UserCircle
                            className="w-6 h-6 text-indigo-800"
                        />
                    </div>
                    <div className={`flex justify-between items-center overflow-hidden transition-all ${props.sidebarExpanded ? "w-52 ml-3" : "w-0"}`}>
                        <div className="leading-4">
                            <h6 className="font-semibold">{auth.user?.fullName}</h6>
                            <span className="text-xs text-gray-600">{auth.user?.email}</span>
                        </div>
                    </div>
                </div>
                <div className='px-3 pb-4'>
                    <LogoutAction>
                        <li className={`relative flex items-center justify-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer 
                            bg-neutral-300 hover:bg-neutral-200 transition-colors group`}>
                            <Logout />
                            <span className={`overflow-hidden whitespace-nowrap text-ellipsis transition-all ${props.sidebarExpanded ? "w-52 ml-4" : "w-0"}`}>
                                Logout
                            </span>

                            {!props.sidebarExpanded && (
                                <div className={`absolute left-full rounded-md px-2 py-1 ml-6 text-sm invisible opacity-20 -translate-x-3 
                                    transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                                    Logout
                                </div>
                            )}
                        </li>
                    </LogoutAction>
                </div>
            </nav>
        </aside>
    )
}

export default Sidebar;