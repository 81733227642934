export const ROLES = {
    ANY_ROLE: [
        "SYSADMIN",
        "DIVISION_HEAD",
        "GHAT_IC",
        "TICKETING_AGENT",
        "TICKET_INSPECTOR",
        "USER",
        "DEPT_USER"
    ],
    SYSADMIN: "SYSADMIN",
    DIVISION_HEAD: "DIVISION_HEAD",
    GHAT_IC: "GHAT_IC",
    TICKETING_AGENT: "TICKETING_AGENT",
    TICKET_INSPECTOR: "TICKET_INSPECTOR",
    USER: "USER",
    DEPT_USER: "DEPT_USER",
}