import React from 'react'
import { useApiService } from '../../../hooks/useApiService';
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { IAgeWisePassenger, ICategoryWiseVehicle, IDivHeadDashboardPost, IGenderWisePassenger, IMisDashboardData, IPaymentModeWiseRevenue } from './div-head-dashboard-interfaces';
import API_ENDPOINT from '../../../constants/api-constants';
import { AxiosResponse } from 'axios';

export const useDivHeadDashboardService = () => {

    const { postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchDivHeadDashboardData = async (
        data: IDivHeadDashboardPost,
        setData: React.Dispatch<React.SetStateAction<IMisDashboardData>>
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MIS.MIS_DASHBOARD_REPORT, data);
            setData(response.data.object);
        } catch (error) {
            handleError(error);
        }
    }

    const getFilteredGenderWisePassenger = (
        serviceType: string,
        owner: string,
        vesselType: string,
        data: IGenderWisePassenger[],
        setData: React.Dispatch<React.SetStateAction<IGenderWisePassenger>>
    ) => {
        // Initialize an object to store the aggregated data
        const aggregatedData: IGenderWisePassenger = {
            owner: owner === "All" ? "All" : owner,
            vesselType: vesselType === "All" ? "All" : vesselType,
            serviceType: serviceType === "All" ? "All" : serviceType,
            maleCount: 0,
            femaleCount: 0,
            otherCount: 0
        };

        // Iterate over the list and aggregate data for matching entries
        for (const entry of data) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Aggregate the values
                aggregatedData.maleCount += entry.maleCount;
                aggregatedData.femaleCount += entry.femaleCount;
                aggregatedData.otherCount += entry.otherCount;
            }
        }
        // Convert the map back to an array
        setData(aggregatedData);
    }

    const getFilteredAgeWisePassenger = (
        serviceType: string,
        owner: string,
        vesselType: string,
        data: IAgeWisePassenger[],
        setData: React.Dispatch<React.SetStateAction<IAgeWisePassenger>>
    ) => {
        // Initialize an object to store the aggregated data
        const aggregatedData: IAgeWisePassenger = {
            owner: owner === "All" ? "All" : owner,
            vesselType: vesselType === "All" ? "All" : vesselType,
            serviceType: serviceType === "All" ? "All" : serviceType,
            adultCount: 0,
            childCount: 0,
            infantCount: 0,
            seniorCount: 0
        };

        // Iterate over the list and aggregate data for matching entries
        for (const entry of data) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" ? "All" : owner) &&
                (vesselType === "All" ? "All" : vesselType)
            ) {
                // Aggregate the values
                aggregatedData.adultCount += entry.adultCount;
                aggregatedData.childCount += entry.childCount;
                aggregatedData.infantCount += entry.infantCount;
                aggregatedData.seniorCount += entry.seniorCount;
            }
        }
        // Convert the map back to an array
        setData(aggregatedData);
    }


    const getFilteredCategoryWiseVehicle = (
        serviceType: string,
        owner: string,
        vesselType: string,
        data: ICategoryWiseVehicle[],
        setData: React.Dispatch<React.SetStateAction<ICategoryWiseVehicle>>
    ) => {
        // Initialize an object to store the aggregated data
        const aggregatedData: ICategoryWiseVehicle = {
            owner: owner === "All" ? "All" : owner,
            vesselType: vesselType === "All" ? "All" : vesselType,
            serviceType: serviceType === "All" ? "All" : serviceType,
            bicycle: 0,
            twoWheeler: 0,
            threeWheeler: 0,
            fourWheeler: 0,
            hmv: 0
        };

        // Iterate over the list and aggregate data for matching entries
        for (const entry of data) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Aggregate the values
                aggregatedData.bicycle += entry.bicycle;
                aggregatedData.twoWheeler += entry.twoWheeler;
                aggregatedData.threeWheeler += entry.threeWheeler;
                aggregatedData.fourWheeler += entry.fourWheeler;
                aggregatedData.hmv += entry.hmv;
            }
        }
        // Convert the map back to an array
        setData(aggregatedData);
    }


    const getFilteredPaymentModeWiseRevenue = (
        serviceType: string,
        owner: string,
        vesselType: string,
        data: IPaymentModeWiseRevenue[],
        setData: React.Dispatch<React.SetStateAction<IPaymentModeWiseRevenue>>
    ) => {
        // Initialize an object to store the aggregated data
        const aggregatedData: IPaymentModeWiseRevenue = {
            owner: owner === "All" ? "All" : owner,
            vesselType: vesselType === "All" ? "All" : vesselType,
            serviceType: serviceType === "All" ? "All" : serviceType,
            cash: 0,
            online: 0,
            pos: 0
        };

        // Iterate over the list and aggregate data for matching entries
        for (const entry of data) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" ? "All" : owner) &&
                (vesselType === "All" ? "All" : vesselType)
            ) {
                // Aggregate the values
                aggregatedData.cash += entry.cash;
                aggregatedData.online += entry.online;
                aggregatedData.pos += entry.pos;
            }
        }
        // Convert the map back to an array
        setData(aggregatedData);
    }

    return {
        fetchDivHeadDashboardData,
        getFilteredGenderWisePassenger,
        getFilteredAgeWisePassenger,
        getFilteredCategoryWiseVehicle,
        getFilteredPaymentModeWiseRevenue
    }
}
