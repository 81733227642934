import { Add } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import FormControl from '../../../../components/forms/FormControl';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../../constants/type-constants';
import { useCommonService } from '../../../../hooks/useCommonService';
import { useFormErrorContext } from '../../../../hooks/useFormErrorContext';
import { validationService } from '../../../../services/validationService';
import { dateUtil } from '../../../../utility/dateUtil';
import { IInfant, InitialModalDetails, InitialPassengerState, ModalDetails, Passenger } from '../ticket-booking-interfaces';

interface PassengerModalProps {
    passengerList: Passenger[]
    setPassengerList: React.Dispatch<React.SetStateAction<Passenger[]>>
    modalDetails: ModalDetails
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    availability: number
}

const PassengerModal: React.FC<PassengerModalProps> = ({ ...props }) => {

    const { t } = useTranslation();
    const { fetchGenders, fetchPwd, fetchInfantAgeGroupOptions } = useCommonService();
    const { setFormErrors } = useFormErrorContext();

    const [genderOptions, setGenderOptions] = useState<SelectOption[]>([]);
    const [pwdOptions, setPwdOptions] = useState<SelectOption[]>([]);
    const [infantAgeGroupOptions, setInfantAgeGroupOptions] = useState<SelectOption[]>([]);

    const [isCarryingInfant, setIsCarryingInfant] = useState(false);
    const [infantList, setInfantList] = useState<IInfant[]>([]);

    const [passengerModalOpen, setPassengerModalOpen] = useState(false);
    const handleModalOpen = () => {
        if (props.availability === 0) {
            toast.error("No seat available to book.");
            return;
        }
        setPassengerModalOpen(true);
    }
    const handleModalClose = () => {
        props.setModalDetails(InitialModalDetails);
        setPassengerModalOpen(false)
    };

    const [passengerForm, setPassengerForm] = useState<Passenger>(InitialPassengerState);

    useEffect(() => {
        fetchGenders(setGenderOptions);
        fetchPwd(setPwdOptions);
        fetchInfantAgeGroupOptions(setInfantAgeGroupOptions);
    }, []);

    useEffect(() => {
        if (isCarryingInfant) {
            console.log(`Carrying Infant: ${isCarryingInfant}`);
            setInfantList([
                {
                    infantName: '',
                    infantGender: genderOptions[0]?.value,
                    infantAgeCode: infantAgeGroupOptions[0]?.value,
                }
            ]);
        } else {
            setInfantList([]);
        }
    }, [isCarryingInfant]);

    useEffect(() => {
        console.log(`Infant List: ${JSON.stringify(infantList)}`);

        if (infantList.length > 0) {
            setPassengerForm((prevForm) => ({
                ...prevForm,
                infants: infantList
            }));
        }
    }, [infantList])

    useEffect(() => {
        if (props.modalDetails.itemType === 'seat') {
            if (props.modalDetails.action === 'add') {
                const id = dateUtil.getTimestamp().toString();
                setPassengerForm({
                    id: id,
                    passengerName: '',
                    email: '',
                    mobileNo: '',
                    age: 0,
                    gender: genderOptions[0]?.value,
                    pwd: pwdOptions[0]?.value,
                    uniqueId: id,
                    infants: []
                })
                setInfantList([]);
                setIsCarryingInfant(false);
            } else if (props.modalDetails.action === 'edit') {
                const passenger = props.passengerList.find(passenger => passenger.id === props.modalDetails.id);
                if (passenger) {
                    setPassengerForm(passenger);
                    (passenger.infants.length > 0) ? setIsCarryingInfant(true) : setIsCarryingInfant(false);

                } else {
                    toast.error("Passenger not found!");
                }
            }
            handleModalOpen();
        }
    }, [props.modalDetails])

    useEffect(() => {
        if (isCarryingInfant && props.modalDetails.action === 'edit') {
            const passenger = props.passengerList.find(passenger => passenger.id === props.modalDetails.id);
            if (passenger && passenger.infants.length > 0) {
                setInfantList(passenger.infants);
            } else {
                setInfantList([
                    {
                        infantName: '',
                        infantGender: genderOptions[0]?.value,
                        infantAgeCode: infantAgeGroupOptions[0]?.value,
                    }
                ]);
            }
        }
    }, [isCarryingInfant, props.modalDetails.action, props.passengerList]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;

        if (name === 'mobileNo' && !validationService.isValidMobileInput(value)) {
            return;
        }
        setPassengerForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCarryingInfant(e.target.checked);
    }

    const handleInfantInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;
        const index = Number(name.split('_')[1]);
        const fieldName = name.split('_')[0];
        setInfantList((prevList) =>
            prevList.map((infant, i) =>
                i === index
                    ? { ...infant, [fieldName]: value }
                    : infant
            )
        );
    }

    const handleAddInfantClick = () => {
        if (infantList.length === 2) {
            toast.error("Maximum of 2 infants allowed");
            return
        }

        if (infantList.length === 1) {
            setInfantList((prevList) => [
                ...prevList,
                {
                    infantName: '',
                    infantGender: genderOptions[0]?.value,
                    infantAgeCode: infantAgeGroupOptions[0]?.value
                }
            ]);
        }
    }

    const handleSaveClick = () => {
        const errorDetails: {
            passengerName?: string;
            age?: string;
            gender?: string;
            pwd?: string;
        } = {};

        if (passengerForm.passengerName === '') {
            errorDetails.passengerName = "Please enter name";
        }
        if (!passengerForm.age || passengerForm.age < 1) {
            errorDetails.age = "Please enter age";
        }
        if (passengerForm.gender === '') {
            errorDetails.gender = "Please select gender";
        }

        if (Object.keys(errorDetails).length > 0) {
            setFormErrors(errorDetails);
            return;
        }

        const passengerIndex = props.passengerList.findIndex(p => p.id == passengerForm.id);

        if (passengerIndex != -1) {
            const updatedPassengerList = [...props.passengerList];
            updatedPassengerList[passengerIndex] = passengerForm;
            props.setPassengerList(updatedPassengerList);
            toast.success('Passenger Details Updated');
        } else {
            props.setPassengerList([...props.passengerList, passengerForm]);
            toast.success('Passenger Added');
        }
        handleModalClose();
    }

    return (
        <ModalWrapper
            title={t('passenger_details')}
            open={passengerModalOpen}
            handleClose={handleModalClose} >
            <div id="passengerForm" className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-2/4 lg:pr-4'>
                    <input type="text" value={passengerForm.id} readOnly hidden />
                    <FormControl
                        label={t('name')}
                        type={'text'}
                        id={'passengerName'}
                        value={passengerForm.passengerName}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4 lg:pr-3'>
                    <FormControl
                        label={t('age')}
                        type={'number'}
                        id={'age'}
                        value={passengerForm.age}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/4'>
                    <FormControl
                        type={'select'}
                        id={'gender'}
                        label={t('gender')}
                        options={genderOptions}
                        value={passengerForm.gender}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label={t('phone')}
                        type={'text'}
                        id={'mobileNo'}
                        value={passengerForm.mobileNo}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        label={t('email')}
                        type={'email'}
                        id={'email'}
                        value={passengerForm.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3'>
                    <FormControl
                        type={'select'}
                        id={'pwd'}
                        label={t('differently_abled')}
                        options={pwdOptions}
                        value={passengerForm.pwd}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                    <FormControl
                        type={'checkbox'}
                        id={'carryingInfant'}
                        label={`${t('carrying_infant')}?`}
                        value={isCarryingInfant}
                        onChange={handleCheckboxChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'></div>
                <div className='w-full mb-3 lg:w-1/3'>
                    {
                        infantList.length === 1 &&
                        <div className='flex justify-end'>
                            <button
                                className='text-sky-600 border-b border-sky-800 hover:border-sky-700 font-medium text-sm text-center'
                                onClick={handleAddInfantClick}>
                                {t('add_more_infant')} <Add />
                            </button>
                        </div>
                    }
                </div>
                {isCarryingInfant &&
                    infantList.map((infant, index) => {
                        return (
                            <>
                                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                                    <FormControl
                                        type={'text'}
                                        id={'infantName_' + index}
                                        label={t('infant_name')}
                                        value={infant.infantName}
                                        onChange={handleInfantInputChange}
                                    />
                                </div>
                                <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                                    <FormControl
                                        type={'select'}
                                        id={'infantGender_' + index}
                                        label={t('infant_gender')}
                                        options={genderOptions}
                                        value={infant.infantGender}
                                        onChange={handleInfantInputChange}
                                        mandatoryField={true}
                                    />
                                </div>
                                <div className='w-full mb-3 lg:w-1/3'>
                                    <FormControl
                                        type={'select'}
                                        id={'infantAgeCode_' + index}
                                        label={t('infant_age_code')}
                                        value={infant.infantAgeCode}
                                        options={infantAgeGroupOptions}
                                        onChange={handleInfantInputChange}
                                        mandatoryField={true}
                                    />
                                </div>
                            </>
                        )
                    })
                }

                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveClick}
                        buttonText={t('save_passenger_details')}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default PassengerModal;