import { DirectionsBoat, DisplaySettings, ModeOfTravel, SettingsRounded, ShowChart } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MisFilterInputGroup from '../../../components/forms/MisFilterInputGroup';
import AdminLayout, { DivHeadPageType } from '../../../components/layouts/AdminLayout';
import { ROUTES } from '../../../constants/routes-constants';
import { IVesselWiseRevenue, ownerFilterOptions, serviceTypeFilterOptions, vesselTypeFilterOptions } from '../mis/mis-interfaces';
import { useRevenueService } from '../mis/useRevenueService';
import { IAgeWisePassenger, ICategoryWiseVehicle, IGenderWisePassenger, IMisDashboardData, initialAgeWisePassenger, initialCategoryWiseVehicle, initialGenderWisePassenger, initialPaymentModeWiseRevenue, IPaymentModeWiseRevenue } from './div-head-dashboard-interfaces';
import PassengerDemographicsCard from './sections/PassengerDemographicsCard';
import RevenueDistributionCard from './sections/RevenueDistributionCard';
import VehicleBreakdownCard from './sections/VehicleBreakdownCard';
import { useDivHeadDashboardService } from './useDivHeadDashboardService';
import AppliedFiltersDisplay from './sections/AppliedFiltersDisplay';

const initialDashboardDataState: IMisDashboardData = {
    noOfVessels: 0,
    noOfTrips: 0,
    noOfTripSettings: 0,
    genderWisePassenger: [],
    ageWisePassenger: [],
    categoryWiseVehicle: [],
    paymentModeWiseRevenue: [],
    vesselWiseRevenue: []
}

const DivHeadDashboard: React.FC = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState<IMisDashboardData>(initialDashboardDataState);

    const [genderWisePassenger, setGenderWisePassenger] = useState<IGenderWisePassenger>(initialGenderWisePassenger);
    const [ageWisePassenger, setAgeWisePassenger] = useState<IAgeWisePassenger>(initialAgeWisePassenger);
    const [categoryWiseVehicle, setCategoryWiseVehicle] = useState<ICategoryWiseVehicle>(initialCategoryWiseVehicle);
    const [paymentModeWiseRevenue, setPaymentModeWiseRevenue] = useState<IPaymentModeWiseRevenue>(initialPaymentModeWiseRevenue);
    const [vesselWiseRevenue, setVesselWiseRevenue] = useState<IVesselWiseRevenue[]>([]);

    const [adminLinks, setAdminLinks] = useState<{ name: string, href: string, icon: any, subtitle: string }[]>([
        { name: 'Vessel Management', href: ROUTES.DIVISION_HEAD.VESSEL, icon: DirectionsBoat, subtitle: 'Total: 0 Vessels' },
        { name: 'Trip Settings', href: ROUTES.DIVISION_HEAD.TRIP_SETTINGS, icon: SettingsRounded, subtitle: 'Total: 0 Settings' },
        { name: 'Trip Management', href: ROUTES.DIVISION_HEAD.TRIP, icon: ModeOfTravel, subtitle: 'Total: 0 Active Trips' },
    ])

    const {
        fetchDivHeadDashboardData,
        getFilteredAgeWisePassenger,
        getFilteredGenderWisePassenger,
        getFilteredCategoryWiseVehicle,
        getFilteredPaymentModeWiseRevenue
    } = useDivHeadDashboardService();

    const { getFilteredVesselWiseTotalRevenueCounts } = useRevenueService();

    const [serviceType, setServiceType] = useState<string>(serviceTypeFilterOptions[0].value);
    const [owner, setOwner] = useState<string>(ownerFilterOptions[0].value);
    const [vesselType, setVesselType] = useState<string>(vesselTypeFilterOptions[0].value);

    const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(1, "month")); // Default: 1 month prior
    const [toDate, setToDate] = useState<Dayjs>(dayjs()); // Default: Today

    useEffect(() => {
        handleFetchCall();
    }, []);

    useEffect(() => {
        setAdminLinks([
            { name: 'Vessel Management', href: ROUTES.DIVISION_HEAD.VESSEL, icon: DirectionsBoat, subtitle: `Total: ${dashboardData.noOfVessels} Vessels` },
            { name: 'Trip Settings', href: ROUTES.DIVISION_HEAD.TRIP_SETTINGS, icon: SettingsRounded, subtitle: `Total: ${dashboardData.noOfTripSettings} Settings` },
            { name: 'Trip Management', href: ROUTES.DIVISION_HEAD.TRIP, icon: ModeOfTravel, subtitle: `Total: ${dashboardData.noOfTrips} Active Trips` },
        ])
    }, [dashboardData]);

    const handleSearchClick = async () => await handleFetchCall();

    const handleFetchCall = async () => {
        await fetchDivHeadDashboardData({
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD')
        }, setDashboardData);
    }

    useEffect(() => {
        getFilteredAgeWisePassenger(serviceType, owner, vesselType, dashboardData.ageWisePassenger, setAgeWisePassenger);
        getFilteredGenderWisePassenger(serviceType, owner, vesselType, dashboardData.genderWisePassenger, setGenderWisePassenger);
        getFilteredCategoryWiseVehicle(serviceType, owner, vesselType, dashboardData.categoryWiseVehicle, setCategoryWiseVehicle);
        getFilteredPaymentModeWiseRevenue(serviceType, owner, vesselType, dashboardData.paymentModeWiseRevenue, setPaymentModeWiseRevenue);
        getFilteredVesselWiseTotalRevenueCounts(serviceType, owner, vesselType, dashboardData.vesselWiseRevenue, setVesselWiseRevenue);
    }, [serviceType, owner, vesselType, dashboardData])

    return (
        <AdminLayout activeLink={DivHeadPageType.DASHBOARD}>
            <div className='flex items-center space-x-2'>
                <DisplaySettings />
                <h4 className='mb-0'>Master Management</h4>
            </div>

            <div className="mx-auto max-w-screen-xl items-center py-1 mt-2">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4">
                    {adminLinks.map((item) => (
                        <div key={item.name}
                            className="w-full group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-sky-50 bg-white shadow-sm hover:shadow-lg">
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white group-hover:bg-white">
                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" />
                            </div>
                            <div className="flex-auto">
                                <button onClick={() => navigate(item.href)} className="block font-semibold text-gray-900 text-left">
                                    {item.name}
                                    <br />
                                    <span className="absolute inset-0" />
                                    <span className='font-normal text-xs text-gray-500'>{item.subtitle}</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='mt-8 mb-4'>
                <div className='flex items-center space-x-2'>
                    <ShowChart />
                    <h4 className='mb-0'>Cummulative Statistics</h4>
                </div>
                {/* Filter Section */}
                <MisFilterInputGroup
                    serviceType={serviceType}
                    setServiceType={setServiceType}
                    owner={owner}
                    setOwner={setOwner}
                    vesselType={vesselType}
                    setVesselType={setVesselType}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSearchClick={handleSearchClick}
                />
            </div>

            <div>
                <AppliedFiltersDisplay
                    fromDate={fromDate.format('DD-MM-YYYY')}
                    toDate={toDate.format('DD-MM-YYYY')}
                    serviceType={serviceType}
                    owner={owner}
                    vesselType={vesselType}
                    setServiceType={setServiceType}
                    setOwner={setOwner}
                    setVesselType={setVesselType}
                />
            </div>

            <RevenueDistributionCard
                vesselWiseRevenue={vesselWiseRevenue}
                paymentModeWiseRevenue={paymentModeWiseRevenue}
                fromDate={fromDate.format('DD-MM-YYYY')}
                toDate={toDate.format('DD-MM-YYYY')}
                serviceType={serviceType}
                owner={owner}
                vesselType={vesselType}
            />
            <PassengerDemographicsCard
                genderWisePassenger={genderWisePassenger}
                ageWisePassenger={ageWisePassenger}
                fromDate={fromDate.format('DD-MM-YYYY')}
                toDate={toDate.format('DD-MM-YYYY')}
                serviceType={serviceType}
                owner={owner}
                vesselType={vesselType}
            />
            <VehicleBreakdownCard
                categoryWiseVehicle={categoryWiseVehicle}
                fromDate={fromDate.format('DD-MM-YYYY')}
                toDate={toDate.format('DD-MM-YYYY')}
                serviceType={serviceType}
                owner={owner}
                vesselType={vesselType}
            />
        </AdminLayout>
    )
}

export default DivHeadDashboard
