import React from 'react'

interface SecondaryButton2Props {
    onClick: any
    buttonText: React.ReactNode | string
    buttonIcon?: React.ElementType;
    fullWidth?: boolean
}

const SecondaryButton2: React.FC<SecondaryButton2Props> = ({ onClick, buttonText, buttonIcon: ButtonIcon, fullWidth }) => {
    return (
        <button className={`text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 
        transition duration-300 
        focus:outline-none focus:ring-slate-300 font-medium rounded-lg px-5 py-2.5 text-center 
        ${fullWidth ? `w-full ` : ` `}
        `}
            onClick={onClick}
        >
            {buttonText}
            {ButtonIcon && <ButtonIcon className='ml-2 -mt-1' />}
        </button>
    );
}

export default SecondaryButton2;