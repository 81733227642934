import React, { useEffect, useState } from 'react';
import Body from '../../../components/layouts/Body';
import BicycleIcon from '../../../resources/icons/bicycle-icon.svg';
import BikeIcon from '../../../resources/icons/bike-icon.svg';
import CarIcon from '../../../resources/icons/car-icon.svg';
import LuggageIcon from '../../../resources/icons/luggage-icon.svg';
import SeatIcon from '../../../resources/icons/seat-icon.svg';

import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import { ROUTES } from '../../../constants/routes-constants';
import useAuth from '../../../hooks/useAuth';
import { dateUtil } from '../../../utility/dateUtil';
import { IDeckItem } from '../../../utility/deckItemUtil';
import { useTranslation } from 'react-i18next';
import { InitialRouteState, IRouteDetail, ISearchResult } from '../../unlogged/search-results/search-interfaces';
import { useSearchResultService } from '../../unlogged/search-results/useSearchResultsService';
import { IBookingTicketDetails } from '../booking-history/ticket-details/booking-ticket-details-interfaces';
import { useTicketRebookService } from './useTicketRebookService';


const RebookSearchResults: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { auth } = useAuth();

    const [route, setRoute] = useState<IRouteDetail>(InitialRouteState);

    const [tripDate, setTripDate] = useState('');
    const [itemList, setItemList] = useState<IDeckItem[]>([]);
    const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
    const [bookingTicketDetails, setBookingTicketDetails] = useState<IBookingTicketDetails | null>(null);

    const {
        processDeckItems,
        handleInputChange,
        initiateBooking,
    } = useSearchResultService({
        route,
        searchResults,
        setSearchResults,
        setItemList
    });

    const {
        transformPassengers,
        transformVehicles,
        transformGoods } = useTicketRebookService(t);

    useEffect(() => {
        const storedBookingTicketDetails = localStorage.getItem('bookingTicketDetails');
        if (storedBookingTicketDetails) {
            const parsedBookingTicketDetails: IBookingTicketDetails = JSON.parse(storedBookingTicketDetails);
            setBookingTicketDetails(parsedBookingTicketDetails);
        }
    }, []);

    useEffect(() => {
        const storedBookingTicketDetails = localStorage.getItem('bookingTicketDetails');
        if (storedBookingTicketDetails) {
            const parsedBookingTicketDetails: IBookingTicketDetails = JSON.parse(storedBookingTicketDetails);
            setBookingTicketDetails(parsedBookingTicketDetails);
            const storedResults = localStorage.getItem('searchResults');
            if (storedResults) {
                const parsedResults: ISearchResult[] = JSON.parse(storedResults).map((result: ISearchResult) => {
                    result.quantity = parsedBookingTicketDetails?.passengers?.length || 0;
                    return result;
                });
                setSearchResults(parsedResults);

            } else {
                navigate(ROUTES.USER.REBOOK_TICKET);
            }
        }
    }, []);

    useEffect(() => {
        if (auth.user) {
            const savedInput = sessionStorage.getItem('pendingBookingInput');
            const savedTripId = sessionStorage.getItem('pendingBookingTripId');
            if (savedInput && savedTripId) {
                proceedWithBooking(savedTripId, savedInput);
                sessionStorage.removeItem('pendingBookingInput');
                sessionStorage.removeItem('pendingBookingTripId');
            }
        }
    }, [auth.user]);

    useEffect(() => {
        if (searchResults.length > 0) {
            setRoute(searchResults[0]?.route);
            setTripDate(dateUtil.formatDateWithDay(searchResults[0]?.tripDate));
            processDeckItems();
        }
    }, [searchResults]);

    const handleBookTicketClick = async (tripId: string) => {
        const quantity = searchResults.find(trip => trip.id === tripId)?.quantity;

        if (!quantity) {
            toast.error("Quantity not found!");
            return;
        }

        if (!auth.user) {
            sessionStorage.setItem('pendingBookingInput', quantity.toString());
            sessionStorage.setItem('pendingBookingTripId', tripId);
            navigate(ROUTES.AUTH.LOGIN, { state: { from: location } });
            return;
        }

        await proceedWithBooking(tripId, quantity.toString());
    };

    const proceedWithBooking = async (tripId: string, inputValue: string) => {
        const booking = await initiateBooking(tripId, inputValue);

        if (booking && bookingTicketDetails) {

            const transformedPassengers = transformPassengers(bookingTicketDetails.passengers);
            const transformedVehicles = transformVehicles(bookingTicketDetails.vehicles, transformedPassengers);
            const transformedGoods = transformGoods(bookingTicketDetails.goods);

            localStorage.setItem('passengerList', JSON.stringify(transformedPassengers));
            localStorage.setItem('vehicleList', JSON.stringify(transformedVehicles));
            localStorage.setItem('goodsList', JSON.stringify(transformedGoods));

            navigate(ROUTES.USER.TICKET_BOOKING, { state: { bookingId: booking.id, tripId: tripId } });
        }
    };

    return (
        <Body>
            <div>
                <div className='border rounded-lg py-2 px-8 border-sky-100 mb-4 bg-white bg-opacity-50'>
                    <div className='text-lg font-semibold text-sky-500'>
                        {t('vessel_list')}
                    </div>
                    <div className='flex flex-wrap w-full md:justify-between'>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>{t('origin')}</div>
                            <div className='font-semibold text-neutral-600'>{route.ghatFrom.ghatName}</div>
                        </div>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>{t('destination')}</div>
                            <div className='font-semibold text-neutral-600'>{route.ghatTo.ghatName}</div>
                        </div>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>{t('date')}</div>
                            <div className='font-semibold text-neutral-600'>{tripDate}</div>
                        </div>
                    </div>
                </div>
                {
                    searchResults.length > 0 &&
                    searchResults.map(result => {
                        const itemSectionContents = [
                            { icon: SeatIcon, count: result.onlineSeatAvailable, label: t('seats_available') },
                            { icon: BicycleIcon, count: itemList.find(item => item.trip == result.id)?.bicycleCapacity, label: t('slots_available') },
                            { icon: BikeIcon, count: itemList.find(item => item.trip == result.id)?.twoWheelerCapacity, label: t('slots_available') },
                            { icon: CarIcon, count: itemList.find(item => item.trip == result.id)?.fourWheelerCapacity, label: t('slots_available') },
                            { icon: LuggageIcon, count: itemList.find(item => item.trip == result.id)?.goodsCapacity, label: t('qls_available') },
                        ]
                        return (
                            <React.Fragment key={result.id}>
                                <Card addClass={'mb-4'}>
                                    <div>
                                        <div className='grid grid-cols-1 lg:grid-cols-2'>
                                            <div>
                                                <h4 className="font-semibold text-sky-500">{result.vessel.name}</h4>
                                                <p className="text-sky-500 font-medium">{dateUtil.formatTime(result.departureTime)} | {route.ghatFrom.ghatName}</p>
                                            </div>
                                            <div className='flex justify-end items-end'>
                                                <div className='pr-4'>
                                                    <FormControl
                                                        label={t('no_of_passengers')}
                                                        type={'number'}
                                                        id={'qty_' + result.id}
                                                        max={10}
                                                        onChange={handleInputChange(result.id)}
                                                        placeholder='Maximum 10'
                                                        value={result.quantity}
                                                    />
                                                </div>
                                                <div className='w-full lg:w-auto mt-2'>
                                                    <DefaultButton
                                                        isDisabled={result.quantity <= 0}
                                                        buttonText={t('book_tickets')}
                                                        onClick={() => handleBookTicketClick(result.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className='my-2' />
                                        <div className="flex items-center justify-between mt-4">
                                            {
                                                itemSectionContents.map(((section, index) => (
                                                    <div key={index} className="flex items-center w-1/5 flex-col text-center lg:mx-8">
                                                        <div className='flex justify-center'>
                                                            <img className='w-8' src={section.icon} alt="Seat" />
                                                        </div>
                                                        <span className="text-lg text-neutral-600 font-semibold">{section.count}</span>
                                                        <span className="text-xs md:text-sm text-neutral-600">{section.label}</span>
                                                    </div>
                                                )))
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </Body>
    )
}

export default RebookSearchResults;