import React from 'react'
import { useApiService } from '../../../hooks/useApiService'
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { AxiosResponse } from 'axios';
import API_ENDPOINT from '../../../constants/api-constants';
import { ICapacityUtilisationReportPost, IRevenueData, IRevenueReportPost } from './mis-interfaces';

const useMisService = () => {

    const { postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchRevenueReportData = async (
        postData: IRevenueReportPost,
        setRevenueData: React.Dispatch<React.SetStateAction<IRevenueData>>
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MIS.REVENUE_REPORT,
                postData
            )
            const { object } = response.data;
            setRevenueData(object);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchCapacityUtilisationData = async (
        postData: ICapacityUtilisationReportPost,
        setCapacityData: React.Dispatch<React.SetStateAction<any>>
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MIS.CAPACITY_UTILISATION,
                postData
            )
            const { object } = response.data;
            setCapacityData(object);

        } catch (error) {
            handleError(error);
        }
    }

    const filterRevenueReport = (
        serviceType: string,
        owner: string,
        vesselType: string,
        revenueReport: IRevenueData,
        setFilteredRevenueReport: React.Dispatch<React.SetStateAction<IRevenueData>>
    ) => {
        return null;
    }

    return {
        fetchRevenueReportData,
        fetchCapacityUtilisationData
    }
}

export default useMisService
