import { AxiosResponse } from 'axios';
import API_ENDPOINT from '../../../../constants/api-constants';
import { useApiService } from '../../../../hooks/useApiService';
import { useHandleErrorService } from '../../../../hooks/useHandleErrorService';
import { BookingTicketTypes } from '../../payment/order-details-interfaces';
import toast from 'react-hot-toast';

export default function useBookingTicketDetailsService(t: (key: string) => string) {

    const { postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const getTableData = (type: BookingTicketTypes) => {
        const headersMap = {
            passengers: [
                { name: 'ticketNo', label: t('ticket_no') },
                { name: 'passengerName', label: t('name') },
                { name: 'gender', label: t('gender') },
                { name: 'age', label: t('age') },
                { name: 'pwd', label: t('differently_abled') },
                { name: 'rate', label: t('price') }
            ],
            vehicles: [
                { name: 'ticketNo', label: t('ticket_no') },
                { name: 'item.itemType', label: t('vehicle_type') },
                { name: 'regNo', label: t('registration_no') },
                { name: 'model', label: t('vehicle_model') },
                { name: 'ownerName', label: t('owner') },
                { name: 'rate', label: '' }
            ],
            goods: [
                { name: 'ticketNo', label: t('ticket_no') },
                { name: 'description', label: t('description') },
                { name: 'weight', label: t('weight') },
                { name: 'volume', label: t('volume') },
                { name: 'rate', label: '' }
            ]
        };

        const titlesMap = {
            passengers: t('passengers'),
            vehicles: t('vehicles'),
            goods: t('goods')
        };

        return {
            title: titlesMap[type],
            headers: headersMap[type]
        }
    }

    const cancelTicket = async (
        bookingNo: string
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.CANCEL.CANCEL_TICKETS,
                {
                    bookingNo: bookingNo
                }
            )
            toast.success(response.data.message);
        } catch (error) {
            handleError(error);
        }
    }

    return {
        getTableData,
        cancelTicket
    }
}
