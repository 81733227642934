import React, { useEffect, useState } from 'react'
import { Goods, InitialGoodsState, ModalDetails } from '../ticket-booking-interfaces'
import { dateUtil } from '../../../../utility/dateUtil'
import toast from 'react-hot-toast'
import ModalWrapper from '../../../../components/modals/ModalWrapper'
import FormControl from '../../../../components/forms/FormControl'
import DefaultButton from '../../../../components/buttons/DefaultButton'
import { useTranslation } from 'react-i18next'

interface GoodsModalProps {
    goodsList: Goods[]
    setGoodsList: React.Dispatch<React.SetStateAction<Goods[]>>
    modalDetails: ModalDetails
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    availability: number
}

const enum GoodsUnit {
    Weight = 'Weight',
    Volume = 'Volume'
}

const GoodsModal: React.FC<GoodsModalProps> = ({ ...props }) => {

    const { t } = useTranslation();
    const [goodsForm, setGoodsForm] = useState<Goods>(InitialGoodsState);
    const [unitOptions, setUnitOptions] = useState([
        { value: GoodsUnit.Weight, label: 'Weight (KG)' },
        { value: GoodsUnit.Volume, label: 'Volume (H x W x L)' }
    ]);

    const [weightFieldView, setWeightFieldView] = useState(false);
    const [volumeFieldView, setVolumeFieldView] = useState(false);

    const [goodsModalOpen, setGoodsModalOpen] = useState(false);
    const handleModalOpen = () => {
        // if (props.availability === 0) {
        //     toast.error("No slot available to book.");
        //     return;
        // }
        setGoodsModalOpen(true);
    }
    const handleModalClose = () => {
        props.setModalDetails({
            action: '',
            itemType: '',
            id: ''
        });
        setGoodsModalOpen(false)
    };

    useEffect(() => {
        if (props.modalDetails.itemType === 'goods') {
            if (props.modalDetails.action === 'add') {
                const id = dateUtil.getTimestamp().toString();
                setGoodsForm({
                    id: id,
                    description: '',
                    unit: unitOptions[0].value,
                    weight: 0,
                    height: 0,
                    width: 0,
                    length: 0
                })
            }
            handleModalOpen();
        }
    }, [props.modalDetails])

    useEffect(() => {
        if (goodsForm.unit === GoodsUnit.Weight) {
            setGoodsForm((prevForm) => ({
                ...prevForm,
                height: 0,
                width: 0,
                length: 0
            }))
            setWeightFieldView(true)
            setVolumeFieldView(false);
        }
        if (goodsForm.unit === GoodsUnit.Volume) {
            setGoodsForm((prevForm) => ({
                ...prevForm,
                weight: 0
            }))
            setWeightFieldView(false)
            setVolumeFieldView(true);
        }
    }, [goodsForm.unit])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;

        if (name === 'unit' && value === 'Volume') {
            toast.error("Volume not supported yet");
            return;
        }

        setGoodsForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    }

    const handleSaveClick = () => {
        const goodsIndex = props.goodsList.findIndex(goods => goods.id == goodsForm.id);
        if (goodsIndex != -1) {
            const updatedGoodsList = [...props.goodsList];
            updatedGoodsList[goodsIndex] = goodsForm;
            props.setGoodsList(updatedGoodsList)
            toast.success('Goods Details Updated');
        } else {
            props.setGoodsList([...props.goodsList, goodsForm]);
            toast.success('Goods Added');
        }
        handleModalClose();
    }

    return (
        <ModalWrapper
            open={goodsModalOpen}
            handleClose={handleModalClose}
            title={t('goods_details')} >
            <div id='goodsForm' className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-1/4 '>
                    <input type="text" value={goodsForm.id} readOnly hidden />
                    <FormControl
                        label={t('unit')}
                        type={'select'}
                        options={unitOptions}
                        id={'unit'}
                        value={goodsForm.unit}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                {
                    weightFieldView &&
                    <div className='w-full mb-3 lg:w-1/4 lg:pl-4'>
                        <input type="text" value={goodsForm.id} readOnly hidden />
                        <FormControl
                            label={t('weight')}
                            type={'number'}
                            id={'weight'}
                            value={goodsForm.weight}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                }
                {
                    volumeFieldView &&
                    <>
                        <div className='w-full mb-3 lg:w-1/5 lg:pl-4'>
                            <input type="text" value={goodsForm.id} readOnly hidden />
                            <FormControl
                                label={t('height')}
                                type={'number'}
                                id={'height'}
                                value={goodsForm.height}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                        </div>
                        <div className='w-full mb-3 lg:w-1/5 lg:pl-4'>
                            <input type="text" value={goodsForm.id} readOnly hidden />
                            <FormControl
                                label={t('width')}
                                type={'number'}
                                id={'width'}
                                value={goodsForm.width}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                        </div>
                        <div className='w-full mb-3 lg:w-1/5 lg:pl-4'>
                            <input type="text" value={goodsForm.id} readOnly hidden />
                            <FormControl
                                label={t('length')}
                                type={'number'}
                                id={'length'}
                                value={goodsForm.length}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                        </div>
                    </>
                }
                <div className='w-full mb-3'>
                    <FormControl
                        label={t('description')}
                        type={'text'}
                        id={'description'}
                        value={goodsForm.description}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveClick}
                        buttonText={t('save_goods_details')}
                    />
                </div>
            </div>
        </ModalWrapper >
    )
}

export default GoodsModal;