import { useNavigate } from "react-router-dom";
import API_ENDPOINT from "../constants/api-constants";
import { ROUTES } from "../constants/routes-constants";
import { device } from "../services/deviceDetailsService";
import { setAuthenticated } from "../store/actions/data";
import { useAppDispatch } from "../store/reducers/store";
import { useApiService } from "./useApiService";
import useAuth from "./useAuth";
import { useHandleErrorService } from "./useHandleErrorService";


export const useLogout = () => {
    const { auth, setAuth } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { postPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const logout = async () => {
        try {
            await postPublicRequest(API_ENDPOINT.AUTH.POST_SIGN_OUT, {
                userId: auth.user?.id,
                deviceType: device.getType(),
                deviceAddress: device.getAddress()
            }, true);

            setAuth({
                accessToken: '',
                tokenType: '',
                user: null,
                roles: null
            });
            dispatch(setAuthenticated(false));
            navigate(ROUTES.INDEX);

        } catch (error) {
            handleError(error);
        }
    };

    return { logout };
};

