import { SelectOption } from "../../../constants/type-constants";

export const serviceTypeFilterOptions: SelectOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Day', label: 'Day' },
    { value: 'Night', label: 'Night' }
];

export const ownerFilterOptions: SelectOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Government', label: 'Government' },
    { value: 'Private', label: 'Private' },
    // { value: 'Both', label: 'Both' },
];

export const vesselTypeFilterOptions: SelectOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Ferry', label: 'Ferry' },
    { value: 'Ropax', label: 'Ropax' }
];

// =============================================================================
// -----------REVENUE REPORT----------------------------------------------------
// =============================================================================

export interface IRouteWiseRevenue {
    owner: string;
    totalRevenue: number;
    routeId: number;
    vesselType: string;
    serviceType: string;
    pos: number;
    cash: number;
    sourceGhat: string;
    destinationGhat: string;
    noOfTrips: number;
    online: number;
}

export interface IVesselWiseRevenue {
    owner: string;
    totalRevenue: number;
    vesselType: string;
    serviceType: string;
    vesselName: string;
    pos: number;
    cash: number;
    vesselId: number;
    noOfTrips: number;
    online: number;
}

export interface ITotalRevenue {
    owner: string;
    totalRevenue: number;
    vesselType: string;
    serviceType: string;
    pos: number;
    cash: number;
    noOfTrips: number;
    online: number;
}

export interface IRevenueData {
    routeWiseRevenue: IRouteWiseRevenue[];
    vesselWiseRevenue: IVesselWiseRevenue[];
    totalRevenue: ITotalRevenue[];
}

export interface IRevenueReportPost {
    fromDate: string;
    toDate: string;
}

// =============================================================================
// -----------CAPACITY UTILISATION REPORT---------------------------------------
// =============================================================================

export interface ICapacityUtilisationReportPost {
    fromDate: string;
    toDate: string;
}

export interface ICapacityUtilisationReport {
    destination: string;
    owner: string;
    source: string;
    departureTime: string;
    goodsCapacity: number;
    twoWheelerCapacity: number;
    fourWheelerCapacity: number;
    tripDate: string;
    vesselType: string;
    serviceType: string;
    vesselName: string;
    passengerUtilization: number;
    passengerCapacity: number;
    biCycleUtilization: number;
    biCycleCapacity: number;
    twoWheelerUtilization: number;
    threeWheelerUtilization: number;
    fourWheelerUtilization: number;
    hmvUtilization: number;
    hmvCapacity: number;
    goodsUtilization: number;
    threeWheelerCapacity: number;
}