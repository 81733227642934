import { FileDownloadOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Body from '../../../components/layouts/Body';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import { IBookingTicketDetails } from '../booking-history/ticket-details/booking-ticket-details-interfaces';
import { useTicketDownloadService } from './useTicketDownloadService';
import useTicketGenerationService from '../booking-history/ticket-details/useTicketGenerationService';
import { useTranslation } from 'react-i18next';

const getBookingIdFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("t");
};

const TicketDownload: React.FC = () => {

    const [bookingId, setBookingId] = useState<string | null>(null);

    const { t } = useTranslation();

    useEffect(() => {
        setBookingId(getBookingIdFromUrl());
    }, []);

    const { getCaptcha, getTicket } = useTicketDownloadService();
    const { generateTicketPdf } = useTicketGenerationService(t);

    const [captchaImage, setCaptchaImage] = useState<string | null>(null);
    const [captchaInput, setCaptchaInput] = useState<string>('');

    useEffect(() => {
        bookingId && getCaptcha(bookingId, setCaptchaImage);
    }, [bookingId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setCaptchaInput(e.target.value);

    const handleDownloadClick = async () => {
        if (!bookingId) {
            toast.error("Booking id not found.");
            return;
        }
        const bookingTicketDetails = await getTicket(bookingId, captchaInput);

        bookingTicketDetails && generateTicketPdf(bookingTicketDetails);
    }

    return (
        <Body>
            <div className="w-full max-w-md mx-auto mt-12">
                <Card>
                    <h3 className='text-center'>{t('download_your_ticket')}</h3>
                    <p className="text-gray-500 text-sm text-center mb-4">{t('download_ticket_instruction_1')}</p>

                    <div className="flex justify-center items-center p-4 bg-gray-200 rounded-lg">
                        {captchaImage ?
                            <img className="shadow-md" src={`data:image/png;base64,${captchaImage}`} alt="Captcha" />
                            :
                            <p className="text-gray-500">{t('captcha_loading')}</p>
                        }
                    </div>

                    <div className="mt-4">
                        <FormControl
                            id="captcha"
                            type="text"
                            value={captchaInput}
                            onChange={handleInputChange}
                            mandatoryField={true}
                            label={t('enter_captcha')}
                        />
                    </div>

                    <PrimaryButton
                        onClick={handleDownloadClick}
                        buttonText={t('proceed_to_download')}
                        buttonIcon={FileDownloadOutlined}
                        fullWidth={true}
                    />
                </Card>
            </div>
        </Body>
    )
}

export default TicketDownload;