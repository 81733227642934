import { IVesselWiseRevenue } from "../mis/mis-interfaces";

export interface IDivHeadDashboardPost {
    fromDate: string;
    toDate: string;
}

export interface IGenderWisePassenger {
    owner: string;
    vesselType: string;
    serviceType: string;
    maleCount: number;
    femaleCount: number;
    otherCount: number;
}

export const initialGenderWisePassenger: IGenderWisePassenger = {
    owner: '',
    vesselType: '',
    serviceType: '',
    maleCount: 0,
    femaleCount: 0,
    otherCount: 0
}

export interface IAgeWisePassenger {
    owner: string;
    vesselType: string;
    serviceType: string;
    infantCount: number;
    seniorCount: number;
    adultCount: number;
    childCount: number;
}

export const initialAgeWisePassenger: IAgeWisePassenger = {
    owner: '',
    vesselType: '',
    serviceType: '',
    infantCount: 0,
    seniorCount: 0,
    adultCount: 0,
    childCount: 0
}

export interface ICategoryWiseVehicle {
    owner: string;
    vesselType: string;
    serviceType: string;
    bicycle: number;
    twoWheeler: number;
    threeWheeler: number;
    fourWheeler: number;
    hmv: number;
}

export const initialCategoryWiseVehicle: ICategoryWiseVehicle = {
    owner: '',
    vesselType: '',
    serviceType: '',
    bicycle: 0,
    twoWheeler: 0,
    threeWheeler: 0,
    fourWheeler: 0,
    hmv: 0
}

export interface IPaymentModeWiseRevenue {
    owner: string;
    vesselType: string;
    serviceType: string;
    cash: number;
    online: number;
    pos: number;
}

export const initialPaymentModeWiseRevenue: IPaymentModeWiseRevenue = {
    owner: '',
    vesselType: '',
    serviceType: '',
    cash: 0,
    online: 0,
    pos: 0
}

export interface IMisDashboardData {
    noOfVessels: number;
    noOfTripSettings: number;
    noOfTrips: number;
    genderWisePassenger: IGenderWisePassenger[];
    ageWisePassenger: IAgeWisePassenger[];
    categoryWiseVehicle: ICategoryWiseVehicle[];
    paymentModeWiseRevenue: IPaymentModeWiseRevenue[];
    vesselWiseRevenue: IVesselWiseRevenue[];
}