import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GhatIcLayout from '../../../components/layouts/GhatIcLayout';
import { useGhatIcTripManagementService } from './useGhatIdTripManagementService';
import { AccessTime, CancelRounded, CheckCircleRounded, ConfirmationNumberOutlined, DirectionsBoatFilledOutlined, Route, SettingsRounded, Tune } from '@mui/icons-material';
import Card from '../../../components/cards/Card';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import DangerButton from '../../../components/buttons/DangerButton';
import SuccessButton from '../../../components/buttons/SuccessButton';

const GhatIcTripDetails: React.FC = () => {

    const location = useLocation();

    const [tripId, setTripId] = React.useState<string>(location.state.tripId);

    const { fetchTripDetails } = useGhatIcTripManagementService();

    useEffect(() => {
        if (tripId !== '') {
            // TODO: Fetch Trip by ID
        }
    }, [tripId]);

    // Sample data (Replace with actual props or API response)
    const tripData = {
        route: {
            origin: "Source Ghat Name",
            destination: "Destination Ghat Name",
        },
        timing: {
            departureTime: "12:00 PM",
            tripDuration: "2 hours",
        },
        vessel: {
            id: 1,
            name: "Vessel Name",
            type: "Vessel Type",
            registrationNumber: "Vessel Registration Number",
            owner: "Vessel Owner",
            dockNo: "Dock No",
        },
        boarding: {
            passengers: { totalCapacity: 100, ticketsIssued: 90, boarded: 80 },
            twoWheelers: { totalCapacity: 50, ticketsIssued: 40, boarded: 35 },
            threeWheelers: { totalCapacity: 30, ticketsIssued: 25, boarded: 20 },
            fourWheelers: { totalCapacity: 20, ticketsIssued: 15, boarded: 10 },
        },
    };

    return (
        <GhatIcLayout>

            <h3 className="text-2xl font-semibold mb-4">Trip Details</h3>

            {/* Route Info */}
            <Card addClass='mb-4 py-0 px-6'>
                <div>
                    <h4 className="font-semibold"><Route className='mb-1' /> Route</h4>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='font-medium'>Origin: {tripData.route.origin}</div>
                        <div className='font-medium'>Destination: {tripData.route.destination}</div>
                    </div>
                </div>
            </Card>

            <Card addClass='mb-4 py-4 px-6'>
                <div>
                    {/* Timing Info */}
                    <h4 className="font-semibold"><AccessTime className='mb-1' /> Timing</h4>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='font-medium'>Departure Time: {tripData.timing.departureTime}</div>
                        <div className='font-medium'>Trip Duration: {tripData.timing.tripDuration}</div>
                    </div>
                    <hr className='my-4' />
                    {/* Vessel Details */}
                    <h4 className="font-semibold"><DirectionsBoatFilledOutlined className='mb-1' /> Vessel Details</h4>
                    <div className='grid grid-cols-2 gap-x-4 gap-y-2 font-medium'>
                        <div>Name: {tripData.vessel.name}</div>
                        <div>Type: {tripData.vessel.type}</div>
                        <div>Registration Number: {tripData.vessel.registrationNumber}</div>
                        <div>Owner: {tripData.vessel.owner}</div>
                        <div>Dock No: {tripData.vessel.dockNo}</div>
                    </div>
                    <hr className='my-4' />
                    {/* Actions */}
                    <h4 className="font-semibold"><Tune className='mb-1' /> Actions</h4>
                    <div className='grid grid-cols-3 gap-x-4'>
                        <div>
                            <SecondaryButton
                                onClick={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                buttonText={'Change Trip Details'}
                                buttonIcon={SettingsRounded}
                            />
                        </div>
                        <div>
                            <SecondaryButton
                                onClick={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                buttonText={'Change Vessel'}
                                buttonIcon={SettingsRounded}
                            />
                        </div>
                        <div>
                            <DangerButton
                                onClick={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                buttonText={'Cancel Trip'}
                                buttonIcon={CancelRounded}
                            />
                        </div>

                    </div>
                </div>
            </Card>

            {/* Boarding Details Table */}
            <Card addClass='mb-4 py-4 px-6'>
            <h4 className="font-semibold"><ConfirmationNumberOutlined className='mb-1' /> Boarding Details</h4>
                <table className="w-full border-collapse border border-gray-300 text-sm">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2 font-medium text-left">Category</th>
                            <th className="border p-2 font-medium">Total Capacity</th>
                            <th className="border p-2 font-medium">Tickets Issued</th>
                            <th className="border p-2 font-medium">Boarded</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(tripData.boarding).map(([key, value]) => (
                            <tr key={key}>
                                <td className="border p-2 capitalize font-medium">{key}</td>
                                <td className="border p-2 text-center">{value.totalCapacity}</td>
                                <td className="border p-2 text-center">{value.ticketsIssued}</td>
                                <td className="border p-2 text-center">{value.boarded}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <SuccessButton
                        onClick={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                        buttonText={'Complete Trip'}
                        buttonIcon={CheckCircleRounded}
                    />
                    <div className='mt-2'>
                        <p className='text-xs font-medium italic'>{`** This action will mark the trip as "Departed," completing the trip.`}</p>
                    </div>
                </div>
            </Card>
        </GhatIcLayout>
    );
}

export default GhatIcTripDetails