import React from 'react'
import { IBookingTicketDetails } from '../booking-ticket-details-interfaces';
import { ArrowForwardRounded, ConfirmationNumberOutlined, ModeOfTravelOutlined } from '@mui/icons-material';
import { BookingStatus } from '../../ticket-list/booking-history-interfaces';
import { useTranslation } from 'react-i18next';

interface TicketHeaderProps {
    bookingTicketDetails: IBookingTicketDetails
    downloadButton: React.ReactElement
    cancelButton: React.ReactElement
}

const TicketHeader: React.FC<TicketHeaderProps> = ({ bookingTicketDetails, downloadButton, cancelButton }) => {

    const { t } = useTranslation();

    return (
        <section className="flex flex-wrap justify-between items-center border-b pb-4 mb-6">
            <div className='flex flex-wrap gap-2 items-start lg:justify-between'>
                <div>
                    <div className='mb-2 text-2xl lg:text-3xl'>
                        <ConfirmationNumberOutlined className='mr-3 pb-1.5' fontSize='large' />
                        {bookingTicketDetails.trip.route.ghatFrom.ghatName}
                        <ArrowForwardRounded className='mx-3' />
                        {bookingTicketDetails.trip.route.ghatTo.ghatName}</div>
                    <h6>{t('pnr_no')}:&emsp;{bookingTicketDetails.pnrNo}&emsp;|&emsp;{t('booking_no')}:&emsp;{bookingTicketDetails.bookingNo}</h6>
                </div>

                <div
                    className={`px-3 py-1 rounded-full text-sm justify-end font-semibold mx-4 mt-2.5
                                        ${bookingTicketDetails.bookingStatus === BookingStatus.CONFIRM ?
                            'bg-green-100 text-green-600' : 'bg-yellow-100 text-yellow-600'}`}>
                    {bookingTicketDetails.bookingStatus}
                </div>
            </div>
            {
                bookingTicketDetails.bookingStatus === BookingStatus.CONFIRM &&
                <div className='ml-auto mt-4 md:mt-0 gap-x-4 flex'>
                    {cancelButton}
                    {downloadButton}
                </div>
            }
        </section>
    )
}

export default TicketHeader;