import React from 'react'
import { useTranslation } from 'react-i18next';

const AdminHeader: React.FC = () => {
    const { t } = useTranslation();
    return (
        <header className="bg-white shadow-sm border-b border-slate-200 text-center">
            <nav className="flex w-full items-center justify-center py-5" aria-label="Global">
                <span className="font-semibold">{t('org_name')}</span>
            </nav>
        </header>
    )
}

export default AdminHeader;