import { BorderColorRounded, CancelRounded } from '@mui/icons-material'
import React from 'react'

interface IFields {
    name: string,
    value: string | number
}

export interface ITicketTableRows {
    handleEditClick: () => void
    handleRemoveClick: () => void
    fields: IFields[]
}

interface TicketDetailsTableProps {
    cols: string[]
    rows: ITicketTableRows[]
}

const TicketDetailsTable: React.FC<TicketDetailsTableProps> = ({ cols, rows }) => {
    return (
        <div className='overflow-x-scroll md:overflow-x-auto'>
            <table className='w-full'>
                <thead>
                    <tr className='bg-sky-50'>
                        <th></th>
                        {cols.map((col: string, index: number) => (
                            <th key={index} className='text-start px-2 py-2 font-medium'>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((row: any, index: number) => (
                        <tr key={index}>
                            <td className='px-2 py-2 w-10'>
                                <div className='flex h-full gap-4 items-center'>
                                    <button className='text-sky-500' onClick={row.handleEditClick}>
                                        <BorderColorRounded />
                                    </button>
                                    <button className='text-red-400' onClick={row.handleRemoveClick}>
                                        <CancelRounded />
                                    </button>
                                </div>
                            </td>
                            {row.fields.map((field: any) => (
                                <td className='px-2 py-2' key={field.name}>
                                    {field.name === 'passengerName' && field.value.includes('Infants') ? (
                                        <>
                                            {field.value.split(', Infants:')[0]}
                                            <br />
                                            <small>
                                                Infants: {field.value.split(', Infants:')[1]}
                                            </small>
                                        </>
                                    ) : (
                                        field.value
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TicketDetailsTable;