import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Card from '../../../../components/cards/Card';
import swalConfig from '../../../../config/swalConfig';
import { Goods, ModalDetails, Passenger, Vehicle } from '../ticket-booking-interfaces';
import TicketDetailsTable, { ITicketTableRows } from './TicketDetailsTable';
import { useCommonService } from '../../../../hooks/useCommonService';
import { SelectOption } from '../../../../constants/type-constants';

interface TicketDetailsProps {
    passengerList: Passenger[]
    vehicleList: Vehicle[]
    goodsList: Goods[]
    setPassengerList: React.Dispatch<React.SetStateAction<Passenger[]>>
    setVehicleList: React.Dispatch<React.SetStateAction<Vehicle[]>>
    setGoodsList: React.Dispatch<React.SetStateAction<Goods[]>>
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
}

const TicketDetails: React.FC<TicketDetailsProps> = ({ ...props }) => {

    const { fetchInfantAgeGroupOptions } = useCommonService();
    const [infantAgeGroupOptions, setInfantAgeGroupOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
        fetchInfantAgeGroupOptions(setInfantAgeGroupOptions);
    }, []);

    const ItemCategory: Record<string, string> = {
        seat: 'seat',
        vehicle: 'vehicle',
        goods: 'goods'
    };

    const handleEditClick = (itemCategory: string, id: string) => {
        props.setModalDetails({
            action: 'edit',
            itemType: itemCategory,
            id: id
        })
    }

    const handleRemoveClick = (itemCategory: string, id: string) => {
        switch (itemCategory) {
            case ItemCategory.seat: {
                const vehicle = props.vehicleList.find(vehicle => vehicle.passengerUniqueId == id);

                if (vehicle) {
                    Swal.fire(swalConfig({
                        title: 'The following vehicle will be deleted as it is mapped to the passenger.',
                        text: `Reg No: ${vehicle.regNo}, Model: ${vehicle.model}`,
                        icon: 'warning',
                        confirmButtonText: 'Continue',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                    })).then(async (result) => {
                        if (result.isConfirmed) {
                            const newPassengerList = props.passengerList.filter(passenger => passenger.id != id);
                            props.setPassengerList(newPassengerList);
                        }
                    });
                } else {
                    const newPassengerList = props.passengerList.filter(passenger => passenger.id != id);
                    props.setPassengerList(newPassengerList);
                }
                break;
            }
            case ItemCategory.vehicle: {
                const newVehicleList = props.vehicleList.filter(vehicle => vehicle.id !== id);
                props.setVehicleList(newVehicleList);
                break;
            }
            case ItemCategory.goods: {
                const newGoodsList = props.goodsList.filter(goods => goods.id !== id);
                props.setGoodsList(newGoodsList);
                break;
            }
            default:
                break;
        }
    }

    const mapToTableRows = (
        items: any[],
        itemCategory: string,
        fields: { name: string, value: string | number }[]
    ): ITicketTableRows[] => {
        return items.map(item => ({
            handleEditClick: () => handleEditClick(itemCategory, item.id),
            handleRemoveClick: () => handleRemoveClick(itemCategory, item.id),
            fields: fields.flatMap(({ name, value }) => {
                if (name === 'passengerName' && item.infants?.length > 0) {
                    return [
                        {
                            name, value: (item as any)[value] + `, Infants:
                            ${item.infants.map((infant: any) => (` ${infant.infantName} (${infantAgeGroupOptions.find((option: SelectOption) => option.value == infant.infantAgeCode)?.label})`))}`
                        },
                    ];
                }

                if(itemCategory === 'goods' && item.unit === 'Weight') {
                    if (name === 'weight') {
                        return [{ name, value: item.weight + ' kgs' }];
                    }
                    if (name === 'volume') {
                        return [{ name, value: '--' }];
                    }
                }
                if(itemCategory === 'goods' && item.unit === 'Volume' ) {
                    if (name === 'volume') {
                        return [{ name, value: item.height + 'x' + item.width + 'x' + item.length +' m³' }];
                    }
                    if (name === 'weight') {
                        return [{ name, value: '--' }];
                    }
                }
                
                return [{ name, value: (item as any)[value] }];
            })
        }));
    };

    const passengerTableRows = mapToTableRows(
        props.passengerList,
        ItemCategory.seat,
        [
            { name: 'passengerName', value: 'passengerName' },
            { name: 'passengerAge', value: 'age' },
            { name: 'passengerGender', value: 'gender' }
        ]
    );

    const vehicleTableRows = mapToTableRows(
        props.vehicleList,
        ItemCategory.vehicle,
        [
            { name: 'vehicleType', value: 'vehicleType' },
            { name: 'regNo', value: 'regNo' },
            { name: 'model', value: 'model' },
            { name: 'ownerName', value: 'ownerName' }
        ]
    );

    const goodsTableRows = mapToTableRows(
        props.goodsList,
        ItemCategory.goods,
        [
            { name: 'description', value: 'description' },
            { name: 'weight', value: 'weight' },
            { name: 'volume', value: 'volume' }
        ]
    );

    return (

        <div className="w-full lg:w-8/12 lg:pr-2 h-full mt-4">
            <h4 className='mb-2'>Ticket Details</h4>
            <Card addClass={"h-full"}>
                {
                    props.passengerList.length > 0 &&
                    <div>
                        <div className='mb-1 font-semibold'>Passenger Details:</div>
                        <TicketDetailsTable
                            cols={['Name', 'Age', 'Gender']}
                            rows={passengerTableRows}
                        />
                    </div>
                }
                {
                    props.vehicleList.length > 0 &&
                    <div>
                        <div className='mb-1 font-semibold'>Vehicle Details:</div>
                        <TicketDetailsTable
                            cols={['Type', 'Reg No', 'Model', 'Owner']}
                            rows={vehicleTableRows}
                        />
                    </div>
                }

                {
                    props.goodsList.length > 0 &&
                    <div>
                        <div className='mb-1 font-semibold'>Goods Details:</div>
                        <TicketDetailsTable
                            cols={['Description', 'Weight', 'Volume']}
                            rows={goodsTableRows}
                        />
                    </div>
                }

            </Card>
        </div>
    )
}

export default TicketDetails;