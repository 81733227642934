import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import MisFilterInputGroup from '../../../components/forms/MisFilterInputGroup';
import AdminLayout, { DivHeadPageType } from '../../../components/layouts/AdminLayout';
import { ICapacityUtilisationReport, ownerFilterOptions, serviceTypeFilterOptions, vesselTypeFilterOptions } from './mis-interfaces';
import { useCapacityUtilisationService } from './useCapacityUtilisationService';
import useMisService from './useMisService';
import AppliedFiltersDisplay from '../dashboard/sections/AppliedFiltersDisplay';

const CapacityUtilisation: React.FC = () => {

    const { fetchCapacityUtilisationData } = useMisService();
    const { getFilteredCapacityUtilisationData } = useCapacityUtilisationService();

    const [capacityUtilisationReport, setCapacityUtilisationReport] = useState<ICapacityUtilisationReport[]>([]);
    const [filteredCapacityUtilisationReport, setFilteredCapacityUtilisationReport] = useState<ICapacityUtilisationReport[]>([]);


    const [serviceType, setServiceType] = useState<string>(serviceTypeFilterOptions[0].value);
    const [owner, setOwner] = useState<string>(ownerFilterOptions[0].value);
    const [vesselType, setVesselType] = useState<string>(vesselTypeFilterOptions[0].value);

    const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(1, "month")); // Default: 1 month prior
    const [toDate, setToDate] = useState<Dayjs>(dayjs()); // Default: Today

    useEffect(() => {
        handleFetchCall();
    }, []);

    const handleFetchCall = async () => {
        await fetchCapacityUtilisationData({
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD')
        }, setCapacityUtilisationReport);
    }

    useEffect(() => {
        getFilteredCapacityUtilisationData(serviceType, owner, vesselType, capacityUtilisationReport, setFilteredCapacityUtilisationReport);
    }, [serviceType, owner, vesselType, capacityUtilisationReport])

    const handleSearchClick = async () => await handleFetchCall();

    return (
        <AdminLayout activeLink={DivHeadPageType.CAPACITY_UTILISATION}>
            {/* Header Section */}
            <div className="bg-white p-4 rounded-md shadow-md flex flex-wrap justify-between items-center">
                <h3>
                    <i className="fas fa-chart-bar mr-2"></i> Vessel Capacity Utilisation
                </h3>

                {/* Filter Section */}
                <MisFilterInputGroup
                    serviceType={serviceType}
                    setServiceType={setServiceType}
                    owner={owner}
                    setOwner={setOwner}
                    vesselType={vesselType}
                    setVesselType={setVesselType}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSearchClick={handleSearchClick}
                />
            </div>

            <div className='mt-4'>
                <AppliedFiltersDisplay
                    fromDate={fromDate.format('DD-MM-YYYY')}
                    toDate={toDate.format('DD-MM-YYYY')}
                    serviceType={serviceType}
                    owner={owner}
                    vesselType={vesselType}
                    setServiceType={setServiceType}
                    setOwner={setOwner}
                    setVesselType={setVesselType}
                />
            </div>

            {/* Table Section */}
            <div className="bg-white p-4 rounded-md shadow-md mt-4">
                <table className="w-full border-collapse border border-gray-300 text-sm">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border p-2">#</th>
                            <th className="border p-2">Ferry Name</th>
                            <th className="border p-2">Source</th>
                            <th className="border p-2">Destination</th>
                            <th className="border p-2">Date</th>
                            <th className="border p-2">Time</th>
                            <th className="border p-2">Passenger</th>
                            <th className="border p-2">Bicycle</th>
                            <th className="border p-2">Motorcycle</th>
                            <th className="border p-2">Three Wheeler</th>
                            <th className="border p-2">LMV</th>
                            <th className="border p-2">HMV</th>
                            <th className="border p-2">Goods</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredCapacityUtilisationReport.length > 0 ?
                                filteredCapacityUtilisationReport.map((row, index) => (
                                    <tr key={index} className="text-center">
                                        <td className="border p-2">{index + 1}</td>
                                        <td className="border p-2">{row.vesselName}</td>
                                        <td className="border p-2">{row.source}</td>
                                        <td className="border p-2">{row.destination}</td>
                                        <td className="border p-2">{row.tripDate}</td>
                                        <td className="border p-2">{row.departureTime}</td>
                                        <td className="border p-2">{row.passengerUtilization}/{row.passengerCapacity}</td>
                                        <td className="border p-2">{row.biCycleUtilization}/{row.biCycleCapacity}</td>
                                        <td className="border p-2">{row.twoWheelerUtilization}/{row.twoWheelerCapacity}</td>
                                        <td className="border p-2">{row.threeWheelerUtilization}/{row.threeWheelerCapacity}</td>
                                        <td className="border p-2">{row.fourWheelerUtilization}/{row.fourWheelerCapacity}</td>
                                        <td className="border p-2">{row.hmvUtilization}/{row.hmvCapacity}</td>
                                        <td className="border p-2">{row.goodsUtilization}/{row.goodsCapacity}</td>
                                    </tr>
                                )) :
                                <tr><td className="border p-2 text-center" colSpan={13}>No data found</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </AdminLayout>
    );
}

export default CapacityUtilisation;