import { Button, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react'
import FilterSelect from './FilterSelect';
import { ownerFilterOptions, serviceTypeFilterOptions, vesselTypeFilterOptions } from '../../pages/division-head/mis/mis-interfaces';

interface MisFilterInputGroupProps {
    fromDate: Dayjs;
    toDate: Dayjs;
    serviceType: string;
    owner: string;
    vesselType: string;
    setServiceType: React.Dispatch<React.SetStateAction<string>>;
    setOwner: React.Dispatch<React.SetStateAction<string>>;
    setVesselType: React.Dispatch<React.SetStateAction<string>>;
    setFromDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    setToDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    handleSearchClick: () => Promise<void>;
}

const SetDateButton: React.FC<{ onClick: () => void, label: string }> = ({ onClick, label }) => {
    return (
        <button
            className='text-sm outline outline-1 outline-slate-600 px-3 rounded-full py-0.5 hover:bg-sky-200'
            onClick={onClick}>
            {label}
        </button>
    )
}

const MisFilterInputGroup: React.FC<MisFilterInputGroupProps> = ({ ...props }) => {
    const handleFromDateChange = (newFromDate: Dayjs | null) => {
        if (newFromDate) {
            props.setFromDate(newFromDate);
            // Ensure toDate is not more than 2 months from fromDate
            if (props.toDate && newFromDate.add(2, "month").isBefore(props.toDate)) {
                props.setToDate(newFromDate.add(2, "month"));
            }
        }
    };

    const handleToDateChange = (newToDate: Dayjs | null) => {
        if (newToDate && props.fromDate && newToDate.diff(props.fromDate, "month") <= 2) {
            props.setToDate(newToDate);
        }
    };

    const handleSelectChange = (updateState: React.Dispatch<React.SetStateAction<string>>) => 
        (e: SelectChangeEvent) => updateState(e.target.value as string);

    const setToday = () => {
        const today = dayjs();
        props.setFromDate(today);
        props.setToDate(today);
        props.handleSearchClick();
    };

    const setThisWeek = () => {
        const today = dayjs();
        const startOfWeek = today.startOf('week').add(1, 'day'); // Monday is the start
        props.setFromDate(startOfWeek);
        props.setToDate(today);
        props.handleSearchClick();
    };

    const setThisMonth = () => {
        const today = dayjs();
        const startOfMonth = today.startOf('month');
        props.setFromDate(startOfMonth);
        props.setToDate(today);
        props.handleSearchClick();
    };


    return (
        // bg-white p-4 rounded-md shadow flex flex-wrap justify-between items-center
        <div className='grid grid-cols-2 gap-x-4'>
            <div className='bg-white p-4 rounded-md shadow'>
                <div className='flex justify-between items-center mb-4'>
                    <h6>Search</h6>
                    <div className='flex gap-x-4 items-center'>
                        <SetDateButton onClick={setToday} label='Today' />
                        <SetDateButton onClick={setThisWeek} label='This Week' />
                        <SetDateButton onClick={setThisMonth} label='This Month' />
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-x-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            value={props.fromDate}
                            onChange={handleFromDateChange}
                            maxDate={dayjs()} // Prevent future dates
                            slotProps={{ textField: { size: 'small' } }}
                            format='DD/MM/YYYY'
                        />
                        <DatePicker
                            label="To"
                            value={props.toDate}
                            onChange={handleToDateChange}
                            minDate={props.fromDate} // Prevent selecting before fromDate
                            maxDate={props.fromDate ? props.fromDate.add(2, "month") : dayjs()} // Max 2 months range
                            slotProps={{ textField: { size: 'small' } }}
                            format='DD/MM/YYYY'
                        />

                        <Button variant="contained" onClick={props.handleSearchClick}>Search</Button>
                    </LocalizationProvider>
                </div>
            </div>
            <div className="bg-white p-4 rounded-md shadow">
                <h6 className='mb-4'>Filters</h6>
                <div className='grid grid-cols-3 gap-x-2'>
                    <FilterSelect
                        options={serviceTypeFilterOptions}
                        label="Timing"
                        value={props.serviceType}
                        id="timingFilter"
                        onChange={handleSelectChange(props.setServiceType)}
                    />
                    <FilterSelect
                        options={ownerFilterOptions}
                        label="Vessel Owner"
                        value={props.owner}
                        id="ownerFilter"
                        onChange={handleSelectChange(props.setOwner)}
                    />
                    <FilterSelect
                        options={vesselTypeFilterOptions}
                        label="Vessel Type"
                        value={props.vesselType}
                        id="vesselTypeFilter"
                        onChange={handleSelectChange(props.setVesselType)}
                    />
                </div>
            </div>
        </div>

    )
}

export default MisFilterInputGroup;