import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import MisFilterInputGroup from '../../../components/forms/MisFilterInputGroup';
import AdminLayout, { DivHeadPageType } from '../../../components/layouts/AdminLayout';
import {
    IRevenueData,
    IRouteWiseRevenue,
    ITotalRevenue,
    IVesselWiseRevenue,
    ownerFilterOptions,
    serviceTypeFilterOptions,
    vesselTypeFilterOptions
} from './mis-interfaces';
import useMisService from './useMisService';
import { useRevenueService } from './useRevenueService';
import AppliedFiltersDisplay from '../dashboard/sections/AppliedFiltersDisplay';

const inititalRevenueReport = {
    routeWiseRevenue: [],
    vesselWiseRevenue: [],
    totalRevenue: []
};

const RevenueDistribution: React.FC = () => {
    const [activeTab, setActiveTab] = useState("route");
    const [serviceType, setServiceType] = useState<string>(serviceTypeFilterOptions[0].value);
    const [owner, setOwner] = useState<string>(ownerFilterOptions[0].value);
    const [vesselType, setVesselType] = useState<string>(vesselTypeFilterOptions[0].value);

    const [revenueReport, setRevenueReport] = useState<IRevenueData>(inititalRevenueReport);

    const [filteredRouteWiseRevenue, setFilteredRouteWiseRevenue] = useState<IRouteWiseRevenue[]>([]);
    const [filteredVesselWiseRevenue, setFilteredVesselWiseRevenue] = useState<IVesselWiseRevenue[]>([]);
    const [filteredTotalRevenue, setFilteredTotalRevenue] = useState<ITotalRevenue>({
        owner: "",
        vesselType: "",
        serviceType: "",
        totalRevenue: 0,
        pos: 0,
        cash: 0,
        noOfTrips: 0,
        online: 0
    });

    const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(1, "month")); // Default: 1 month prior
    const [toDate, setToDate] = useState<Dayjs>(dayjs()); // Default: Today

    const { fetchRevenueReportData } = useMisService();
    const { getFilteredRouteWiseTotalRevenueCounts,
        getFilteredVesselWiseTotalRevenueCounts,
        getFilteredTotalRevenueCounts
    } = useRevenueService();

    useEffect(() => {
        handleFetchCall();
    }, [])

    const handleSearchClick = async () => await handleFetchCall();

    const handleFetchCall = async () => {
        await fetchRevenueReportData({
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD')
        }, setRevenueReport);
    }

    useEffect(() => {
        getFilteredRouteWiseTotalRevenueCounts(serviceType, owner, vesselType, revenueReport.routeWiseRevenue, setFilteredRouteWiseRevenue);
        getFilteredVesselWiseTotalRevenueCounts(serviceType, owner, vesselType, revenueReport.vesselWiseRevenue, setFilteredVesselWiseRevenue);
        getFilteredTotalRevenueCounts(serviceType, owner, vesselType, revenueReport.totalRevenue, setFilteredTotalRevenue);
    }, [serviceType, owner, vesselType, revenueReport])

    return (
        <AdminLayout activeLink={DivHeadPageType.REVENUE_DISTRIBUTION}  >
            <h3>
                <i className="fas fa-chart-bar mr-2"></i> Revenue Distribution
            </h3>

            {/* Filter Section */}
            <MisFilterInputGroup
                serviceType={serviceType}
                setServiceType={setServiceType}
                owner={owner}
                setOwner={setOwner}
                vesselType={vesselType}
                setVesselType={setVesselType}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                handleSearchClick={handleSearchClick}
            />

            <div className='mt-4'>
                <AppliedFiltersDisplay
                    fromDate={fromDate.format('DD-MM-YYYY')}
                    toDate={toDate.format('DD-MM-YYYY')}
                    serviceType={serviceType}
                    owner={owner}
                    vesselType={vesselType}
                    setServiceType={setServiceType}
                    setOwner={setOwner}
                    setVesselType={setVesselType}
                />
            </div>

            {/* Revenue Summary */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                {[
                    { label: "Total Revenue", amount: `₹ ${filteredTotalRevenue.totalRevenue ? filteredTotalRevenue.totalRevenue.toLocaleString() : 0}` },
                    { label: "Online Revenue", amount: `₹ ${filteredTotalRevenue.online ? filteredTotalRevenue.online.toLocaleString() : 0}` },
                    { label: "POS Cash", amount: `₹ ${filteredTotalRevenue.cash ? filteredTotalRevenue.cash.toLocaleString() : 0}` },
                    { label: "POS Cashless", amount: `₹ ${filteredTotalRevenue.pos ? filteredTotalRevenue.pos.toLocaleString() : 0}` },
                ]
                    .map((item, index) => (
                        <div key={index} className="bg-white p-4 rounded-md shadow text-center">
                            <strong className="text-gray-600">{item.label}</strong>
                            <p className="text-xl font-semibold text-gray-800">{item.amount}</p>
                        </div>
                    ))}
            </div>

            {/* Tabs */}
            <div className="bg-white p-4 rounded-md shadow mt-4">
                <div className="flex border-b">
                    {[
                        { id: "route", label: "Route Wise Revenue" },
                        { id: "vessel", label: "Vessel Wise Revenue" },
                    ].map((tab) => (
                        <button
                            key={tab.id}
                            onClick={() => setActiveTab(tab.id)}
                            className={`px-4 py-2 ${activeTab === tab.id ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"}`}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
                <div className="p-4">
                    {activeTab === "route" ? (
                        <table className="w-full border-collapse border border-gray-300 text-sm">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border p-2">#</th>
                                    <th className="border p-2">Source</th>
                                    <th className="border p-2">Destination</th>
                                    <th className="border p-2">Total Trips</th>
                                    <th className="border p-2">Online(₹)</th>
                                    <th className="border p-2">POS Cash(₹)</th>
                                    <th className="border p-2">POS Cashless(₹)</th>
                                    <th className="border p-2">Total Revenue(₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRouteWiseRevenue.length > 0 ?
                                    filteredRouteWiseRevenue.map((row, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border p-2">{index + 1}</td>
                                            <td className="border p-2">{row.sourceGhat}</td>
                                            <td className="border p-2">{row.destinationGhat}</td>
                                            <td className="border p-2">{row.noOfTrips}</td>
                                            <td className="border p-2">{row.online}</td>
                                            <td className="border p-2">{row.cash}</td>
                                            <td className="border p-2">{row.pos}</td>
                                            <td className="border p-2">{row.totalRevenue}</td>
                                        </tr>
                                    )) :
                                    <tr><td className="border p-2 text-center" colSpan={8}>No data found</td></tr>
                                }
                            </tbody>
                        </table>
                    ) : (
                        <table className="w-full border-collapse border border-gray-300 text-sm">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border p-2">#</th>
                                    <th className="border p-2">Vessel Name</th>
                                    <th className="border p-2">Total Trips</th>
                                    <th className="border p-2">Cash(₹)</th>
                                    <th className="border p-2">Online(₹)</th>
                                    <th className="border p-2">POS(₹)</th>
                                    <th className="border p-2">Total Revenue(₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredVesselWiseRevenue.length > 0 ?
                                    filteredVesselWiseRevenue.map((row, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border p-2">{index + 1}</td>
                                            <td className="border p-2">{row.vesselName}</td>
                                            <td className="border p-2">{row.noOfTrips}</td>
                                            <td className="border p-2">{row.online}</td>
                                            <td className="border p-2">{row.cash}</td>
                                            <td className="border p-2">{row.pos}</td>
                                            <td className="border p-2">{row.totalRevenue}</td>
                                        </tr>
                                    )) :
                                    <tr><td className="border p-2 text-center" colSpan={7}>No data found</td></tr>
                                }
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </AdminLayout>
    );
}

export default RevenueDistribution;