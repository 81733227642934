import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Body from '../../../components/layouts/Body';
import Card from '../../../components/cards/Card';
import { dateUtil } from '../../../utility/dateUtil';
import { InitialTripState, Trip } from '../../manage/trip-management/trip-interfaces';
import { Goods, InitialModalDetails, ModalDetails, Passenger, Vehicle } from './ticket-booking-interfaces';
import { useTicketBookingService } from './useTicketBookingService';


import { deckItemUtil, IDeckItem, InitialDeckItemState } from '../../../utility/deckItemUtil';
import AddTicketDetails from './sections/AddTicketDetails';
import BookingTripDetails from './sections/BookingTripDetails';
import PassengerModal from './popovers/PassengerModal';
import TicketDetails from './sections/TicketDetails';
import VehicleModal from './popovers/VehicleModal';
import { SelectOption } from '../../../constants/type-constants';
import BookingSummary from './sections/BookingSummary';
import GoodsModal from './popovers/GoodsModal';
import { useTranslation } from 'react-i18next';

const TicketBooking: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { tripId, bookingId } = location.state;

    const [vehicleOptions, setVehicleOptions] = useState<SelectOption[]>([]);
    const [trip, setTrip] = useState<Trip>(InitialTripState);
    const [itemCapacities, setItemCapacities] = useState<IDeckItem>(InitialDeckItemState);

    const [passengerList, setPassengerList] = useState<Passenger[]>(() => {
        const storedPassengers = localStorage.getItem('passengerList');
        console.log(storedPassengers);
        
        return storedPassengers ? JSON.parse(storedPassengers) : [];
    });
    const [vehicleList, setVehicleList] = useState<Vehicle[]>(() => {
        const storedVehicles = localStorage.getItem('vehicleList');
        return storedVehicles ? JSON.parse(storedVehicles) : [];
    });
    const [goodsList, setGoodsList] = useState<Goods[]>(() => {
        const storedGoods = localStorage.getItem('goodsList');
        return storedGoods ? JSON.parse(storedGoods) : [];
    });
    const [modalDetails, setModalDetails] = useState<ModalDetails>(InitialModalDetails);

    const { fetchTrips } = useTicketBookingService();

    useEffect(() => {
        fetchTrips(tripId, setTrip)
    }, []);

    useEffect(() => {
        localStorage.setItem('passengerList', JSON.stringify(passengerList));
        localStorage.setItem('vehicleList', JSON.stringify(vehicleList));
        localStorage.setItem('goodsList', JSON.stringify(goodsList));
    }, [passengerList, vehicleList, goodsList,]);

    useEffect(() => {
        if (trip.id !== '') {
            const vehicleOptions: SelectOption[] = [];
            trip.tripDeckItems.forEach(deckItem => {
                if (
                    deckItem.item.itemCategory === 'Vehicle' &&
                    !vehicleOptions.some(option => option.value === deckItem.item.itemType)
                ) {
                    vehicleOptions.push({
                        value: deckItem.item.itemType,
                        label: deckItem.item.itemType,
                    });
                }
            });
            setVehicleOptions(vehicleOptions);
            setItemCapacities(deckItemUtil.processDeckItemsForTrip(trip));
        }
    }, [trip])

    useEffect(() => {
        const updatedVehicleList = vehicleList.map(vehicle => {
            const passenger = passengerList.find(passenger => passenger.uniqueId === vehicle.passengerUniqueId);
            if (passenger) {
                return {
                    ...vehicle,
                    passengerUniqueId: passenger.uniqueId,
                    ownerName: passenger.passengerName
                };
            }
            return vehicle;
        });
        setVehicleList(updatedVehicleList);
    }, [passengerList]);

    return (
        <Body>
            <>
                <h1>{t('ticket_booking')}</h1>
                <Card>
                    <div className='flex flex-wrap items-center -mt-2'>
                        <div className='w-full lg:w-3/6 lg:pr-2'>
                            <div className='w-full'>
                                <h3 className='m-0 text-sky-500'>
                                    {trip.vessel?.name}
                                </h3>
                            </div>

                            <AddTicketDetails
                                items={itemCapacities}
                                setModalDetails={setModalDetails}
                            />
                        </div>

                        {/* ---------- TRIP DETAILS ---------- */}

                        <div className='w-full lg:w-3/6 lg:px-2 lg:ps-10 h-full'>
                            <BookingTripDetails trip={trip} />
                        </div>
                    </div>
                </Card>

                {
                    (passengerList.length > 0 || vehicleList.length > 0 || goodsList.length > 0) &&
                    <div className='w-full flex flex-wrap'>
                        <TicketDetails
                            passengerList={passengerList}
                            vehicleList={vehicleList}
                            goodsList={goodsList}
                            setPassengerList={setPassengerList}
                            setVehicleList={setVehicleList}
                            setGoodsList={setGoodsList}
                            setModalDetails={setModalDetails}
                        />

                        <BookingSummary
                            trip={trip}
                            bookingId={bookingId}
                            passengerList={passengerList}
                            vehicleList={vehicleList}
                            goodsList={goodsList}
                        />
                    </div>
                }

                {/* --------- MODALS ------------ */}
                <PassengerModal
                    passengerList={passengerList}
                    setPassengerList={setPassengerList}
                    modalDetails={modalDetails}
                    setModalDetails={setModalDetails}
                    availability={itemCapacities.seatCapacity}
                />
                <VehicleModal
                    vehicleList={vehicleList}
                    setVehicleList={setVehicleList}
                    modalDetails={modalDetails}
                    setModalDetails={setModalDetails}
                    passengerList={passengerList}
                    vehicleOptions={vehicleOptions}
                    tripDeckItems={trip.tripDeckItems}
                />
                <GoodsModal
                    goodsList={goodsList}
                    setGoodsList={setGoodsList}
                    modalDetails={modalDetails}
                    setModalDetails={setModalDetails}
                    availability={itemCapacities.goodsCapacity}
                />
            </>
        </Body>
    )
}

export default TicketBooking;