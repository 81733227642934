import { AxiosResponse } from "axios";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { useApiService } from "../../../hooks/useApiService";
import API_ENDPOINT from "../../../constants/api-constants";
import { IUser, IUserFetch } from "./user-interfaces";
import toast from "react-hot-toast";

export const useUserManagementService = () => {

    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchUsers = async (
        setUsersList: React.Dispatch<React.SetStateAction<IUserFetch[]>>,
        division?: string
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.MANAGE.USER_MANAGEMENT.VIEW_USERS,
                { divisionId: division || null }
            );
            setUsersList(response.data.object);
        } catch (error) {
            handleError(error);
        }
    }

    const saveUser = async (
        userForm: IUser
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MANAGE.USER_MANAGEMENT.SAVE_USER,
                userForm
            )
            const { message } = response.data;
            toast.success(message);
        } catch (error) {
            handleError(error);
        }
    }

    return {
        fetchUsers,
        saveUser
    }
}
