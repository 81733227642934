import React from 'react'
import { useApiService } from '../../../hooks/useApiService'
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { ROUTES } from '../../../constants/routes-constants';
import { AxiosResponse } from 'axios';
import API_ENDPOINT from '../../../constants/api-constants';
import { IBookingTicketDetails } from '../booking-history/ticket-details/booking-ticket-details-interfaces';

export const useTicketDownloadService = () => {

    const { fetchPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const getCaptcha = async (
        bookingId: string,
        setCaptchaImage: React.Dispatch<React.SetStateAction<string | null>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.DOWNLOAD.GET_CAPTCHA,
                {
                    encText: bookingId,
                    captchaText: ''
                }
            );
            const data = response.data.object;
            setCaptchaImage(data.captcha);
        } catch (error) {
            handleError(error);
        }

    }

    const getTicket = async (
        bookingId: string,
        captchaInput: string
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.DOWNLOAD.GET_TICKET,
                {
                    encText: bookingId,
                    captchaText: captchaInput
                }
            );
            return response.data.object as IBookingTicketDetails;
        } catch (error) {
            handleError(error);
        }
    }

    return {
        getCaptcha,
        getTicket
    }
}
