import { Add } from '@mui/icons-material';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import React, { useEffect, useState } from 'react';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';
import Body from '../../../../components/layouts/Body';
import { SelectOption } from '../../../../constants/type-constants';
import { useCommonService } from '../../../../hooks/useCommonService';
import { dateUtil } from '../../../../utility/dateUtil';
import { Route } from '../../../manage/route-management/route-interfaces';
import { Trip, TripManagementTableRow } from '../../../manage/trip-management/trip-interfaces';
import TripManagementModal from '../../../manage/trip-management/TripManagementModal';
import TripManagementTable from '../../../manage/trip-management/TripManagementTable';
import { useTripManagementService } from '../../../manage/trip-management/useTripManagementService';
import FormControl from '../../../../components/forms/FormControl';
import Card from '../../../../components/cards/Card';
import AdminLayout, { DivHeadPageType } from '../../../../components/layouts/AdminLayout';

const DivHeadTripManagement: React.FC = () => {

    const {
        fetchDivisionOptions,
        fetchRoutesByDivision
    } = useCommonService();

    const {
        handleInputChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTrips
    } = useTripManagementService();

    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatFromOptions, setGhatFromOptions] = useState<SelectOption[]>([]);
    const [ghatToOptions, setGhatToOptions] = useState<SelectOption[]>([]);

    const [division, setDivision] = useState('');
    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatTo, setGhatTo] = useState('');
    const [tripDate, setTripDate] = useState(dateUtil.formatDate((new Date()).toString()));

    const [routes, setRoutes] = useState<Route[]>([]);
    const [tripList, setTripList] = useState<Trip[]>([]);
    const [trip, setTrip] = useState<Trip>();

    const [tripModalOpen, setTripModalOpen] = useState(false);
    const handleTripModalOpen = () => setTripModalOpen(true);
    const handleTripModalClose = () => setTripModalOpen(false);

    const [tripTableRows, setTripTableRows] = useState<TripManagementTableRow[]>([]);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchRoutesByDivision(division, setRoutes);
        }
    }, [division]);

    useEffect(() => {
        if (routes.length == 0) {
            setGhatFromOptions([]);
            setGhatToOptions([]);
        } else {
            generateGhatFromOptions(routes, setGhatFromOptions, setGhatFrom);
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions(routes, ghatFrom, setGhatToOptions, setGhatTo);
        }
    }, [ghatFrom]);

    const handleCreateTripClick = async () => {
        setTrip(undefined);
        handleTripModalOpen();
    }

    const handleFetchTripsClick = async () => fetchTrips(
        routes,
        ghatFrom,
        ghatTo,
        tripDate,
        setTripTableRows,
        setTripList
    );

    const handleTripEditClick = (id: string) => {
        generateGhatFromOptions(routes, setGhatFromOptions, setGhatFrom);
        const tripData = tripList.find((trip: Trip) => trip.id == id);
        if (tripData) {
            setTrip(tripData);
            handleTripModalOpen();
        }
    }

    return (
        <AdminLayout activeLink={DivHeadPageType.TRIP}>
            <div className='flex flex-wrap justify-between items-end mb-2'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><ModeOfTravelIcon /> Trip Management</h3>
                <div>
                    <SecondaryButton
                        onClick={handleCreateTripClick}
                        buttonText={'Create Trip'}
                        buttonIcon={Add}
                    />
                </div>
            </div>
            <Card >
                <div className='grid grid-cols-5 gap-2 items-end'>
                    <FormControl
                        label='Division'
                        type={'select'}
                        id={'division'}
                        value={division}
                        options={divisionOptions}
                        onChange={handleInputChange(setDivision)}
                    />
                    <FormControl
                        label='From'
                        type={'select'}
                        id={'ghatFrom'}
                        value={ghatFrom}
                        options={ghatFromOptions}
                        onChange={handleInputChange(setGhatFrom)}
                    />
                    <FormControl
                        label='To'
                        type={'select'}
                        id={'ghatTo'}
                        value={ghatTo}
                        options={ghatToOptions}
                        onChange={handleInputChange(setGhatTo)}
                    />
                    <FormControl
                        label='Date'
                        type={'date'}
                        id={'tripDate'}
                        value={tripDate}
                        onChange={handleInputChange(setTripDate)}
                    />
                    <div>
                        <DefaultButton
                            onClick={handleFetchTripsClick}
                            buttonText={'Fetch Trips'}
                        />
                    </div>
                </div>
            </Card>

            <TripManagementTable
                tripTableRows={tripTableRows}
                handleTripEditClick={handleTripEditClick}
            />

            <TripManagementModal
                trip={trip}
                tripDate={tripDate}
                routes={routes}
                division={division}
                divisionOptions={divisionOptions}
                tripModalOpen={tripModalOpen}
                handleTripModalClose={handleTripModalClose}
                ghatFrom={ghatFrom}
                ghatTo={ghatTo}
                setTripTableRows={setTripTableRows}
                setTripList={setTripList}
            />
        </AdminLayout>
    )
}

export default DivHeadTripManagement