import axios from "axios";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import API_ENDPOINT from "../constants/api-constants";
import { store } from "../store/reducers/store";
import { device } from "../services/deviceDetailsService";

interface User {
    id: string;
    username: string;
    fullName: string;
    mobileNo: string;
    email: string;
    enabled: boolean;
}

interface Role {
    id: number;
    role: string;
    jurisdiction: string;
    jurisdictionId: string;
    jurisdictionName: string;
}

interface Auth {
    // Define the structure of your auth state here
    accessToken: string;
    tokenType: string;
    user: User | null;
    roles: Role | null;
}

interface AuthContextType {
    auth: Auth;
    setAuth: React.Dispatch<React.SetStateAction<Auth>>;
}

const initialAuthState: Auth = {
    accessToken: '',
    tokenType: '',
    user: null,
    roles: null
};

const AuthContext = createContext<AuthContextType>({
    auth: initialAuthState,
    setAuth: () => { },
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { data } = store.getState();
    const [auth, setAuth] = useState<Auth>(initialAuthState);

    useEffect(() => {
        const isAuthenticated = data.isAuthenticated;

        if (isAuthenticated) {
            const refreshAuthToken = async () => {
                try {
                    const response = await axios.post(
                        API_ENDPOINT.BASE_URL + API_ENDPOINT.AUTH.POST_REFRESH_TOKEN,
                        {
                            deviceType: device.getType(),
                            deviceAddress: device.getAddress(),
                        },
                        { withCredentials: true }
                    );

                    const { accessToken, tokenType, user } = response.data;

                    setAuth({
                        accessToken,
                        tokenType,
                        user,
                        roles: user.roles[0],
                    });

                } catch (error) {
                    console.error('Token refresh failed:', error);
                    setAuth(initialAuthState);
                }
            };
            refreshAuthToken();
        }
    }, []);


    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;