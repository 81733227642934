export interface Passenger {
    id: string
    passengerName: string
    mobileNo: string
    email: string
    pwd: string
    gender: string
    age: number
    uniqueId: string
    infants: IInfant[]
}

export interface IInfant {
    infantName: string
    infantGender: string
    infantAgeCode: string
}

export interface IInfantView {
    infantName: string
    infantGender: string
    infantAge: {
        code: string
        description: string
    }
}

export const InitialPassengerState: Passenger = {
    id: '',
    passengerName: '',
    mobileNo: '',
    email: '',
    pwd: '',
    gender: '',
    age: 0,
    uniqueId: '',
    infants: []
}

export interface Vehicle {
    id: string
    model: string
    regNo: string
    item: {
        id: string,
        itemType: string
    }
    vehicleType: string
    passengerUniqueId: string
    ownerName: string
}

export const InitialVehicleState: Vehicle = {
    id: '',
    model: '',
    regNo: '',
    item: {
        id: '',
        itemType: ''
    },
    vehicleType: '',
    passengerUniqueId: '',
    ownerName: '',
}

export interface Goods {
    id: string
    description: string
    unit: string
    weight: number
    height: number
    width: number
    length: number
}

export const InitialGoodsState: Goods = {
    id: '',
    description: '',
    unit: '',
    weight: 0,
    height: 0,
    width: 0,
    length: 0
}

export interface ModalDetails {
    action: string
    itemType: string
    id?: string
}

export const InitialModalDetails = {
    action: '',
    itemType: ''
}

export interface BookingDetailsPost {
    booking: {
        id: string
    },
    trip: {
        id: number
    },
    mobileNo: string,
    email: string,
    passengers: Passenger[],
    vehicles: Vehicle[],
    goods: Goods[]
}

export interface Ticket {
    itemType: string;
    itemDetails: string;
    itemQuantity: number;
    itemPrice: string;
    itemTotalPrice: string;
}

export interface PriceDetails {
    netAmount: string;
    convenienceFee: string;
    totalPrice: string;
}

export interface IBookingSummary {
    tickets: Ticket[];
    price: PriceDetails;
}

export const InitialBookingSummary: IBookingSummary = {
    tickets: [],
    price: {
        convenienceFee: '',
        netAmount: '',
        totalPrice: ''
    }
}