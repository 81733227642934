import React, { useEffect, useState } from 'react';
import { IUser } from './user-interfaces';
import FormControl from '../../../components/forms/FormControl';
import { useCommonService } from '../../../hooks/useCommonService';
import { SelectOption } from '../../../constants/type-constants';
import { validationService } from '../../../services/validationService';

interface UserFormProps {
    userForm: IUser,
    setUserForm: React.Dispatch<React.SetStateAction<IUser>>
    roleOptions: SelectOption[]
    divisionOptions: SelectOption[]
    ghatOptions: SelectOption[]
    division: string
    ghat: string
    setDivision: React.Dispatch<React.SetStateAction<string>>
    setGhat: React.Dispatch<React.SetStateAction<string>>
}

enum Jurisdiction {
    STATE = "State",
    DIVISION = "Division",
    GHAT = "Ghat"
}

const jurisdictionOptions: SelectOption[] = [
    { value: Jurisdiction.STATE, label: Jurisdiction.STATE },
    { value: Jurisdiction.DIVISION, label: Jurisdiction.DIVISION },
    { value: Jurisdiction.GHAT, label: Jurisdiction.GHAT },
]

const UserForm: React.FC<UserFormProps> = ({ ...props }) => {

    useEffect(() => {
        if (props.userForm.role === '' && props.roleOptions.length > 0) {
            props.setUserForm({
                ...props.userForm,
                role: props.roleOptions[0].value
            });
        }
    }, [props.userForm, props.roleOptions]);

    useEffect(() => {
        if (props.userForm.jurisdiction === '' && jurisdictionOptions.length > 0) {
            props.setUserForm({
                ...props.userForm,
                jurisdiction: jurisdictionOptions[0].value
            });
        }
    }, [props.userForm, jurisdictionOptions]);

    useEffect(() => {
        let jurisdictionId;
        if (props.userForm.jurisdiction === Jurisdiction.DIVISION && props.division !== '') {
            jurisdictionId = Number(props.division);
        } else if (props.userForm.jurisdiction === Jurisdiction.GHAT && props.ghat !== '') {
            jurisdictionId = Number(props.ghat);
        } else {
            jurisdictionId = 0;
        }
        props.setUserForm({
            ...props.userForm,
            jurisdictionId: jurisdictionId
        });

    }, [props.userForm.jurisdiction, props.division, props.ghat]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'mobileNo' && !validationService.isValidMobileInput(value)) return;
        if (name === 'mpin' && !validationService.isValidMpinInput(value)) return;

        props.setUserForm({
            ...props.userForm,
            [name]: value
        });
    }

    const handleSelectInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (e: React.ChangeEvent<HTMLSelectElement>) => updateState(e.target.value);

    return (
        <div className='flex flex-wrap mb-4 gap-y-4'>
            <div className='w-full lg:w-1/2 lg:pr-4'>
                <FormControl
                    type={'text'}
                    id={'fullName'}
                    value={props.userForm.fullName}
                    label='Full Name'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2'>
                <FormControl
                    type={'text'}
                    id={'username'}
                    value={props.userForm.username}
                    label='Username'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2 lg:pr-4'>
                <FormControl
                    type={'text'}
                    id={'mobileNo'}
                    value={props.userForm.mobileNo}
                    label='Mobile No'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2'>
                <FormControl
                    type={'email'}
                    id={'email'}
                    value={props.userForm.email}
                    label='Email'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2 lg:pr-4'>
                <FormControl
                    type={'password'}
                    id={'password'}
                    value={props.userForm.password}
                    label='Password'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2'>
                <FormControl
                    type={'password'}
                    id={'confirmPassword'}
                    value={props.userForm.confirmPassword}
                    label='Confirm Password'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2 lg:pr-4'>
                <FormControl
                    type={'password'}
                    id={'mpin'}
                    value={props.userForm.mpin}
                    label='MPIN'
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2'>
                <FormControl
                    type={'select'}
                    id={'role'}
                    value={props.userForm.role}
                    label='Role'
                    options={props.roleOptions}
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full lg:w-1/2 lg:pr-4'>
                <FormControl
                    type={'select'}
                    id={'jurisdiction'}
                    value={props.userForm.jurisdiction}
                    label='Jurisdiction'
                    options={jurisdictionOptions}
                    onChange={handleInputChange}
                />
            </div>
            {
                (props.userForm.jurisdiction === Jurisdiction.DIVISION || props.userForm.jurisdiction === Jurisdiction.GHAT) &&
                <div className='w-full lg:w-1/2'>
                    <FormControl
                        type={'select'}
                        id={'divisionId'}
                        value={props.division}
                        label='Division'
                        options={props.divisionOptions}
                        onChange={handleSelectInputChange(props.setDivision)}
                    />
                </div>
            }
            {
                props.userForm.jurisdiction === Jurisdiction.GHAT &&
                <div className='w-full lg:w-1/2 lg:pr-4'>
                    <FormControl
                        type={'select'}
                        id={'ghatId'}
                        value={props.ghat}
                        label='Ghat'
                        options={props.ghatOptions}
                        onChange={handleSelectInputChange(props.setGhat)}
                    />
                </div>
            }

        </div>
    )
}

export default UserForm;