import { AddRounded, ManageAccounts } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import AdminLayout, { DivHeadPageType } from '../../../../components/layouts/AdminLayout';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { useFormErrorContext } from '../../../../hooks/useFormErrorContext';
import { useValidationService } from '../../../../hooks/useValidationService';
import { IUser, IUserFetch, UserFormInitialState } from '../../../manage/user-management/user-interfaces';
import UserForm from '../../../manage/user-management/UserForm';
import UserTable from '../../../manage/user-management/UserTable';
import { useUserManagementService } from '../../../manage/user-management/useUserManagementSevice';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';
import { useCommonService } from '../../../../hooks/useCommonService';
import { SelectOption } from '../../../../constants/type-constants';
import { ROLES } from '../../../../constants/role-constants';

const DivHeadUserManagement: React.FC = () => {

    const { fetchUsers, saveUser } = useUserManagementService();
    const { setFormErrors } = useFormErrorContext();
    const { validateFields } = useValidationService();
    const { fetchDivisionOptions, fetchGhatOptionsByDivision, fetchRoleOptions } = useCommonService();

    const [userList, setUserList] = React.useState<IUserFetch[]>([]);
    const [userForm, setUserForm] = React.useState<IUser>(UserFormInitialState);

    const [userDetailsModalOpen, setUserDetailsModalOpen] = React.useState(false);
    const handleUserDetailsModalOpen = () => setUserDetailsModalOpen(true);
    const handleUserDetailsModalClose = () => setUserDetailsModalOpen(false);

    const [division, setDivision] = useState('');
    const [ghat, setGhat] = useState('');

    const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatOptions, setGhatOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        fetchRoleOptions(setRoleOptions);
        fetchDivisionOptions(setDivisionOptions, setDivision);
    }, []);

    useEffect(() => {
        if (roleOptions.length) {
            setRoleOptions(
                roleOptions
                    .filter(role => role.value !== ROLES.SYSADMIN)
                    .map(role => 
                        role.value === ROLES.DIVISION_HEAD 
                            ? { ...role, label: "ADMIN_GG" } 
                            : role
                    )
            );
        }
    }, [roleOptions]);

    useEffect(() => {
        division !== '' &&
            fetchGhatOptionsByDivision(division, setGhatOptions, setGhat);
    }, [division]);

    useEffect(() => {
        fetchUsers(setUserList);
    }, []);

    const handleEditClick = async (id: number) => {
        const user = userList.find(user => user.id === id);
        user && setUserForm({
            id: user.id,
            fullName: user.fullName,
            username: user.username,
            enabled: user.enabled,
            mobileNo: user.mobileNo,
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
            mpin: user.mpin,
            role: user.role.role,
            jurisdiction: user.jurisdiction,
            jurisdictionId: user.jurisdictionId
        });
        handleUserDetailsModalOpen();
    }

    const handleAddUserClick = () => {
        setUserForm(UserFormInitialState);
        handleUserDetailsModalOpen();
    }

    const handleSaveUserClick = () => {
        const errors = validateFields(null,
            { fullName: userForm.fullName },
            { username: userForm.username },
            { mobileNo: userForm.mobileNo },
            { email: userForm.email },
            { password: userForm.password },
            { confirmPassword: userForm.confirmPassword },
            { mpin: userForm.mpin },
            { role: userForm.role },
            { jurisdiction: userForm.jurisdiction }
        );
        if (errors) {
            setFormErrors(errors);
            return;
        }
        saveUser(userForm)
    };

    return (
        <AdminLayout activeLink={DivHeadPageType.USERS}>
            <div className='flex flex-wrap justify-between items-end mb-2'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><ManageAccounts /> User Management</h3>
                <div>
                    <SecondaryButton
                        onClick={handleAddUserClick}
                        buttonText={'Create User'}
                        buttonIcon={AddRounded}
                    />
                </div>
            </div>

            <UserTable
                rows={userList}
                handleEditClick={handleEditClick}
            />

            <ModalWrapper
                open={userDetailsModalOpen}
                handleClose={handleUserDetailsModalClose}
                title={'User Details'}
            >
                <UserForm
                    userForm={userForm}
                    setUserForm={setUserForm}
                    division={division}
                    ghat={ghat}
                    setDivision={setDivision}
                    setGhat={setGhat}
                    roleOptions={roleOptions}
                    divisionOptions={divisionOptions}
                    ghatOptions={ghatOptions}
                />
                <PrimaryButton
                    fullWidth={true}
                    buttonText={'Save User'}
                    onClick={handleSaveUserClick}
                />
            </ModalWrapper>
        </AdminLayout>
    )
}

export default DivHeadUserManagement