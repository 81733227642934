import React from 'react';
import { Chart } from 'react-google-charts';
import Card from '../../../../components/cards/Card';
import { Download, PieChart } from '@mui/icons-material';
import { IAgeWisePassenger, IGenderWisePassenger } from '../div-head-dashboard-interfaces';
import { useDownloadService } from './services/useDownloadService';

interface IPassengerDemographicsCardProps {
    genderWisePassenger: IGenderWisePassenger;
    ageWisePassenger: IAgeWisePassenger;
    fromDate: string;
    toDate: string;
    serviceType: string;
    owner: string;
    vesselType: string;
}

const PassengerDemographicsCard: React.FC<IPassengerDemographicsCardProps> = ({
    genderWisePassenger,
    ageWisePassenger,
    fromDate,
    toDate,
    serviceType,
    owner,
    vesselType }) => {

    const { downloadPassengerDemographics } = useDownloadService();

    const genderWisePassengerChart = React.useRef<HTMLDivElement>(null);
    const ageWisePassengerChart = React.useRef<HTMLDivElement>(null);

    const genderData = [
        ['Gender', 'Passenger Count'],
        ['Male', genderWisePassenger.maleCount],
        ['Female', genderWisePassenger.femaleCount],
        ['Others', genderWisePassenger.otherCount]
    ];

    const ageData = [
        ['Age Category', 'Passenger Count'],
        ['Infant (0-5)', ageWisePassenger.infantCount],
        ['Child (6-17)', ageWisePassenger.childCount],
        ['Adult (18-59)', ageWisePassenger.adultCount],
        ['Senior (60+)', ageWisePassenger.seniorCount],
    ];

    const handleDownloadClick = () => {
        genderWisePassengerChart.current && ageWisePassengerChart.current &&
            downloadPassengerDemographics(
                genderWisePassengerChart.current,
                ageWisePassengerChart.current,
                genderData,
                ageData,
                fromDate,
                toDate,
                serviceType,
                owner,
                vesselType
            );
    }

    return (
        <Card addClass='mb-4'>
            <div className="flex justify-between items-center border-b pb-2">
                <h6 className="text-lg font-medium flex items-center">
                    <PieChart fontSize='small' className='mr-2' /> Passenger Demographics
                </h6>
                <button
                    className="text-gray-600 hover:text-gray-800"
                    onClick={handleDownloadClick}
                >
                    <Download />
                </button>
            </div>
            <div className="card-body" id='passenger-demographics-chart'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div className="border-r">
                        <div className="text-center">
                            <strong>Gender Wise Passenger Distribution</strong>
                        </div>
                        <div ref={genderWisePassengerChart}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={genderData}
                                options={{
                                    chartArea: { width: '90%', height: '65%', top: 20 },
                                    legend: { position: 'bottom' },
                                    is3D: false,
                                }}
                            />
                        </div>
                    </div>
                    <div className="border-l">
                        <div className="text-center">
                            <strong>Age Wise Passenger Distribution</strong>
                        </div>
                        <div ref={ageWisePassengerChart}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={ageData}
                                options={{
                                    chartArea: { width: '90%', height: '65%', top: 20 },
                                    legend: { position: 'bottom' },
                                    is3D: false,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default PassengerDemographicsCard;