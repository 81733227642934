import React from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from "jspdf-autotable";
import { ICategoryWiseVehicle } from '../../div-head-dashboard-interfaces';

export const useDownloadService = () => {

    const downloadPassengerDemographics = async (
        genderWisePassengerChart: HTMLElement,
        ageWisePassengerChart: HTMLElement,
        genderData: (Array<string | number>)[],
        ageData: (Array<string | number>)[],
        dateFrom: string,
        dateTo: string,
        serviceType: string,
        owner: string,
        vesselType: string
    ) => {
        const pdf = new jsPDF();

        pdf.setFont("helvetica", "bold").setFontSize(16);
        pdf.text("Passenger Demographics Report", 105, 10, { align: "center" });

        // Report Parameters Table
        pdf.setFontSize(12);
        const params = [
            ["From", dateFrom],
            ["To", dateTo],
            ["Service Type", serviceType],
            ["Owner", owner],
            ["Vessel Type", vesselType]
        ];

        autoTable(pdf, {
            startY: 20,
            head: [["Parameter", "Value"]],
            body: params,
            theme: "grid"
        });

        let yOffset = (pdf as any).lastAutoTable.finalY || 30; // Ensure a valid default value

        // Function to add a high-resolution image while maintaining aspect ratio
        const addChartToPDF = async (chartElement: HTMLElement, yPosition: number) => {
            const canvas = await html2canvas(chartElement, { scale: 2 }); // Higher resolution
            const imgData = canvas.toDataURL("image/png");

            const imgWidth = 180; // Fixed width
            const imgHeight = (canvas.height / canvas.width) * imgWidth; // Maintain aspect ratio

            pdf.addImage(imgData, "PNG", 10, yPosition, imgWidth, imgHeight);
            return yPosition + imgHeight; // Return new Y position
        };

        // Add Gender Chart Title
        yOffset += 10;
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold").setFontSize(12).text("Gender Wise Passenger Distribution", 105, yOffset, { align: "center" });

        // Add Gender Chart
        yOffset = await addChartToPDF(genderWisePassengerChart, yOffset + 5);

        // Add Gender Table
        autoTable(pdf, {
            startY: yOffset,
            head: [genderData[0]],
            body: genderData.slice(1),
            theme: "striped"
        });


        pdf.addPage();
        yOffset = 10;

        // Add Age Chart Title
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold").setFontSize(12).text("Age Wise Passenger Distribution", 105, yOffset, { align: "center" });
        yOffset += 5;

        // Add Age Chart
        yOffset = await addChartToPDF(ageWisePassengerChart, yOffset);

        // Add Age Table
        autoTable(pdf, {
            startY: yOffset,
            head: [ageData[0]],
            body: ageData.slice(1),
            theme: "striped"
        });

        pdf.save("passenger_demographics.pdf");
    };

    const downloadCategoryWiseVehicleBreakdown = async (
        vehicleBreakdownChart: HTMLElement,
        vehicleBreakdownData: ICategoryWiseVehicle,
        dateFrom: string,
        dateTo: string,
        serviceType: string,
        owner: string,
        vesselType: string
    ) => {
        const pdf = new jsPDF();

        pdf.setFont("helvetica", "bold").setFontSize(16);
        pdf.text("Category Wise Vehicle Distribution Report", 105, 10, { align: "center" });

        // Report Parameters Table
        pdf.setFontSize(12);
        const params = [
            ["From", dateFrom],
            ["To", dateTo],
            ["Service Type", serviceType],
            ["Owner", owner],
            ["Vessel Type", vesselType]
        ];

        autoTable(pdf, {
            startY: 20,
            head: [["Parameter", "Value"]],
            body: params,
            theme: "grid"
        });

        let yOffset = (pdf as any).lastAutoTable.finalY || 30; // Ensure a valid default value

        // Function to add a high-resolution image while maintaining aspect ratio
        const addChartToPDF = async (chartElement: HTMLElement, yPosition: number) => {
            const canvas = await html2canvas(chartElement, { scale: 2 }); // Higher resolution
            const imgData = canvas.toDataURL("image/png");

            const imgWidth = 200; // Fixed width
            const imgHeight = (canvas.height / canvas.width) * imgWidth; // Maintain aspect ratio

            pdf.addImage(imgData, "PNG", 10, yPosition, imgWidth, imgHeight);
            return yPosition + imgHeight; // Return new Y position
        };

        yOffset += 10;
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold").setFontSize(12).text("Category Wise Vehicle Distribution", 105, yOffset, { align: "center" });

        yOffset = await addChartToPDF(vehicleBreakdownChart, yOffset + 5);

        autoTable(pdf, {
            startY: yOffset,
            head: [['Category', 'No of Vehicles']],
            body: [
                ['Bicycle', vehicleBreakdownData.bicycle],
                ['Two Wheeler', vehicleBreakdownData.twoWheeler],
                ['Three Wheeler', vehicleBreakdownData.threeWheeler],
                ['Four Wheeler', vehicleBreakdownData.fourWheeler],
                ['HMV', vehicleBreakdownData.hmv]],
            theme: "striped"
        });

        pdf.save("category_wise_vehicle_breakdown.pdf");
    }

    const downloadRevenueInformation = async (
        revenueTable: HTMLElement,
        modeWiseRevenueChart: HTMLElement,
        modeWiseRevenueData: (Array<string | number>)[],
        dateFrom: string,
        dateTo: string,
        serviceType: string,
        owner: string,
        vesselType: string
    ) => {
        const pdf = new jsPDF();

        pdf.setFont("helvetica", "bold").setFontSize(16);
        pdf.text("Revenue Report", 105, 10, { align: "center" });

        // Report Parameters Table
        pdf.setFontSize(12);
        const params = [
            ["From", dateFrom],
            ["To", dateTo],
            ["Service Type", serviceType],
            ["Owner", owner],
            ["Vessel Type", vesselType]
        ];

        autoTable(pdf, {
            startY: 20,
            head: [["Parameter", "Value"]],
            body: params,
            theme: "grid"
        });

        let yOffset = (pdf as any).lastAutoTable.finalY || 30; // Ensure a valid default value

        // Function to add a high-resolution image while maintaining aspect ratio
        const addChartToPDF = async (chartElement: HTMLElement, yPosition: number, width?: number, xPosition?: number) => {
            const canvas = await html2canvas(chartElement, { scale: 2 }); // Higher resolution
            const imgData = canvas.toDataURL("image/png");

            const imgWidth = width || 180; // Fixed width
            const imgHeight = (canvas.height / canvas.width) * imgWidth; // Maintain aspect ratio

            pdf.addImage(imgData, "PNG", xPosition || 15, yPosition, imgWidth, imgHeight);
            return yPosition + imgHeight; // Return new Y position
        };

        // Add Top 5 Revenue Generating Vessels Title
        yOffset += 10;
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold").setFontSize(12).text("Top 5 Revenue Generating Vessels", 105, yOffset, { align: "center" });

        // Add Revenue Table
        yOffset = await addChartToPDF(revenueTable, yOffset + 5);

        // Add Top 5 Revenue Generating Vessels Title
        yOffset += 15;
        pdf.setFontSize(14);
        pdf.setFont("helvetica", "bold").setFontSize(12).text("Mode-wise Revenue Distribution", 105, yOffset, { align: "center" });

        yOffset = await addChartToPDF(modeWiseRevenueChart, yOffset + 2, 130, 42);

        // Add Gender Table
        autoTable(pdf, {
            startY: yOffset + 10,
            head: [modeWiseRevenueData[0]],
            body: modeWiseRevenueData.slice(1),
            theme: "striped"
        });

        pdf.save("revenue_report.pdf");
    }

    return {
        downloadPassengerDemographics,
        downloadCategoryWiseVehicleBreakdown,
        downloadRevenueInformation
    };
}
