import { SearchRounded, SwapHorizRounded } from '@mui/icons-material';
import { Container } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultFooter from '../../../components/footers/DefaultFooter';
import DefaultHeader from '../../../components/headers/DefaultHeader';
import { ROLES } from '../../../constants/role-constants';
import { ROUTES } from '../../../constants/routes-constants';
import useAuth from '../../../hooks/useAuth';
import OrgLogo from '../../../resources/images/aiwtd_logo.png';
import { dateUtil } from '../../../utility/dateUtil';
import { Route } from '../../manage/route-management/route-interfaces';
import GhatSelectModal from './GhatSelectModal';
import { ISearchParams } from './landing-interfaces';
import './landing.sass';
import AboutUs from './sections/AboutUs';
import Features from './sections/Features';
import HowItWorks from './sections/HowItWorks';
import { useLandingService } from './useLandingService';

const Landing: React.FC = () => {
    const { t } = useTranslation();
    const { auth } = useAuth();

    const InitialSearchParamState: ISearchParams = useMemo(() => ({
        origin: {
            originId: '',
            originName: t('origin')
        },
        destination: {
            destinationId: '',
            destinationName: t('destination')
        },
        tripDate: dateUtil.formatDate((new Date()).toString())
    }), [t]);

    const { fetchRoutes, routesToGhatList, searchTrips } = useLandingService();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState<ISearchParams>(InitialSearchParamState);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [ghatList, setGhatList] = useState<any[]>([]);

    useEffect(() => {
        fetchRoutes(setRoutes)
    }, []);

    useEffect(() => {
        setSearchParams(InitialSearchParamState)
    }, [InitialSearchParamState]);

    const { handleSwapClick } = useLandingService();

    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selectedParameter, setSelectedParameter] = useState('');

    const [ghatOptionModalOpen, setGhatOptionModalOpen] = useState(false);
    const handleGhatOptionModalOpen = () => setGhatOptionModalOpen(true);
    const handleGhatOptionModalClose = () => setGhatOptionModalOpen(false);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchParams({
            ...searchParams,
            tripDate: e.target.value
        });
    }

    const showSearchModal = (clickedDiv: string) => {
        setSelectedParameter(clickedDiv);

        routesToGhatList(clickedDiv, routes, searchParams, setGhatList);
        handleGhatOptionModalOpen();
        searchInputRef.current?.focus();
    }

    const handleSearchClick = async () => {
        const found = await searchTrips(searchParams, routes);
        if (found) navigate(ROUTES.USER.SEARCH_RESULTS);
    }

    return (
        <div className='bg-slate-50'>
            <div className='min-h-screen'>
                <DefaultHeader />
                <div className='hero-section h-full flex items-center justify-center'>
                    <Container>
                        <div className="p-4 pt-12 pb-20">
                            <div className="flex justify-center">
                                <img className="w-40" src={OrgLogo} alt="" />
                            </div>
                            <div className="text-3xl text-center mb-4 text-sky-700 mt-2">
                                {t('search_vessels')}
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <div className="rounded-xl w-full md:w-3/4 lg:w-2/3 outline outline-2 outline-blue-400 flex flex-wrap bg-slate-50">
                                    <div className="w-full lg:w-7/12 flex flex-wrap">
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 cursor-pointer"
                                                onClick={() => showSearchModal('origin')}>
                                                {searchParams.origin.originName}
                                            </div>
                                        </div>
                                        <div className="relative bg-slate-400 flex items-center">
                                            <button className="absolute -left-12 top-7 lg:-left-5 lg:top-1.5 flex p-2 bg-zinc-500 text-white rounded-full scale-90 rotate-90 lg:rotate-0"
                                                onClick={() => handleSwapClick(searchParams, setSearchParams)}
                                            >{<SwapHorizRounded />}</button>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 cursor-pointer"
                                                onClick={() => showSearchModal('destination')}>
                                                {searchParams.destination.destinationName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-5/12 flex flex-wrap">
                                        <div className="w-full lg:w-7/12 px-6 bg-transparent">
                                            <input
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 bg-transparent"
                                                type="date"
                                                value={searchParams.tripDate}
                                                onChange={handleDateChange}
                                                min={dateUtil.today()}
                                            />
                                        </div>
                                        <div className="w-full lg:w-5/12">
                                            <button
                                                className="w-full justify-center h-full p-3.5 bg-sky-600 text-white font-medium rounded-b-xl lg:rounded-bl-none lg:rounded-r-xl flex gap-2 pl-5 items-center outline outline-1 outline-sky-600"
                                                onClick={handleSearchClick}>
                                                {t('search')} <SearchRounded />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4 mb-4 text-slate-700 font-medium">
                                {t('org_name')}
                            </div>
                        </div>
                    </Container>
                </div>

                <AboutUs />

                <Features />

                <HowItWorks />

                <div className="bg-sky-700 text-white mt-16">
                    <Container>
                        {/* Call to Action */}
                        <section className="py-10  text-center">
                            <h2 className="text-2xl font-bold mb-4">{t('start_journey')}</h2>
                            <p className="mb-4">{t('register_and_book')}</p>
                            {
                                auth.roles && auth.roles.role  === ROLES.USER &&
                                <button
                                    className="bg-white text-sky-700 px-6 py-2 rounded-full font-medium hover:bg-gray-100"
                                    onClick={() => {
                                        document.body.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }}>
                                    {t('search_vessels')}
                                </button>
                            }
                            {
                                !auth.user &&
                                <button
                                    className="bg-white text-sky-700 px-6 py-2 rounded-full font-medium hover:bg-gray-100"
                                    onClick={() => navigate(ROUTES.AUTH.SIGN_UP)}>
                                    {t('sign_up')}
                                </button>
                            }
                        </section>
                    </Container>
                </div>
            </div>
            <DefaultFooter />

            <GhatSelectModal
                ghatOptionModalOpen={ghatOptionModalOpen}
                handleGhatOptionModalClose={handleGhatOptionModalClose}
                searchInputRef={searchInputRef}
                ghatList={ghatList}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                selectedParameter={selectedParameter}
                setSelectedParameter={setSelectedParameter}
            />
        </div>
    )
}

export default Landing;