import { CurrencyRupeeRounded, DeviceHub, DirectionsBoat, Map, ModeOfTravel, PeopleAltRounded, Route, SettingsRounded } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../../components/layouts/Body';
import Card from '../../../components/cards/Card';
import { ROUTES } from '../../../constants/routes-constants';

const masterManagementLinks = [
    { name: 'Users', href: ROUTES.MANAGE.USERS, icon: PeopleAltRounded },
    { name: 'Divisions', href: ROUTES.MANAGE.DIVISION, icon: DeviceHub },
    { name: 'Ghats', href: ROUTES.MANAGE.GHAT, icon: Map },
    { name: 'Routes', href: ROUTES.MANAGE.ROUTE, icon: Route },
    { name: 'Vessels', href: ROUTES.MANAGE.VESSEL, icon: DirectionsBoat },
    { name: 'Rates', href: ROUTES.MANAGE.RATE, icon: CurrencyRupeeRounded },
    { name: 'Trip Settings', href: ROUTES.MANAGE.TRIP_SETTINGS, icon: SettingsRounded },
    { name: 'Trips', href: ROUTES.MANAGE.TRIP, icon: ModeOfTravel },
]

const SysAdminDashboard: React.FC = () => {

    const navigate = useNavigate();

    return (
        <Body>
            <h1 className="text-3xl font-medium mb-2">System Admin - Dashboard</h1>

            {/* Master Management Links */}
            <h5>Master Management</h5>
            <div className='grid grid-cols-8 gap-4 mb-4'>
                {
                    masterManagementLinks.map(link => (
                        <div key={link.name}
                            className='bg-white rounded-xl px-2 py-2 shadow text-center cursor-pointer hover:scale-105 duration-300 hover:bg-blue-50'
                            onClick={() => navigate(link.href)}
                        >
                            <div className='pt-1 pb-2 mb-1'>
                                <link.icon className='text-slate-500' />
                            </div>
                            <div className='font-medium text-slate-600 text-sm'>
                                {link.name}
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='flex-1 flex flex-col'>
                {/* Key Metrics */}
                <div className='flex flex-wrap mb-4'>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={'p-2'}>
                            <div>
                                <h2 className='text-lg'>Total Divisions</h2>
                                <p className='text-2xl font-medium'>10</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={'p-2'}>
                            <div>
                                <h2 className='text-lg'>Total Ghats</h2>
                                <p className='text-2xl font-medium'>35</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={'p-2'}>
                            <div>
                                <h2 className='text-lg'>Total Routes</h2>
                                <p className='text-2xl font-medium'>25</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <Card addClass={'p-2'}>
                            <div>
                                <h2 className='text-lg'>Total Vessels</h2>
                                <p className='text-2xl font-medium'>15</p>
                            </div>
                        </Card>
                    </div>
                </div>

                {/* Recent Activities */}
                <div className='mb-4'>
                    <Card>
                        <h2 className='text-lg font-semibold mb-4'>Recent Activities</h2>
                        <ul className='space-y-2'>
                            <li className='flex justify-between'>
                                <span>Route 101 updated</span>
                                <span className='text-gray-500 text-sm'>10 mins ago</span>
                            </li>
                            <li className='flex justify-between'>
                                <span>Division Guwahati added</span>
                                <span className='text-gray-500 text-sm'>1 hour ago</span>
                            </li>
                            <li className='flex justify-between'>
                                <span>Vessel Assam Express scheduled</span>
                                <span className='text-gray-500 text-sm'>2 hours ago</span>
                            </li>
                        </ul>
                    </Card>
                </div>
                {/* User Statistics */}
                <div className='mb-4'>
                    <Card>
                        <h2 className='text-lg font-semibold mb-4'>User Statistics</h2>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Total Users</h4>
                                <p className='text-2xl font-medium'>3,450</p>
                                <p className='text-sm text-gray-500'>Registered</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Active Users</h4>
                                <p className='text-2xl font-medium'>2,980</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>New Registrations</h4>
                                <p className='text-2xl font-medium'>150</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>User Retention Rate</h4>
                                <p className='text-2xl font-medium'>85%</p>
                                <p className='text-sm text-gray-500'>Last 30 days</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Churn Rate</h4>
                                <p className='text-2xl font-medium'>5%</p>
                                <p className='text-sm text-gray-500'>Last 30 days</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Average Session Duration</h4>
                                <p className='text-2xl font-medium'>12 min</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Body>
    )
}

export default SysAdminDashboard;
