import React from "react";
import { ReactNode } from "react";

interface CardProps {
    addClass?: string;
    children: ReactNode;
}

const Card: React.FC<CardProps> = ({ addClass, children }) => {
    return (
        <div className="w-full bg-white rounded-xl shadow">
            <div className={`px-6 py-4 space-y-4 md:space-y-6 ${addClass}`}>
                {children}
            </div>
        </div>
    );
}

export default Card;