import { CancelOutlined } from '@mui/icons-material';
import React from 'react'

interface AppliedFiltersDisplayProps {
    fromDate: string;
    toDate: string;
    serviceType: string;
    owner: string;
    vesselType: string;
    setServiceType: React.Dispatch<React.SetStateAction<string>>;
    setOwner: React.Dispatch<React.SetStateAction<string>>;
    setVesselType: React.Dispatch<React.SetStateAction<string>>;
}

interface FilterBadgeProps {
    label: string;
    value: string;
    onClick: () => void;
}

const FilterBadge: React.FC<FilterBadgeProps> = ({ ...props }) => {
    return (
        <div>
            <div className='bg-sky-200 shadow px-6 py-2 rounded-full font-semibold text-sky-800 flex items-center gap-x-2'>
                <button onClick={props.onClick}>
                    <CancelOutlined fontSize='small' />
                </button>
                {props.label}: {props.value}
            </div>
        </div>
    )
}

const AppliedFiltersDisplay: React.FC<AppliedFiltersDisplayProps> = ({ ...props }) => {

    const handleClear = (
        updateState: React.Dispatch<React.SetStateAction<string>>
    ) => updateState('All');

    return (
        <div className='mb-4'>
            <div className='flex flex-wrap gap-x-4 justify-between'>
                <div>
                    <div className='bg-red-200 shadow px-6 py-2 rounded-full font-semibold text-red-800'>Date: {props.fromDate} - {props.toDate}</div>
                </div>
                <div className='flex justify-end gap-x-4'>
                    {
                        props.serviceType !== 'All' &&
                        <FilterBadge
                            label='Timing'
                            value={props.serviceType}
                            onClick={() => handleClear(props.setServiceType)} />
                    }
                    {
                        props.owner !== 'All' &&
                        <FilterBadge
                            label='Owner'
                            value={props.owner}
                            onClick={() => handleClear(props.setOwner)} />
                    }
                    {
                        props.vesselType !== 'All' &&
                        <FilterBadge
                            label='Vessel Type'
                            value={props.vesselType}
                            onClick={() => handleClear(props.setVesselType)} />
                    }
                </div>
            </div>
        </div>
    )
}

export default AppliedFiltersDisplay;