import React from 'react';
import { Chart } from 'react-google-charts';
import Card from '../../../../components/cards/Card';
import { BarChart, Download } from '@mui/icons-material';
import { ICategoryWiseVehicle } from '../div-head-dashboard-interfaces';
import { useDownloadService } from './services/useDownloadService';

interface IVehicleBreakdownCardProps {
    categoryWiseVehicle: ICategoryWiseVehicle;
    fromDate: string;
    toDate: string;
    serviceType: string;
    owner: string;
    vesselType: string;
}

const VehicleBreakdownCard: React.FC<IVehicleBreakdownCardProps> = ({
    categoryWiseVehicle,
    fromDate,
    toDate,
    serviceType,
    owner,
    vesselType }) => {

    const categoryWiseVehicleChart = React.useRef<HTMLDivElement>(null);
    const { downloadCategoryWiseVehicleBreakdown } = useDownloadService();

    const vehicleData = [
        ['Category', 'No of Vehicles'],
        ['Bicycle', categoryWiseVehicle.bicycle],
        ['Motorcycle', categoryWiseVehicle.twoWheeler],
        ['3 Wheeler', categoryWiseVehicle.threeWheeler],
        ['4 Wheeler', categoryWiseVehicle.fourWheeler],
        ['HMV', categoryWiseVehicle.hmv],
    ];

    const handleDownloadClick = async () => {
        categoryWiseVehicleChart.current &&
            downloadCategoryWiseVehicleBreakdown(
                categoryWiseVehicleChart.current,
                categoryWiseVehicle,
                fromDate,
                toDate,
                serviceType,
                owner,
                vesselType
            );
    }

    return (
        <Card addClass='mb-4'>
            <div className="flex justify-between items-center border-b pb-2">
                <h6 className="text-lg font-medium flex items-center">
                    <BarChart fontSize='small' className='mr-2' /> Vehicle Breakdown
                </h6>
                <button
                    className="text-gray-600 hover:text-gray-800"
                    onClick={handleDownloadClick}
                >
                    <Download />
                </button>
            </div>
            <div className="card-body">
                <div className="text-center">
                    <strong>Category Wise Vehicle Distribution</strong>
                </div>
                <div ref={categoryWiseVehicleChart}>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={vehicleData}
                        options={{
                            chartArea: { width: '50%', height: '50%', top: 20 },
                            hAxis: {
                                title: 'No of Vehicles',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'Category',
                            },
                            legend: { position: 'bottom' }
                        }}
                    />
                </div>
            </div>
        </Card>
    );
};

export default VehicleBreakdownCard;