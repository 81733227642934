import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../../components/modals/ModalWrapper'
import FormControl from '../../../components/forms/FormControl'
import DefaultButton from '../../../components/buttons/DefaultButton'
import { SelectOption } from '../../../constants/type-constants'
import { Route } from '../route-management/route-interfaces'
import { useRateManagementService } from './useRateManagementSevice'
import { useCommonService } from '../../../hooks/useCommonService'
import { ItemRate, Rate, RatePost } from './rate-management-interface'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

interface RateManagementModalProps {
    division: string
    routes: Route[]
    rateModalOpen: boolean
    handleRateModalClose: () => void
    rateList: Rate[]
    itemList: SelectOption[]
    itemRateList: ItemRate[]
    setItemRateList: React.Dispatch<React.SetStateAction<ItemRate[]>>
}

const RateManagementModal: React.FC<RateManagementModalProps> = ({
    division,
    routes,
    rateModalOpen,
    handleRateModalClose,
    rateList,
    itemList,
    itemRateList,
    setItemRateList
}) => {

    const {
        fetchVesselTypeOptions,
        fetchServiceTypeOptions
    } = useCommonService();

    const {
        generateGhatFromOptions,
        generateGhatToOptions,
        handleInputChange,
        handleRateInputChange,
        filterRates,
        saveRates
    } = useRateManagementService();

    const [vesselTypeOptions, setVesselTypeOptions] = useState<SelectOption[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<SelectOption[]>([]);

    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<SelectOption[]>([]);
    const [formGhatTo, setFormGhatTo] = useState('');
    const [formGhatToOptions, setFormGhatToOptions] = useState<SelectOption[]>([]);
    const [vesselType, setVesselType] = useState('');
    const [serviceType, setServiceType] = useState('');

    useEffect(() => {
        fetchVesselTypeOptions(setVesselTypeOptions, setVesselType);
        fetchServiceTypeOptions(setServiceTypeOptions, setServiceType);
    }, [division]);

    useEffect(() => {
        if (routes.length == 0) {
            setFormGhatFromOptions([]);
            setFormGhatToOptions([]);
        } else {
            generateGhatFromOptions(routes, setFormGhatFromOptions, setFormGhatFrom);
        }
    }, [routes]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateGhatToOptions(routes, formGhatFrom, setFormGhatToOptions, setFormGhatTo);
        }
    }, [formGhatFrom])

    useEffect(() => {
        const route = routes.find(route => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);

        if (route) {
            filterRates(
                route,
                rateList,
                setItemRateList,
                vesselType,
                serviceType,
            )
        }

    }, [formGhatFrom, formGhatTo, vesselType, serviceType, routes, rateList]);


    const generateItemInputs = () => {
        const elements: ReactJSXElement[] = [];
        if (itemList) {
            itemList.map((item, index) => {
                const existingItem = itemRateList.find((rateItem: ItemRate) => item.value == rateItem.item.id);
                elements.push(
                    <div key={item.value} className='w-1/4 pr-4' >
                        <FormControl
                            label={item.label}
                            type={'number'}
                            id={'item_' + item.value}
                            placeholder='Price'
                            value={existingItem ? existingItem.rate : 0}
                            onChange={handleRateInputChange(itemRateList, setItemRateList)}
                            mandatoryField={true}
                        />
                    </div>
                );
            })
        }
        return elements;
    }

    const handleSaveRateClick = async () => {
        const selectedRoute = routes.find(route => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);
        const postData: RatePost = {
            route: {
                id: selectedRoute?.id || null
            },
            vesselType: vesselType,
            serviceType: serviceType,
            itemRates: itemRateList,
        }
        saveRates(postData);
    }


    return (

        <ModalWrapper
            title={'Rate Details'}
            open={rateModalOpen}
            handleClose={handleRateModalClose}
        >
            <div className='flex flex-wrap'>
                <div className='w-full lg:w-1/3 lg:pr-4'>
                    <FormControl
                        label='From'
                        type={'select'}
                        id={'formGhatFrom'}
                        value={formGhatFrom}
                        options={formGhatFromOptions}
                        onChange={handleInputChange(setFormGhatFrom)}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full lg:w-1/3 lg:pr-4'>
                    <FormControl
                        label='To'
                        type={'select'}
                        id={'formGhatTo'}
                        value={formGhatTo}
                        options={formGhatToOptions}
                        onChange={handleInputChange(setFormGhatTo)}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full lg:w-1/3'>
                    <FormControl
                        label='Vessel Type'
                        type={'select'}
                        id={'vesselType'}
                        value={vesselType}
                        options={vesselTypeOptions}
                        onChange={handleInputChange(setVesselType)}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full lg:w-1/3 lg:pr-4'>
                    <FormControl
                        label='Service Type'
                        type={'select'}
                        id={'serviceType'}
                        value={serviceType}
                        options={serviceTypeOptions}
                        onChange={handleInputChange(setServiceType)}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full'>
                    <h6 className='text-blue-400'>Item-wise Rates (&#8377;)</h6>
                </div>
                {generateItemInputs()}
                <div className='w-full mt-4'>
                    <DefaultButton
                        onClick={handleSaveRateClick}
                        buttonText='Save Rate Details'
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default RateManagementModal