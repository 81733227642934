import { AddRounded, DirectionsBoat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCommonService } from '../../../../hooks/useCommonService';
import { useFormErrorContext } from '../../../../hooks/useFormErrorContext';
import { useValidationService } from '../../../../hooks/useValidationService';
import { SelectOption } from '../../../../constants/type-constants';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import FormControl from '../../../../components/forms/FormControl';
import SelectWrapper from '../../../../components/forms/SelectWrapper';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import AdminLayout, { DivHeadPageType } from '../../../../components/layouts/AdminLayout';
import Card from '../../../../components/cards/Card';
import { ROUTES } from '../../../../constants/routes-constants';
import { useVesselManagementService } from '../../../manage/vessel-management/useVesselManagementService';
import {
    SelectedDaysMap,
    TripDay,
    Vessel,
    VesselPost,
    VesselTableRow
} from '../../../manage/vessel-management/vessel-interfaces';
import VesselTable from '../../../manage/vessel-management/VesselTable';

const DivHeadVesselManagement: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { divisionId } = location.state || { divisionId: '' };

    const {
        fetchDivisionOptions,
        fetchGhatOptionsByDivision,
        fetchVesselTypeOptions,
        fetchOwnerOptions,
        fetchNightNavigationOptions,
        fetchStatusOptions,
        fetchWeekDayOptions,
        fetchGhatOptionsByDivisionList,
    } = useCommonService();

    const {
        handleInputChange,
        handleNumberInputChange,
        handleSelectInputChange,
        handleCheckboxChange,
        fetchVessels,
        saveVessel,
        deleteVessel
    } = useVesselManagementService();

    const { formErrors, setFormErrors } = useFormErrorContext();
    const { validateFields, validateList, validateNumber } = useValidationService();


    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [vesselTypeOptions, setVesselTypeOptions] = useState<SelectOption[]>([]);
    const [ownerOptions, setOwnerOptions] = useState<SelectOption[]>([]);
    const [nightNavigationOptions, setNightNavigationOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [ghatOptions, setGhatOptions] = useState<SelectOption[]>([]);
    const [formGhatOptions, setFormGhatOptions] = useState<SelectOption[]>([]);
    const [weekdaysOptions, setWeekdaysOptions] = useState<TripDay[]>([]);

    const [division, setDivision] = useState<string>('');
    const [vessel, setVessel] = useState<string>('');
    const [vesselName, setVesselName] = useState<string>('');
    const [vesselRegNo, setVesselRegNo] = useState<string>('');
    const [vesselType, setVesselType] = useState<string>('');
    const [owner, setOwner] = useState<string>('');
    const [nightNavigation, setNightNavigation] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [vesselDivisions, setVesselDivisions] = useState<SelectOption[]>([]);
    const [baseGhat, setBaseGhat] = useState<string>('');
    const [selectedDays, setSelectedDays] = useState<SelectedDaysMap>({});
    const [luggageCapacity, setLuggageCapacity] = useState<number>(0);

    const [vesselList, setVesselList] = useState<Vessel[]>([]);
    const [vesselTableRows, setVesselTableRows] = useState<VesselTableRow[]>([]);

    const [vesselModalOpen, setVesselModalOpen] = useState<boolean>(false);
    const handleVesselModalOpen = () => setVesselModalOpen(true);
    const handleVesselModalClose = () => setVesselModalOpen(false);

    useEffect(() => {
        if (divisionId != '') {
            setDivision(divisionId);
            fetchVessels(divisionId, setVesselTableRows, setVesselList);
            fetchGhatOptionsByDivision(divisionId, setGhatOptions);
        }
    }, [divisionId]);

    useEffect(() => {
        if (vesselDivisions.length === 0) return;
        const divisionIds = vesselDivisions.map(division => Number(division.value));

        fetchGhatOptionsByDivisionList(divisionIds, setFormGhatOptions, baseGhat === '' ? setBaseGhat : null);

    }, [vesselDivisions]);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchVesselTypeOptions(setVesselTypeOptions, setVesselType);
        fetchOwnerOptions(setOwnerOptions, setOwner);
        fetchNightNavigationOptions(setNightNavigationOptions, setNightNavigation);
        fetchStatusOptions(setStatusOptions, setStatus);
        fetchWeekDayOptions(setWeekdaysOptions, setSelectedDays);
    }, []);

    const handleFetchVesselsClick = async () => {
        fetchVessels(division, setVesselTableRows, setVesselList);
        fetchGhatOptionsByDivision(division, setGhatOptions);
    }

    const handleAddButtonClick = async () => {
        setVessel('');
        setVesselName('');
        setVesselRegNo('');
        setVesselType(vesselTypeOptions[0]?.value);
        setOwner(ownerOptions[0]?.value);
        setNightNavigation(nightNavigationOptions[0]?.value);
        setStatus(statusOptions[0]?.value);
        setVesselDivisions([]);
        setBaseGhat(ghatOptions[0]?.value);
        setLuggageCapacity(0);
        setSelectedDays([]);
        handleVesselModalOpen();
    }

    const handleVesselEditClick = async (id: string) => {
        const vesselObject: Vessel | undefined = vesselList.find(vessel => vessel.id == id);

        if (!vesselObject) return;

        setVessel(vesselObject.id);
        setVesselName(vesselObject.name);
        setVesselRegNo(vesselObject.regNo);
        setVesselType(vesselObject.vesselType);
        setOwner(vesselObject.owner);
        setNightNavigation(vesselObject.hasNightNavigation);
        setStatus(vesselObject.status);
        setVesselDivisions(vesselObject.divisions.map(div => (
            { value: div.id, label: div.divisionName }
        )));
        setBaseGhat(vesselObject.baseGhat.id);

        const updatedSelectedDays = vesselObject.tripDays.reduce((days: SelectedDaysMap, dayName: string) => {
            const weekDayOption = weekdaysOptions.find(option => option.dayName === dayName);
            if (weekDayOption) {
                days[Number(weekDayOption.id)] = true;
            }
            return days;
        }, {} as SelectedDaysMap);

        setSelectedDays(updatedSelectedDays);
        setLuggageCapacity(vesselObject.luggageCapacity)
        handleVesselModalOpen();
    }

    const handleVesselDeleteClick = async (id: string) => {
        const deleted = await deleteVessel(id)
        if (deleted) fetchVessels(division, setVesselTableRows, setVesselList);
    }

    const handleVesselSaveClick = async () => {

        const selectedDayNames = weekdaysOptions
            .filter(option => selectedDays[Number(option.id)])
            .map(option => option.dayName);

        const vd = vesselDivisions.map(division => ({ id: division.value }))

        let errors = validateFields(
            null,
            { vesselName },
            { baseGhat },
            { vesselType },
            { owner },
            { nightNavigation },
            { status });

        errors = validateList(errors, { vesselDivision: vd.map(division => division.id) }, { weekDays: selectedDayNames });
        errors = validateNumber(errors, { luggageCapacity });

        if (errors) {
            setFormErrors(errors);
            return;
        }

        const postData: VesselPost = {
            id: vessel !== "" ? vessel : null,
            baseGhat: {
                id: baseGhat
            },
            divisions: vd,
            tripDays: selectedDayNames,
            name: vesselName,
            regNo: vesselRegNo,
            vesselType: vesselType,
            owner: owner,
            hasNightNavigation: nightNavigation,
            status: status,
            luggageCapacity: luggageCapacity
        }

        const saved = await saveVessel(postData)
        if (saved) {
            fetchVessels(division, setVesselTableRows, setVesselList);
            handleVesselModalClose();
        }
    }

    const handleVesselLayoutClick = async (id: string) => {
        const vesselDetails = vesselList.find(vessel => vessel.id == id);

        navigate(ROUTES.MANAGE.VESSEL_LAYOUT,
            {
                state: {
                    vessel: vesselDetails,
                    divisionId: division
                }
            })
    }

    return (
        <AdminLayout activeLink={DivHeadPageType.VESSEL}>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><DirectionsBoat /> Vessel Management</h3>
            </div>

            <Card>
                <div className='flex w-full justify-between'>
                    <div className='flex gap-2 items-end'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            onChange={handleInputChange(setDivision)}
                            options={divisionOptions}
                        />
                        <div>
                            <DefaultButton
                                onClick={handleFetchVesselsClick}
                                buttonText={'Fetch Vessels'}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap justify-between items-end' >
                        <div className='flex w-max'>
                            <DefaultButton
                                onClick={handleAddButtonClick}
                                buttonText='Add New Vessel'
                                buttonIcon={AddRounded}
                            />
                        </div>
                    </div>
                </div>
            </Card>

            <VesselTable
                vesselTableRows={vesselTableRows}
                handleEditClick={handleVesselEditClick}
                handleDeleteClick={handleVesselDeleteClick}
                handleLayoutClick={handleVesselLayoutClick}
            />

            <ModalWrapper
                open={vesselModalOpen}
                handleClose={handleVesselModalClose}
                title={'Vessel Details'}
            >
                <form className='flex flex-wrap gap-y-2'>
                    <div className='w-full lg:w-2/3 lg:pr-4'>
                        <input type='text' id='id' name='id' value={vessel} readOnly hidden />
                        <FormControl
                            label='Vessel Name'
                            type={'text'}
                            id={'vesselName'}
                            value={vesselName}
                            onChange={handleInputChange(setVesselName)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Registration Number'
                            type={'text'}
                            id={'regNo'}
                            value={vesselRegNo}
                            onChange={handleInputChange(setVesselRegNo)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Vessel Type'
                            type={'select'}
                            id={'vesselType'}
                            value={vesselType}
                            options={vesselTypeOptions}
                            onChange={handleInputChange(setVesselType)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Owner Type'
                            type={'select'}
                            id={'owner'}
                            value={owner}
                            options={ownerOptions}
                            onChange={handleInputChange(setOwner)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Has Night Navigation?'
                            type={'select'}
                            id={'hasNightNavigation'}
                            value={nightNavigation}
                            options={nightNavigationOptions}
                            onChange={handleInputChange(setNightNavigation)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange(setStatus)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <SelectWrapper
                            label='Division'
                            defaultValue={vesselDivisions}
                            id={'vesselDivision'}
                            options={divisionOptions}
                            onChange={handleSelectInputChange(setVesselDivisions)}
                            isMulti={true}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Base Ghat'
                            type={'select'}
                            id={'baseGhat'}
                            value={baseGhat}
                            options={formGhatOptions}
                            onChange={handleInputChange(setBaseGhat)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-2/3'>
                        <label htmlFor="weekdaysSelect" className='text-sm font-medium text-gray-900'>Weekdays <span className='text-red-500'>*</span></label>
                        <div id='weekdaysSelect' className='flex gap-2 mt-2'>
                            {weekdaysOptions.map(day => (
                                <div key={day.id}>
                                    <label className=
                                        {`block w-10 cursor-pointer text-center py-2 text-white duration-300 rounded-full 
                                            ${selectedDays[Number(day.id)] || false ?
                                                `bg-sky-400`
                                                :
                                                `bg-neutral-200`
                                            }`}
                                    >
                                        <input
                                            hidden
                                            type="checkbox"
                                            checked={selectedDays[Number(day.id)] || false}
                                            onChange={() => handleCheckboxChange(day.id, setSelectedDays)}
                                        />
                                        {day.dayName[0]}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {
                            formErrors["weekDays"] && formErrors["weekDays"] != '' &&
                            <div className="text-red-500 text-xs mt-1 font-medium">{formErrors["weekDays"]}</div>
                        }
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Luggage Capacity'
                            type={'number'}
                            id={'luggageCapacity'}
                            value={luggageCapacity}
                            onChange={handleNumberInputChange(setLuggageCapacity)}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleVesselSaveClick}
                        buttonText={'Save Vessel Details'}
                    />
                </div>
            </ModalWrapper>

        </AdminLayout>
    )
}

export default DivHeadVesselManagement