import React from 'react'

export interface IValidationObject {
    [key: string]: string;
}

const camelToNormalText = (str: string) => {
    // Remove "form" at the beginning of the string, if it exists
    if (str.toLowerCase().startsWith("form")) {
        str = str.slice(4);  // Remove the first 4 characters (i.e., "form")
    }

    return str
        // Add a space before each uppercase letter, except the first one
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .toLowerCase()
        // Capitalize the first letter of the result
        .replace(/^./, (match) => match.toUpperCase());
};

export const useValidationService = () => {

    const validateFields = (existingErrors?: IValidationObject | null, ...fields: IValidationObject[]): IValidationObject | null => {
        const errors: IValidationObject = existingErrors || {};

        fields.forEach((field) => {
            // Iterate through the keys and values inside each passed field object
            Object.entries(field).forEach(([label, value]) => {
                if (value === '') {
                    // If the value is empty, generate the error message
                    errors[label] = `${camelToNormalText(label)} is mandatory`;
                }
            });
        });
        // Return errors object if there are errors, otherwise return null
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const validateList = (existingErrors?: IValidationObject | null, ...fields: { [key: string]: any[]; }[]): IValidationObject | null => {
        const errors: IValidationObject = existingErrors || {};
        fields.forEach((field) => {
            // Iterate through the keys and values inside each passed field object
            Object.entries(field).forEach(([label, value]) => {
                if (value.length === 0) {
                    // If the value is empty, generate the error message
                    errors[label] = `${camelToNormalText(label)} is mandatory`;
                }
            });
        });
        // Return errors object if there are errors, otherwise return null
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const validateNumber = (existingErrors?: IValidationObject | null, ...fields: { [key: string]: number }[]): IValidationObject | null => {
        const errors: IValidationObject = existingErrors || {};
        fields.forEach((field) => {
            // Iterate through the keys and values inside each passed field object
            Object.entries(field).forEach(([label, value]) => {
                if (isNaN(Number(value))) {
                    // If the value is empty, generate the error message
                    errors[label] = `${camelToNormalText(label)} must be a number`;
                }
            });
        });
        return Object.keys(errors).length > 0 ? errors : null;
    };

    return {
        validateFields,
        validateList,
        validateNumber
    }
}
