import React, { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ArrowUpTrayIcon, Bars3Icon, Cog8ToothIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import LogoutAction from '../../auth/LogoutAction';
import { ROLES } from '../../constants/role-constants';
import { ROUTES } from '../../constants/routes-constants';
import useAuth from '../../hooks/useAuth';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Language } from '@mui/icons-material';
import i18n from '../../config/localizationConfig';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const DefaultHeader: React.FC = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { auth } = useAuth();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setLanguageDropdownOpen(false);
    };

    return (
        <header className="bg-white shadow-sm">
            <Container>
                <nav className="flex justify-between items-center py-6" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <button onClick={() => navigate(ROUTES.INDEX)} className="-m-1.5 p-1.5 flex items-center gap-4">
                            <span className="sr-only">{t('portal_name_short')}</span>
                            <img className="h-8 w-auto" src={require("../../resources/images/aiwtd_logo.png")} alt="" />
                            <span className="font-semibold">{t('portal_name')}</span>
                        </button>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">{t('open_main_menu')}</span>
                            <Bars3Icon className="h-6 w-6" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12">
                        <button onClick={() => navigate(ROUTES.INDEX)} className="text-sm font-semibold leading-6 text-gray-900">
                            {t('home')}
                        </button>
                        {
                            auth.roles && auth.roles.role === ROLES.SYSADMIN &&

                            <button onClick={() => navigate(ROUTES.SYSTEM_ADMIN.DASHBOARD)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('system_admin')}
                            </button>
                        }
                        {
                            auth.roles && auth.roles.role === ROLES.DIVISION_HEAD &&

                            <button onClick={() => navigate(ROUTES.DIVISION_HEAD.DASHBOARD)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('division_head')}
                            </button>
                        }
                        {
                            auth.roles && auth.roles.role === ROLES.GHAT_IC &&

                            <button onClick={() => navigate(ROUTES.GHAT_IC.DASHBOARD)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('ghat_ic')}
                            </button>
                        }
                        {
                            auth.roles && auth.roles.role === ROLES.USER &&

                            <button onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('your_bookings')}
                            </button>
                        }
                        {
                            auth.roles && auth.roles.role === ROLES.DEPT_USER &&

                            <button onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('your_bookings')}
                            </button>
                        }
                        {
                            auth.user &&

                            <Popover className="relative">
                                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                                    {auth.user.fullName}
                                    <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" />
                                </Popover.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute right-0 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                        <div className="p-4">
                                            <div
                                                key={auth.user?.id}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <UserCircleIcon className="h-6 w-6 text-gray-600 group-hover:text-sky-600" />
                                                </div>
                                                <div className="flex-auto">
                                                    <div className="block font-semibold text-gray-900">
                                                        {auth.user ? auth.user.email : `Please update your email`}
                                                        <span className="absolute inset-0" />
                                                    </div>
                                                    <p className="mt-1 text-gray-600">{auth.user ? `+91 ${auth.user.mobileNo}` : `Please update your phone number`}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                            <button onClick={() => navigate(ROUTES.LOGGED_IN.SETTINGS)}
                                                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                                                <Cog8ToothIcon className="h-5 w-5 flex-none text-gray-400 rotate-90" />
                                                {t('settings')}
                                            </button>
                                            <LogoutAction>
                                                <button className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 w-full">
                                                    <ArrowUpTrayIcon className="h-5 w-5 flex-none text-gray-400 rotate-90" />
                                                    {t('logout')}
                                                </button>
                                            </LogoutAction>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </Popover>
                        }
                        {
                            !auth.user &&

                            <button onClick={() => navigate(ROUTES.AUTH.LOGIN)} className="text-sm font-semibold leading-6 text-gray-900">
                                {t('login')} <span>&rarr;</span>
                            </button>
                        }
                        {
                            <Popover className="relative">
                                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
                                    onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}>
                                    <Language />
                                    <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" />
                                </Popover.Button>

                                <Transition
                                    show={languageDropdownOpen}
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute right-0 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                        <div className="bg-gray-50 flex flex-col gap-y-2 p-4">
                                            <button onClick={() => changeLanguage('en')}
                                                className="py-2 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                                                English
                                            </button>
                                            <button onClick={() => changeLanguage('as')}
                                                className="py-2 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                                                অসমীয়া
                                            </button>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </Popover>
                        }
                    </Popover.Group>
                </nav>
                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <button onClick={() => navigate(ROUTES.INDEX)} className="-m-1.5 p-1.5">
                                <span className="sr-only">{t('portal_name')}</span>
                                <img className="h-8 w-auto" src={require("../../resources/images/aiwtd_logo.png")} alt="" />
                            </button>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">{t('close_menu')}</span>
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                {
                                    auth.roles && auth.roles.role === ROLES.DEPT_USER &&

                                    <div className="py-6">
                                        <button
                                            onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)}
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {t('your_bookings')}
                                        </button>
                                    </div>
                                }
                                {
                                    auth.roles && auth.roles.role === ROLES.USER &&

                                    <div className="py-6">
                                        <button
                                            onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)}
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {t('your_bookings')}
                                        </button>
                                    </div>
                                }
                                {
                                    auth.roles && auth.roles.role === ROLES.SYSADMIN &&

                                    <div className="py-6">
                                        <button
                                            onClick={() => navigate(ROUTES.SYSTEM_ADMIN.DASHBOARD)}
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {t('system_admin')}
                                        </button>
                                    </div>
                                }
                                <div className="space-y-2 py-6">
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                {
                                                    auth.user &&

                                                    <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                        {auth.user?.fullName}
                                                        <ChevronDownIcon
                                                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                        />
                                                    </Disclosure.Button>
                                                }
                                                {
                                                    auth.user &&

                                                    <Disclosure.Panel className="mt-2 space-y-2">
                                                        <Disclosure.Button
                                                            as="button"
                                                            onClick={() => navigate(ROUTES.LOGGED_IN.SETTINGS)}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                        >
                                                            {t('settings')}
                                                        </Disclosure.Button>
                                                        <Disclosure.Button
                                                            as="div"
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                        >
                                                            <LogoutAction>
                                                                {t('logout')}
                                                            </LogoutAction>
                                                        </Disclosure.Button>
                                                    </Disclosure.Panel>
                                                }
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                                {
                                    !auth.user &&

                                    <div className="py-6">
                                        <button
                                            onClick={() => navigate(ROUTES.AUTH.LOGIN)}
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {t('login')}
                                        </button>
                                    </div>
                                }
                                <div className="space-y-2 py-6">
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    <Language />
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    />
                                                </Disclosure.Button>

                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    <Disclosure.Button
                                                        as="button"
                                                        onClick={() => changeLanguage('en')}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    >
                                                        English
                                                    </Disclosure.Button>

                                                    <Disclosure.Button
                                                        as="button"
                                                        onClick={() => changeLanguage('as')}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    >
                                                        অসমীয়া
                                                    </Disclosure.Button>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </Container>
        </header>
    )
}

export default DefaultHeader;
