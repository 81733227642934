import { Dashboard, ModeOfTravel } from '@mui/icons-material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes-constants';
import Sidebar from '../sidebars/Sidebar';
import SidebarItem from '../sidebars/SidebarItem';
import AdminHeader from '../headers/AdminHeader';
import { Container } from '@mui/material';
import AdminFooter from '../footers/AdminFooter';
import DefaultHeader from '../headers/DefaultHeader';
import DefaultFooter from '../footers/DefaultFooter';

interface GhatIcLayoutProps {
    children: React.ReactNode
}

const GhatIcLayout: React.FC<GhatIcLayoutProps> = ({ ...props }) => {

    const ghatIcLinks = [
        { name: 'Dashboard', href: ROUTES.GHAT_IC.DASHBOARD, icon: <Dashboard /> },
        { name: 'Trip Management', href: ROUTES.GHAT_IC.TRIP_MANAGEMENT, icon: <ModeOfTravel /> }
    ]

    return (
        <div>
            <DefaultHeader />
            <div className='min-h-screen py-4 bg-neutral-50'>
                <Container>
                    {props.children}
                </Container>
            </div>
            <DefaultFooter />
        </div>
    )
}

export default GhatIcLayout;