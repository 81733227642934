import React from 'react'

interface DangerButtonProps {
    onClick: () => void;
    buttonText: string;
    buttonIcon?: React.ElementType;
    isDisabled?: boolean;
}

const DangerButton: React.FC<DangerButtonProps> = ({ onClick, buttonText, buttonIcon: ButtonIcon, isDisabled }) => {
    return (
        <button type="submit" onClick={onClick} disabled={isDisabled}
            className="w-full text-white bg-red-500 hover:bg-red-600 focus:ring-4 
                    transition duration-300 focus:outline-none focus:ring-slate-300 font-medium rounded-lg 
                    px-5 text-center"
            style={{
                paddingTop: '0.575rem',
                paddingBottom: '0.575rem'
            }}
        >
            {buttonText}
            {ButtonIcon && <ButtonIcon className='ml-2 -mt-1' />}
        </button>
    )
}

export default DangerButton;