import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Body from '../../../components/layouts/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import RateManagementModal from './RateManagementModal';
import { useRateManagementService } from './useRateManagementSevice';
import { Route } from '../route-management/route-interfaces';
import { ItemRate, Rate, RateTableRow } from './rate-management-interface';

const RateManagement: React.FC = () => {
    const {
        fetchDivisionOptions,
        fetchItemOptions,
        fetchRoutesByDivision
    } = useCommonService();

    const {
        generateGhatFromOptions,
        generateGhatToOptions,
        handleInputChange,
        fetchRatesByRoute
    } = useRateManagementService();

    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatFromOptions, setGhatFromOptions] = useState<SelectOption[]>([]);
    const [ghatToOptions, setGhatToOptions] = useState<SelectOption[]>([]);

    const [division, setDivision] = useState('');
    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatTo, setGhatTo] = useState('');

    const [itemList, setItemList] = useState<SelectOption[]>([]);
    const [rateList, setRateList] = useState<Rate[]>([]);
    const [itemRateList, setItemRateList] = useState<ItemRate[]>([]);


    const [routes, setRoutes] = useState<Route[]>([]);


    const [rateModalOpen, setRateModalOpen] = useState(false);
    const handleRateModalOpen = () => setRateModalOpen(true);
    const handleRateModalClose = () => setRateModalOpen(false);

    const [rateTableRows, setRateTableRows] = useState<RateTableRow[]>([]);
    const rateTableCols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vesselType', headerName: 'Vessel Type', flex: 2 },
        { field: 'serviceType', headerName: 'Service Type', flex: 2 },
        { field: 'itemLabel', headerName: 'Item', flex: 2 },
        { field: 'rate', headerName: 'Rate', flex: 2 },
    ];

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchItemOptions(setItemList);
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchRoutesByDivision(division, setRoutes);
        }
    }, [division]);

    useEffect(() => {
        if (routes.length > 0) {
            generateGhatFromOptions(routes, setGhatFromOptions, setGhatFrom);
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions(routes, ghatFrom, setGhatToOptions, setGhatTo);
        }
    }, [ghatFrom]);

    const handleFetchRates = async () => {
        const route = routes.find((route: Route) => route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo);
        if (route) {
            fetchRatesByRoute(
                route,
                setRateTableRows,
                setRateList,
            )
        }
    };

    const handleAddRateClick = async () => {
        // generateGhatFromOptions(routes, setFormGhatFromOptions, setFormGhatFrom);
        // setFormGhatFrom(ghatFrom);
        // setFormGhatTo(ghatTo);
        // setVesselType(vesselTypeOptions[0]?.value);
        // setServiceType(serviceTypeOptions[0]?.value);
        // // filterRates();
        handleRateModalOpen();
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><CurrencyRupeeRoundedIcon /> Rate Management</h3>
            </div>
            <Card >
                <div className='flex gap-2 items-end'>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleInputChange(setDivision)}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleInputChange(setGhatFrom)}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleInputChange(setGhatTo)}
                        />
                    </div>
                    <div className='w-full lg:w-1/5 pb-1'>
                        <DefaultButton
                            onClick={handleFetchRates}
                            buttonText={'Fetch Rates'}
                        />
                    </div>
                    <div className='w-full lg:w-1/5 pb-1'>
                        <DefaultButton
                            onClick={handleAddRateClick}
                            buttonText={'Update Rates'}
                            buttonIcon={AddOutlinedIcon}
                        />
                    </div>
                </div>
            </Card>
            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <DataGrid
                            rows={rateTableRows}
                            columns={rateTableCols}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>


            <RateManagementModal
                division={division}
                routes={routes}
                rateModalOpen={rateModalOpen}
                handleRateModalClose={handleRateModalClose}
                itemList={itemList}
                rateList={rateList}
                itemRateList={itemRateList}
                setItemRateList={setItemRateList}
            />

        </Body>
    )
}

export default RateManagement 