import React, { useContext } from 'react';
import { SidebarContext } from './Sidebar';

interface SidebarItemProps {
    icon: React.ReactNode;
    text: string;
    active?: boolean;
    alert?: boolean;
    theme?: 'primary' | 'secondary';
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, active, alert, theme = 'primary' }) => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error("SidebarItem must be used within a Sidebar");
    }
    const { expanded } = context;

    // Define color classes based on the theme
    const themeColors = theme === 'primary'
        ? {
            bgActive: "bg-indigo-100 text-indigo-800",
            hoverBg: "hover:bg-indigo-50 text-gray-600",
            alertBg: "bg-indigo-400",
            tooltipBg: "bg-indigo-100 text-indigo-800"
        }
        : {
            bgActive: "bg-slate-200 text-slate-800",
            hoverBg: "hover:bg-slate-100 text-gray-600",
            alertBg: "bg-slate-400",
            tooltipBg: "bg-slate-100 text-slate-800"
        };

    return (
        <li
            className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group
                ${active ? themeColors.bgActive : themeColors.hoverBg}`}
        >
            {icon}
            <span
                className={`overflow-hidden whitespace-nowrap text-ellipsis transition-all ${expanded ? "w-52 ml-4" : "w-0"}`}
            >
                {text}
            </span>
            {alert && (
                <div className={`absolute right-2 w-2 h-2 rounded ${themeColors.alertBg} ${expanded ? "" : "top-2"}`} />
            )}

            {!expanded && (
                <div className={`absolute left-full rounded-md px-2 py-1 ml-6 ${themeColors.tooltipBg} text-sm invisible opacity-20
                     -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
                >
                    {text}
                </div>
            )}
        </li>
    );
};

export default SidebarItem;
