import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IBookingTicketDetails } from '../booking-history/ticket-details/booking-ticket-details-interfaces';
import Body from '../../../components/layouts/Body';
import { useLandingService } from '../../unlogged/landing-page/useLandingService';
import FormControl from '../../../components/forms/FormControl';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { Route } from '../../manage/route-management/route-interfaces';
import { ArrowRightAlt, SearchOutlined } from '@mui/icons-material';
import { ROUTES } from '../../../constants/routes-constants';
import { useTicketRebookService } from './useTicketRebookService';
import TicketItemDetailsTable from '../payment/sections/TicketItemDetailsTable';
import { BookingTicketTypes } from '../payment/order-details-interfaces';
import { dateUtil } from '../../../utility/dateUtil';
import Card from '../../../components/cards/Card';

const TicketRebook: React.FC = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const { fetchRoutes } = useLandingService();
    const { searchTrips, getTableData } = useTicketRebookService(t);

    const bookingTicketDetails = location.state.bookingTicketDetails as IBookingTicketDetails;

    const [routes, setRoutes] = useState<Route[]>([]);
    const [tripDate, setTripDate] = useState<string>('');

    useEffect(() => {
        fetchRoutes(setRoutes);
    }, []);

    const handleTripDateChange = (e: React.ChangeEvent<HTMLInputElement>) => setTripDate(e.target.value);

    const handleSearchClick = async () => {
        const found = await searchTrips(
            {
                origin: {
                    originId: bookingTicketDetails.trip.route.ghatFrom.id,
                    originName: bookingTicketDetails.trip.route.ghatFrom.ghatName
                },
                destination: {
                    destinationId: bookingTicketDetails.trip.route.ghatTo.id,
                    destinationName: bookingTicketDetails.trip.route.ghatTo.ghatName
                },
                tripDate: tripDate
            },
            routes,
            bookingTicketDetails);
        if (found) navigate(ROUTES.USER.REBOOK_SEARCH_RESULTS);
    }
    const renderTable = (type: BookingTicketTypes) => {
        const tickets = bookingTicketDetails[type];
        if (!tickets || tickets.length === 0) return null;
        const { title, headers } = getTableData(type);
        return (
            <TicketItemDetailsTable
                title={title}
                headers={headers}
                tickets={tickets}
            />
        )
    }
    return (
        <Body>
            {/* Header and Trip Details */}
            <h2>{t('ticket_rebook')}</h2>
            <div className="flex flex-wrap lg:justify-between items-end gap-x-4 mb-8">
                <div className='flex flex-wrap gap-x-2 items-end'>
                    <div>
                        <span className='font-semibold text-sm'>{t('origin')}:</span>
                        <br />
                        <h3>{bookingTicketDetails.trip.route.ghatFrom.ghatName}</h3>
                    </div>
                    <ArrowRightAlt fontSize='large' className='mb-1' />
                    <div>
                        <span className='font-semibold text-sm'>{t('destination')}:</span>
                        <br />
                        <h3>{bookingTicketDetails.trip.route.ghatTo.ghatName}</h3>
                    </div>
                </div>
                <div className='flex items-end gap-x-4'>
                    <div className=''>
                        <FormControl
                            id="tripDate"
                            type="date"
                            value={tripDate}
                            onChange={handleTripDateChange}
                            mandatoryField={true}
                            min={dateUtil.today()}
                            label={t('trip_date')}
                        />
                    </div>

                    <div>
                        {/* Proceed Button */}
                        <PrimaryButton
                            onClick={handleSearchClick}
                            buttonText={t('search')}
                            buttonIcon={SearchOutlined}
                            fullWidth={true}
                        />
                    </div>
                </div>
            </div>
            <Card>
                <div>
                    <h3 className='text-center'>{t('ticket_details')}</h3>
                    {renderTable('passengers')}
                    {renderTable('vehicles')}
                    {renderTable('goods')}
                </div>
            </Card>
        </Body>
    )
}

export default TicketRebook;