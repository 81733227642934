import React from 'react'
import { SelectOption } from '../../constants/type-constants';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface FilterSelectProps {
    options: SelectOption[];
    label: string;
    value: string;
    id: string;
    onChange: (e: SelectChangeEvent) => void;
    className?: string
}

const FilterSelect: React.FC<FilterSelectProps> = ({ ...props }) => {
    return (
        <FormControl>
            <InputLabel id={`${props.id}Label`}>{props.label}</InputLabel>
            <Select
                className={props.className}
                labelId={`${props.id}Label`}
                id={props.id}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                size='small'
                MenuProps={{
                    disableScrollLock: true,
                }}
            >
                {
                    props.options.map(option =>
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default FilterSelect;