import { ArrowRightAlt, Check, Clear, FileDownloadOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Body from '../../../../components/layouts/Body';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import SecondaryButton2 from '../../../../components/buttons/SecondaryButton2';
import Card from '../../../../components/cards/Card';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { BookingTicketTypes } from '../../payment/order-details-interfaces';
import TicketItemDetailsTable from '../../payment/sections/TicketItemDetailsTable';
import { IBookingTicketDetails } from './booking-ticket-details-interfaces';
import PaymentInfo from './sections/PaymentInfo';
import TicketHeader from './sections/TicketHeader';
import TripVesselInfo from './sections/TripVesselInfo';
import useBookingTicketDetailsService from './useBookingTicketDetailsService';
import useTicketGenerationService from './useTicketGenerationService';
import useBookingHistoryService from '../ticket-list/useBookingHistoryService';

const BookingTicketDetails: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [bookingTicketDetails, setBookingTicketDetails] = useState<IBookingTicketDetails>(location.state.bookingTicketDetails);

    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const handleCancelModalOpen = () => setCancelModalOpen(true);
    const handleCancelModalClose = () => setCancelModalOpen(false);

    const { generateTicketPdf } = useTicketGenerationService(t);
    const { fetchTicketDetails } = useBookingHistoryService();
    const { getTableData, cancelTicket } = useBookingTicketDetailsService(t);

    const renderTable = (type: BookingTicketTypes) => {
        const tickets = bookingTicketDetails[type];
        if (!tickets || tickets.length === 0) return null;
        const { title, headers } = getTableData(type);
        return (
            <TicketItemDetailsTable
                title={title}
                headers={headers}
                tickets={tickets}
            />
        )
    }

    const handleDownloadClick = async () => generateTicketPdf(bookingTicketDetails);

    const handleCancelClick = async () => handleCancelModalOpen();

    const handleCancelConfirmClick = async () => {
        await cancelTicket(
            bookingTicketDetails.bookingNo
        );
        const ticketDetails = await fetchTicketDetails(bookingTicketDetails.id);
        setBookingTicketDetails(ticketDetails);
        handleCancelModalClose();
    }

    return (
        <Body>
            <h2 className='mb-3'>{t('ticket_details')}</h2>
            <Card>
                <>
                    {/* Header */}
                    <TicketHeader
                        bookingTicketDetails={bookingTicketDetails}
                        downloadButton={<PrimaryButton
                            onClick={handleDownloadClick}
                            buttonText={t('ticket_download')}
                            buttonIcon={FileDownloadOutlined}
                        />}
                        cancelButton={<SecondaryButton2
                            onClick={handleCancelClick}
                            buttonText={t('cancel_booking')}
                            buttonIcon={Clear}
                        />}
                    />

                    {/* Trip and Vessel Information */}
                    <TripVesselInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Ticket Tables */}
                    {renderTable('passengers')}
                    {renderTable('vehicles')}
                    {renderTable('goods')}

                    {/* Payment Information */}
                    <PaymentInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Footer Placeholder */}
                    <div className="text-gray-500 text-sm text-justify mt-10">
                        * {t('ticket_instruction_1')}
                    </div>
                </>
            </Card>
            <ModalWrapper
                title={t('cancel_booking_modal_title')}
                open={cancelModalOpen}
                handleClose={handleCancelModalClose}>
                <div className='lg:px-6'>
                    <h3>{t('cancel_booking_confirmation')}</h3>
                    <ul className='list-disc list-inside mb-4 ml-4'>
                        <li>{t('cancellation_note_1')}</li>
                        <li>{t('cancellation_note_2')}</li>
                        <li>{t('cancellation_note_3')}</li>
                    </ul>
                    <h6 className='mb-2'>{t('booking_details')}:</h6>
                    <ul className='list-disc list-inside mb-4 ml-4'>
                        <li>{t('booking_no')}: {bookingTicketDetails.bookingNo}</li>
                        <li>{t('booking_date')}: {bookingTicketDetails.confirmationDate}</li>
                        <li className='font-semibold'>{t('date_and_time_of_journey')}: {bookingTicketDetails.trip.tripDate} | {bookingTicketDetails.trip.departureTime}</li>
                        <li>{t('route')}: {bookingTicketDetails.trip.route.ghatFrom.ghatName} <ArrowRightAlt /> {bookingTicketDetails.trip.route.ghatTo.ghatName}</li>
                        <li>{t('vessel_name')}: {bookingTicketDetails.trip.vessel.name} ({bookingTicketDetails.trip.vessel.regNo})</li>
                    </ul>
                    <div className='flex justify-center gap-x-4'>
                        <PrimaryButton
                            onClick={handleCancelConfirmClick}
                            buttonText={t('confirm_cancel')}
                            buttonIcon={Check}
                        />
                        <SecondaryButton2
                            onClick={handleCancelModalClose}
                            buttonText={t('go_back')}
                            buttonIcon={Clear}
                        />
                    </div>
                </div>
            </ModalWrapper>
        </Body>
    );
};

export default BookingTicketDetails;
