import { Container } from '@mui/material';
import React from 'react'
import { dateUtil } from '../../utility/dateUtil';

const AdminFooter: React.FC = () => {
    return (
        <div className='bg-slate-700'>
            <Container>
                <div className='py-4 text-center text-slate-300 text-sm leading-6'>
                    <div>
                        <p>Copyright © {dateUtil.getCurrentYear()} | <a href='#'
                            className="hover:underline"
                            target='_blank'
                            rel="noopener noreferrer">Assam Inland Water Transport Development Society</a> | All Rights Reserved.</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AdminFooter;