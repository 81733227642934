import { AxiosResponse } from 'axios';
import React from 'react';
import toast from 'react-hot-toast';
import API_ENDPOINT from '../../../constants/api-constants';
import { useApiService } from '../../../hooks/useApiService';
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { Route } from '../../manage/route-management/route-interfaces';
import { IDestination, IGhatDetail, IGhatOption, IOrigin, ISearchParams } from './landing-interfaces';
import { ISearchResult } from '../search-results/search-interfaces';

export const useLandingService = () => {

    const { fetchPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchRoutes = async (
        setRoutes: React.Dispatch<React.SetStateAction<Route[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_ROUTES);
            if (response) {
                const { object } = response.data;
                setRoutes(object);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleSwapClick = (
        searchParams: ISearchParams,
        setSearchParams: React.Dispatch<React.SetStateAction<ISearchParams>>
    ) => {

        const { origin, destination } = searchParams;

        if (origin.originId === '') {
            toast.error("Please select origin");
            return;
        }

        if (destination.destinationId === '') {
            toast.error("Please select destination");
            return;
        }

        // Swapping origin and destination
        const newOrigin: IOrigin = {
            originId: destination.destinationId,
            originName: destination.destinationName
        };

        const newDestination: IDestination = {
            destinationId: origin.originId,
            destinationName: origin.originName
        };

        setSearchParams({
            ...searchParams,
            origin: newOrigin,
            destination: newDestination
        });
    }

    const routesToGhatList = (
        clickedDiv: string,
        routes: Route[],
        searchParams: ISearchParams,
        setGhatList: React.Dispatch<React.SetStateAction<IGhatOption[]>>
    ) => {
        const divisionsMap: Record<string, IGhatOption> = {};
        routes.forEach((route) => {
            let ghats: IGhatDetail[] = [];

            if (clickedDiv == 'destination') {
                if (route.ghatFrom.id == searchParams.origin.originId) {
                    ghats = [route.ghatTo];
                }
            } else {
                ghats = [route.ghatFrom, route.ghatTo];
            }

            ghats.forEach(ghat => {
                const divisionId = ghat.division.id;
                const divisionName = ghat.division.divisionName;
                if (!divisionsMap[divisionId]) {
                    // Initialize the division if it does not exist
                    divisionsMap[divisionId] = {
                        divisionId: divisionId.toString(),
                        divisionName: divisionName,
                        ghats: []
                    };
                }
                if (!divisionsMap[divisionId].ghats.find(g => g.id === ghat.id.toString())) {
                    // Add the ghat to the division if it is not already included
                    divisionsMap[divisionId].ghats.push({
                        id: ghat.id.toString(),
                        name: ghat.ghatName
                    });
                }
            });
        });

        // Convert the object to an array format
        setGhatList(Object.values(divisionsMap));
    };

    const getRouteFromOriginAndDestination = (routes: Route[], origin: string, destination: string) => {
        const match = routes.find((route) => route.ghatFrom.id == origin && route.ghatTo.id == destination);
        if (match) {
            return match.id;
        }
        return null;
    }

    const searchTrips = async (
        searchParams: ISearchParams,
        routes: Route[]
    ) => {
        const { origin, destination, tripDate } = searchParams;

        const missingFields = [];
        if (!origin.originId) missingFields.push('origin');
        if (!destination.destinationId) missingFields.push('destination');

        if (missingFields.length > 0) {
            toast.error(`Please select ${missingFields.join(' and ')}.`);
            return;
        }

        const routeId = getRouteFromOriginAndDestination(routes, origin.originId, destination.destinationId);

        if (!routeId || !tripDate.trim()) {
            toast.error("No trips found for the selected route and date.");
            return;
        }
            
        try {
            const response: AxiosResponse = await fetchPublicRequest(API_ENDPOINT.PUBLIC.GET_TRIPS, {
                routeId,
                tripDate
            });
            const object: ISearchResult[] = response.data.object;
            if (object.length > 0) {
                localStorage.setItem('searchResults', JSON.stringify(object));
                return true;
            } else {
                toast.error("No trips found for the selected route and date.");
            }
        } catch (error) {
            toast.error("Failed to fetch trips. Please try again.");
        }
    }

    return {
        fetchRoutes,
        handleSwapClick,
        routesToGhatList,
        searchTrips
    }
}
