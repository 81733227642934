import React from 'react'

export interface TicketItemTableHeader {
    name: string;
    label: string;
}

interface TicketItemDetailsTableProps {
    title: string
    headers: TicketItemTableHeader[]
    tickets: any[]
}

const TicketItemDetailsTable: React.FC<TicketItemDetailsTableProps> = ({ ...props }) => {
    return (
        <section className="mb-6 border-b pb-6">
            <h5>{props.title}</h5>
            <div className='overflow-x-scroll lg:overflow-auto pb-4 md:pb-0'>
                <table className='w-full mb-4'>
                    <thead>
                        <tr>
                            {props.headers.map((header, index) => {
                                const textAlignClass = index === 0
                                    ? 'text-left'
                                    : index === props.headers.length - 1
                                        ? 'text-right'
                                        : 'text-left';
                                return (
                                    <th key={header.name} className={`w-auto ${textAlignClass} font-medium px-3`}>
                                        {header.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tickets.map((ticket, rowIndex) => (
                            <tr key={rowIndex}>
                                {props.headers.map((header, colIndex) => {
                                    const textAlignClass = colIndex === 0
                                        ? 'text-left'
                                        : colIndex === props.headers.length - 1
                                            ? 'text-right'
                                            : 'text-left';
                                    const splitHeader = header.name.split('.');

                                    if (header.name === 'passengerName' && ticket.infants?.length > 0) {
                                        return (
                                            <td key={header.name} className={`w-auto ${textAlignClass} px-3`}>
                                                <div className='-mb-1'>{ticket[splitHeader[0]]}</div>
                                                <small>
                                                    {`Infants: ${ticket.infants.map((infant: any) => (` ${infant.infantName} (${infant.infantAge.description})`))}`}
                                                </small>
                                            </td>
                                        );
                                    }

                                    if (header.name === 'volume' || header.name === 'weight') {
                                        if (header.name === 'volume') {
                                            let volume = '--'
                                            if (ticket.unit === 'Volume') volume = `${ticket.height}x${ticket.width}x${ticket.length} m³`;
                                            return (
                                                <td key={header.name} className={`w-auto ${textAlignClass} px-3`}>
                                                    <div className='-mb-1'>{volume}</div>
                                                </td>
                                            );
                                        } else if (header.name === 'weight') {
                                            let weight = '--'
                                            if (ticket.unit === 'Weight') weight = `${ticket.weight} kgs`;
                                            return (
                                                <td key={header.name} className={`w-auto ${textAlignClass} px-3`}>
                                                    <div className='-mb-1'>{weight}</div>
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <td key={header.name} className={`w-auto ${textAlignClass} px-3`}>
                                            {splitHeader.length > 1 ?
                                                ticket[splitHeader[0]][splitHeader[1]]
                                                :
                                                ticket[splitHeader[0]]
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default TicketItemDetailsTable;