import React from 'react'
import { IRevenueData, IRouteWiseRevenue, ITotalRevenue, IVesselWiseRevenue } from './mis-interfaces'

export const useRevenueService = () => {

    const getFilteredRouteWiseTotalRevenueCounts = (
        serviceType: string,
        owner: string,
        vesselType: string,
        routeWiseRevenue: IRouteWiseRevenue[],
        setFilteredRouteWiseRevenue: React.Dispatch<React.SetStateAction<IRouteWiseRevenue[]>>
    ) => {
        const groupedData = new Map();

        for (const entry of routeWiseRevenue) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Group by routeId
                if (!groupedData.has(entry.routeId)) {
                    groupedData.set(entry.routeId, { ...entry });
                } else {
                    const existingEntry = groupedData.get(entry.routeId);
                    existingEntry.totalRevenue += entry.totalRevenue;
                    existingEntry.cash += entry.cash;
                    existingEntry.online += entry.online;
                    existingEntry.noOfTrips += entry.noOfTrips;
                }
            }
        }

        // Convert the map back to an array
        setFilteredRouteWiseRevenue(Array.from(groupedData.values()));
    }

    const getFilteredVesselWiseTotalRevenueCounts = (
        serviceType: string,
        owner: string,
        vesselType: string,
        vesselWiseRevenue: IVesselWiseRevenue[],
        setFilteredVesselWiseRevenue: React.Dispatch<React.SetStateAction<IVesselWiseRevenue[]>>
    ) => {
        const groupedData = new Map();

        for (const entry of vesselWiseRevenue) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Group by vesselId
                if (!groupedData.has(entry.vesselId)) {
                    groupedData.set(entry.vesselId, { ...entry });
                } else {
                    const existingEntry = groupedData.get(entry.vesselId);
                    existingEntry.totalRevenue += entry.totalRevenue;
                    existingEntry.cash += entry.cash;
                    existingEntry.online += entry.online;
                    existingEntry.noOfTrips += entry.noOfTrips;
                }
            }
        }

        // Convert the map back to an array
        setFilteredVesselWiseRevenue(Array.from(groupedData.values()));
    }

    const getFilteredTotalRevenueCounts = (
        serviceType: string,
        owner: string,
        vesselType: string,
        totalRevenueCountList: ITotalRevenue[],
        setTotalRevenue: React.Dispatch<React.SetStateAction<ITotalRevenue>>
    ) => {
        // Initialize an object to store the aggregated data
        const aggregatedData: ITotalRevenue = {
            owner: owner === "All" ? "All" : owner,
            totalRevenue: 0,
            vesselType: vesselType === "All" ? "All" : vesselType,
            serviceType: serviceType === "All" ? "All" : serviceType,
            pos: 0,
            cash: 0,
            noOfTrips: 0,
            online: 0,
        };

        // Iterate over the list and aggregate data for matching entries
        for (const entry of totalRevenueCountList) {
            // Check if the entry matches all filters
            if (
                (serviceType === "All" || entry.serviceType === serviceType) &&
                (owner === "All" || entry.owner === owner) &&
                (vesselType === "All" || entry.vesselType === vesselType)
            ) {
                // Aggregate the values
                aggregatedData.totalRevenue += entry.totalRevenue;
                aggregatedData.cash += entry.cash;
                aggregatedData.online += entry.online;
                aggregatedData.noOfTrips += entry.noOfTrips;
                aggregatedData.pos += entry.pos; // Assuming pos is also aggregated
            }
        }
        // Convert the map back to an array
        setTotalRevenue(aggregatedData);
    }

    return {
        getFilteredRouteWiseTotalRevenueCounts,
        getFilteredVesselWiseTotalRevenueCounts,
        getFilteredTotalRevenueCounts
    }
}
